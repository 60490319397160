import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddUpdatePollsModalComponent } from './add-update-polls-modal/add-update-polls-modal.component';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { PollsService } from 'src/app/_services/polls.service';
import * as moment from 'moment';

@Component({
  selector: 'app-user-polls',
  templateUrl: './user-polls.component.html',
  styleUrls: ['./user-polls.component.css']
})
export class UserPollsComponent implements OnInit {
  isDashboardMode = true
  moment: any = moment;

  title = 'My Polls'
  color = 'my-pool-head-bg'
  polls: any;

  constructor(
    private modalService: NgbModal,
    public spinner: NgxSpinnerService,
    public toastr: ToastrService,
    public pollsService: PollsService,
    private authenticationService: AuthenticationService,
  ) { }

  ngOnInit() {
    this.getPolls()
  }
  getPolls(){
    this.spinner.show();
    let currentUser: any = this.authenticationService.currentUserValue;
    
    this.pollsService.getAllPollsByUser({
      userID : currentUser.profile.FKuserID
    }).subscribe(
      response => {
        this.spinner.hide();
        this.polls = response.data;
      },
      error => {
 
      }
    )
   }
   pollDetails(poll){
    localStorage.setItem('poll', JSON.stringify(poll))
   }
   deletePoll(poll){
    this.spinner.show();
    this.pollsService.deletePoll({
      id : poll.id
    }).subscribe(
      response => {
        this.spinner.hide();
        this.getPolls()
        this.toastr.success(response.message, "Success!")
      },
      error => {

      }
    )
   }
   updateStatus(poll){
    this.spinner.show();
    this.pollsService.updateStatus({
      id : poll.id,
      status : poll.isActive == 1 ? 0 : 1
    }).subscribe(
      response => {
        this.spinner.hide();
        this.getPolls()
        this.toastr.success(response.message, "Success!")
      },
      error => {

      }
    )
   }
  openAddUpdateModal(poll) {
    const modalRef = this.modalService.open(AddUpdatePollsModalComponent);
    modalRef. result.then(() => { this.getPolls() }, () => { this.getPolls() })

    modalRef.componentInstance.poll = poll;
    // modalRef.componentInstance.broadcastID = this.broadcastId;
  }
}
