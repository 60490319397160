import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

import { AuthenticationService, GlobalService } from '../_services';
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { ToastrService } from 'ngx-toastr';


@Component({ templateUrl: 'login.component.html' })
export class LoginComponent implements OnInit {
    returnUrl: string;
    user: any =[];
    loggedIn: any;
    error = '';
    result:any;
    constructor(
        private toastr: ToastrService,

        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private globalService: GlobalService,
        private spinner: NgxSpinnerService,
        private authService: AuthService
            ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }
    }
  

    signInWithFB(): void {
        this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/channels';
        this.authService.authState.subscribe((user) => {
            this.user = user;
         
            if (this.user && this.user.provider == 'FACEBOOK') {

                const requestData = {
                    "socialID":this.user.id,
                    "socialType": "facebook",
                    
                    "apnsToken": localStorage.getItem('setFireBaseToken') != null ? localStorage.getItem('setFireBaseToken'): '2222',
                    "deviceType":"web",
                       "email" : this.user.email,   
                       "firstName" : this.user.firstName,    
                       "lastName" : this.user.lastName, 
                       "Avtar" :   this.user.photoUrl,      
                }
                  this.authenticationService.socialLogin(requestData)
                      .pipe(first())
                      .subscribe(
                          response => {
                              console.log('response', response)
                              this.spinner.hide();
                              if (!response.error) {

                                if(response.data.updateInfo=='1'){
                                  this.router.navigate(['/social-info']);
                                }
                                else{
                                  this.router.navigate([this.returnUrl]);
                                }
                                // this.toastr.success('Login You are successfully logged in', 'Success!', {
                                //   timeOut: 5000,
                                //   progressBar: true,
                                //   closeButton: true
                                // });
                               
                              } else {
                                if (response.message) {
                                  this.toastr.error(response.message, 'Error!', {
                                    timeOut: 5000,
                                    progressBar: true,
                                    closeButton: true
                                  });
                                }else {
                                //   this.toastr.error('Sorry, something went wrong there. Try again', 'Error!', {
                                //     timeOut: 5000,
                                //     progressBar: true,
                                //     closeButton: true
                                //   });
                                }
                              }
                              
                          },
                          error => {
                              this.spinner.hide();
                              this.error = error;
                             
                      });

            }
      });
    }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/channels';

    this.user =[];
    this.authService.authState.subscribe((user) => {
        this.user = user;
  
        if (this.user && this.user.provider == 'GOOGLE') {

            const requestData = {
                "socialID":this.user.id,
                "socialType": "google",
                
                "apnsToken": localStorage.getItem('setFireBaseToken') !=null ? localStorage.getItem('setFireBaseToken') : '2222',
                "deviceType":"web",
                   "email" : this.user.email,   
                   "firstName" : this.user.firstName,    
                   "lastName" : this.user.lastName, 
                   "Avtar" :   this.user.photoUrl,      
            }
              this.authenticationService.socialLogin(requestData)
                  .pipe(first())
                  .subscribe(
                      response => {
                          console.log('response', response)
                          this.spinner.hide();
                          if (!response.error) {


                            if(response.data.updateInfo=='1'){
                              this.router.navigate(['/social-info']);
                            }
                            else{
                              this.router.navigate([this.returnUrl]);
                            }
                            // this.toastr.success('Login You are successfully logged in', 'Success!', {
                            //   timeOut: 5000,
                            //   progressBar: true,
                            //   closeButton: true
                            // });
                          //  this.router.navigate([this.returnUrl]);
                          } else {
                            if (response.message) {
                              this.toastr.error(response.message, 'Error!', {
                                timeOut: 5000,
                                progressBar: true,
                                closeButton: true
                              });
                            }else {
                            //   this.toastr.error('Sorry, something went wrong there. Try again', 'Error!', {
                            //     timeOut: 5000,
                            //     progressBar: true,
                            //     closeButton: true
                            //   });
                            }
                          }
                          
                      },
                      error => {
                          this.spinner.hide();
                          this.error = error;
                         
                  });

        }


        
    });

  }

    ngOnInit() {
 

      let currentUrl = decodeURIComponent(window.location.href);
      let isTwitter =  currentUrl.split("&oauth_verifier="); 
   
      if(isTwitter[1]){

        const requestData = {
           "token": localStorage.getItem("twitter-oauth-token"),  
           "token_secret": localStorage.getItem("twitter-oauth-token-secret"),   
           "oauth_verifier": isTwitter[1],         
        }
          this.authenticationService.getTwitterAccDetails(requestData)
              .pipe(first())
              .subscribe(
                  response => {
                      console.log('response', response)
                      this.spinner.hide();
                      if (!response.error) {

                        console.log("twitter>>>>",response);
                       

                        const requestData = {
                            "socialID":response.data.user_data.id,
                            "socialType": "Twitter",
                            
                            "apnsToken": localStorage.getItem('setFireBaseToken') !=null ? localStorage.getItem('setFireBaseToken') : '2222',
                            "deviceType":"web",
                               "email" : response.data.user_data.email,   
                               "firstName" : response.data.user_data.name,    
                              
                               "Avtar" :   response.data.user_data.profile_image_url_https,      
                        }
                        console.log("twitter request>>>",requestData);
                          this.authenticationService.socialLogin(requestData)
                              .pipe(first())
                              .subscribe(
                                  response => {
                                      console.log('response twitter>>', response)
                                      this.spinner.hide();
                                      if (!response.error) {


                                        if(response.data.updateInfo=='1'){
                                          this.router.navigate(['/social-info']);
                                        }
                                        else{
                                          this.router.navigate(['/channels']);
                                        }
                                        // this.toastr.success('Login You are successfully logged in', 'Success!', {
                                        //   timeOut: 5000,
                                        //   progressBar: true,
                                        //   closeButton: true
                                        // });
                                      //  this.router.navigate(["/channels"]);
                                      } else {
                                        if (response.message) {
                                          this.toastr.error(response.message, 'Error!', {
                                            timeOut: 5000,
                                            progressBar: true,
                                            closeButton: true
                                          });
                                        }else {
                                          this.toastr.error('Sorry, something went wrong there. Try again', 'Error!', {
                                            timeOut: 5000,
                                            progressBar: true,
                                            closeButton: true
                                          });
                                        }
                                      }                                      
                                  },
                                  error => {
                                      this.spinner.hide();
                                      this.error = error;
                                     
                              });



                       // this.router.navigate([this.returnUrl]);
                      } else {
                        if (response.message) {
                          this.toastr.error(response.message, 'Error!', {
                            timeOut: 5000,
                            progressBar: true,
                            closeButton: true
                          });
                        }else {
                          this.toastr.error('Sorry, something went wrong there. Try again', 'Error!', {
                            timeOut: 5000,
                            progressBar: true,
                            closeButton: true
                          });
                        }
                      }
                      
                  },
                  error => {
                      this.spinner.hide();
                      this.error = error;
                     
              });
      }
      

        this.spinner.show();

        this.globalService.getCountries().pipe(first()).subscribe(
            data => {
                if (!data.error) {
                    //Success
                    localStorage.setItem('countries', JSON.stringify(data.data));
                    this.spinner.hide();
                } else {
                    //Error
                    this.spinner.hide();
                }
            },
            error => {

            }
        )
    }


    signInWithTwitter(){
        this.spinner.show();

        this.authenticationService.getTwitterReqToken().subscribe(
            data => {
                if (!data.error) {
                    //Success
                    localStorage.setItem('twitter-oauth-token', data.data.request_token.oauth_token);

                    localStorage.setItem('twitter-oauth-token-secret', data.data.request_token.oauth_token_secret);
                   
                    window.open(data.data.twitter_url);
                    this.spinner.hide();
                } else {
                    //Error
                    this.spinner.hide();
                    this.toastr.error(data.message, 'Error!', {
                            timeOut: 5000,
                            progressBar: true,
                            closeButton: true
                          });
                }
            },
            error => {

            }
        )
    }
  
}