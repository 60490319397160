import { Component, OnInit } from '@angular/core';
import { AuthenticationService, GlobalService, UploadFileService, UserService } from '../../../../_services';
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-family',
  templateUrl: './user-family.component.html',
  styleUrls: ['./user-family.component.css']
})
export class UserFamilyComponent implements OnInit {
	isDashboardMode = true
	title = 'My Family'
	color = 'myfan_head-bg'
	getUserfamilyDetailData:any;
	constructor(
		private toastr: ToastrService,
		private authenticationService: AuthenticationService,
		private globalService: GlobalService,
		private userService: UserService,
		private router: Router,
		private spinner: NgxSpinnerService,
	) {
		
	}

	ngOnInit() {
		this.getUserFamily();
	}

	getUserFamily(){
		let currentUser: any = this.authenticationService.currentUserValue;
		const requestData = {
			userID: currentUser.profile.FKuserID
		}
		this.spinner.show();
		this.userService.getUserFamilyDetail(requestData).subscribe(
		data => {
			if (!data.error) {
			//Success			
			this.getUserfamilyDetailData = data.data;
			console.log(this.getUserfamilyDetailData, 'getUserfamilyDetailData');
			this.spinner.hide();
			} else {
			//Error
			this.toastr.error("Something went wrong", 'Error!', {
				timeOut: 5000,
				progressBar: true,
				closeButton: true
			});
			this.spinner.hide();
			}
		},
		error => {

		}
		)
	} 

	removeFamily(id){
		Swal.fire({
			title: 'Are you sure?',
			text: 'You Want to Remove this user',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No'
		  }).then((result) => {
			if (result.value) {
			  let currentUser: any = this.authenticationService.currentUserValue; 
			  let fanId = id;
			  this.spinner.show();
		  
			  const requestData = {
				userID: currentUser.profile.FKuserID,
				fansID: fanId
			  }
			  this.userService.removeFamily(requestData).subscribe(
				data => {
				  if (!data.error) {
					//Success
					this.getUserFamily();
					 this.spinner.hide();
				  } else {
					//Error
					this.toastr.error(data.message, 'Error!', {
					  timeOut: 5000,
					  progressBar: true,
					  closeButton: true
					});
					this.spinner.hide();
				  }
				},
				error => {
				 // this.spinner.hide();
			  
				}
			  ) 
			
			} else if (result.dismiss === Swal.DismissReason.cancel) {
			  Swal.fire(
				'Cancelled',
				'',
				'error'
			  )
			}
		  })
	}

  

}
