import { Component, OnInit, Renderer2, Inject, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService, GlobalService, UploadFileService, UserService } from '../../../../_services';
import { NgxSpinnerService } from "ngx-spinner";

import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';
import { ValidateEmailNotTaken, ValidateUsernameNotTaken } from 'src/app/validators';
import * as moment from 'moment';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-bankdetails',
  templateUrl: './bankdetails.component.html',
  styleUrls: ['./bankdetails.component.scss']
})
export class BankdetailsComponent implements OnInit {
  bankDetails: FormGroup;
  submitted = false

  isDashboardMode = true
  title = 'My Bank Details'
  color = 'recharge_headerbg'
  coins: any;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private modalService: BsModalService,
    private renderer: Renderer2,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private globalService: GlobalService,
    private userService: UserService,

    private spinner: NgxSpinnerService
  ) { }
  get fc() { return this.bankDetails.controls; }
  ngOnInit() {

    this.bankDetails = this.formBuilder.group({

      account_holder_firstname: ['', [Validators.required]],
      account_holder_lastname: ['', [Validators.required]],
      account_holder_email: ['', [Validators.required]],
      phoneNo: ['', [Validators.required]],
      account_number: ['', [Validators.required]],
      dob: ['', [Validators.required]],
      adddress1: ['', [Validators.required]],
      country_code: ['US', [Validators.required]],
      city_name: ['', [Validators.required]],
      state_name: ['', [Validators.required]],
      zip: ['', [Validators.required]],
      // newOTP: ['', [Validators.required]],
    });
    this.getBankDetails();
  }
  getBankDetails() {
    let currentUser: any = this.authenticationService.currentUserValue;

    const requestData = {
      userID: currentUser.profile.FKuserID,

    }
    // const requestData = {
    //   userID: "1",

    // }
    let rGetProfileData: any;
    this.userService.getUserSavedBankAccount(requestData).subscribe(
      response => {

        rGetProfileData = response.data;
        console.log('response', rGetProfileData)
        if (!response.error) {


          this.bankDetails.patchValue(
            {
              "account_holder_firstname": rGetProfileData.account_holder_firstname,
              "account_holder_lastname": rGetProfileData.account_holder_lastname,
              "account_holder_email": rGetProfileData.account_holder_email,
              "phoneNo": rGetProfileData.phoneNo,
              "state_name": rGetProfileData.state_name,
              "city_name": rGetProfileData.city_name,
              "zip": rGetProfileData.zip,
              "adddress1": rGetProfileData.adddress1,
              "dob": rGetProfileData.dob,
              "account_number": rGetProfileData.accountNo,



            }
          );
          //  console.log('editInfoForm', this.editInfoForm);


          this.spinner.hide();
        }
      },
      error => {

        this.toastr.error("Sorry, something went wrong there. Try again", 'Error!', {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
        this.spinner.hide();
      }
    );
  }
  sendBankDetails() {
    this.submitted = true;
    let currentUser: any = this.authenticationService.currentUserValue;

    if (!this.bankDetails.invalid) {
      this.spinner.show();

      this.userService.saveBankAccount({
        userID: currentUser.profile.FKuserID,
        account_holder_firstname: this.bankDetails.value.account_holder_firstname,
        account_holder_lastname: this.bankDetails.value.account_holder_lastname,
        account_number: this.bankDetails.value.account_number,
        account_holder_email: this.bankDetails.value.account_holder_email,
        city_name: this.bankDetails.value.city_name,
        state_name: this.bankDetails.value.state_name,
        zip: this.bankDetails.value.zip,
        adddress1: this.bankDetails.value.adddress1,
        adddress2: "",
        country_code: this.bankDetails.value.country_code,
        phoneNo: this.bankDetails.value.phoneNo,
        dob: this.bankDetails.value.dob,
        routing_number: "110000000",
        ssn: "000-00-0000",
      }).subscribe(
        response => {



          if (!response.error) {
            this.spinner.hide();
            this.toastr.success("Account Details Added Successfully", 'Success!', {
              timeOut: 5000,
              progressBar: true,
              closeButton: true
            });
          }
          else {
            this.spinner.hide();
            this.toastr.error(response.message, 'Error!', {
              timeOut: 5000,
              progressBar: true,
              closeButton: true
            });
          }



        },
        error => {


          this.toastr.error("Sorry, something went wrong there. Try again", 'Error!', {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
          this.spinner.hide();
        }
      );

    }
    else {
      alert('error');
    }

  }

}
