import { Component, OnInit } from '@angular/core';
import { AuthenticationService, GlobalService, UploadFileService, UserService } from '../../../_services';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-dash-link-settings',
  templateUrl: './dash-link-settings.component.html',
  styleUrls: ['./dash-link-settings.component.css']
})
export class DashLinkSettingsComponent implements OnInit {

  constructor(
    private toastr: ToastrService,
    private authenticationService: AuthenticationService,
    private globalService: GlobalService,
    private userService: UserService,
    private spinner: NgxSpinnerService,
  ) {
    const Swal = require('sweetalert2')
   }
   addFeaturedReq(){
    let currentUser: any = this.authenticationService.currentUserValue;
    const requestData = {
      userID: currentUser.profile.FKuserID,
    
    }
    this.spinner.show();

    this.userService.addFeaturedReq(requestData).subscribe(
      data => {
        console.log(">>>>>>>",data);
        if (!data.error) {
          this.toastr.success(data.message, 'Success!', {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
          //Success
          //  this.getAllBlockedUserData = data.data;
           
           this.spinner.hide();
        } else {
          //this.getAllBlockedUserData = [];
          //Error
          this.toastr.error(data.message, 'Error!', {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
          this.spinner.hide();
        }
      },
      error => {

      }
    )

   }
  ngOnInit() {
  }

}
