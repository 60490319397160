import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import { Email, Username } from '../_models';
import { map } from 'rxjs/internal/operators/map';
import { IUserResponse, User } from '../_models/search';
import { tap } from 'rxjs/internal/operators/tap';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({ providedIn: 'root' })
export class GlobalService {
    constructor(
        private http: HttpClient,
        private route: ActivatedRoute,
        private router: Router,
    ) { 

    }

    getCountries() {
        return this.http.get<any>(`${environment.apiUrl}common/settings/getCountries`);
    }
    globalSearch(keywored) {
        return this.http.get<any>(`${environment.apiUrl}app/common/globalSearch?keywored=`+keywored+`&start=0&limit=10&type=USER`)
        .pipe(map(response => {
            return response.data.USER ? response.data.USER : [];
        }));
    }
    search(filter: {keywored: string} = {keywored: 'a'}, page = 1): Observable<IUserResponse> {
        console.log("filter.keywored", filter.keywored);
    return this.http.get<IUserResponse>(`${environment.apiUrl}app/common/globalSearch?keywored=`+filter.keywored+`&start=0&limit=10&type=USER`)
    .pipe(
      tap((response: IUserResponse) => {
        console.log("response", response);
        if (response.data) {
            response.data.USER.data = response.data.USER.data
            .map(user => new User(user.id, user.username))
            // Not filtering in the server since in-memory-web-api has somewhat restricted api
            .filter(user => user.username ? user.username.includes(filter.keywored) : null)
          console.log("response", response);
          return response;
        } else {
          let nullRes = {"error":false,"message":"Successful","data":{"USER":{"data":[],"total":"0"}},"total":0};
          console.log("nullRes", nullRes);
          return nullRes;
        }
      })
    );
  }
    getIpdata() {
        return this.http.get<any>(`https://ipinfo.io?token=f409a0a3b730c0`);
    }

    checkUserPhoneNo(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/checkUserPhoneNo`, requestData);
    }

    getUserDetails(requestData){
        return this.http.post<any>(`${environment.apiUrl}app/user/getUserDetails`, requestData);
    }


    redirectToHome(){
        this.router.navigate(['/']);
    }

    verifyOtp(enteredOtp){
    //Need to change
      var verifyOtpData = localStorage.getItem('verify-otp');
      if (verifyOtpData) {
        const verifyOtpJson = JSON.parse(verifyOtpData);
        if (verifyOtpJson.otp == enteredOtp) {
            return true;
        } else {
            return false;
        }
      }else {
          return false;
      }
    }
    checkEmailNotTaken(email: string, customerId: string) {
        return this.http.post<Email>(`${environment.apiUrl}app/user/checkUserEmail`, {
          email,
          customerId
        });
    }
    checkUserNameNotTaken(username: string, customerId: string) {
        return this.http.post<Username>(`${environment.apiUrl}app/user/checkUserName`, {
          username,
          customerId
        });
    }
    getUserFriendFamilyFollowerDetail(requestData){
        return this.http.post<any>(`${environment.apiUrl}app/user/getUserFriendFamilyFollowerDetail`, requestData);
    }
}