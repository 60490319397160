import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/_services/user.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from 'moment';


@Component({
  selector: 'app-user-settings-broadcast',
  templateUrl: './user-settings-broadcast.component.html',
  styleUrls: ['./user-settings-broadcast.component.css']
})
export class UserSettingsBroadcastComponent implements OnInit {
  isDashboardMode = true
  title = 'Broadcast Data Details'
  color = 'broadcast-head-bg'
  user:any;
  userId: any;
  getBroadcustDetailsDataList:any;
  moment: any = moment;
  totalDuration:any;
  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,

  ) { }

  ngOnInit() {
    this.spinner.show()
    this.user = JSON.parse(localStorage.getItem('currentUser'))
    this.userId = this.user.profile.FKuserID

    this.userService.getBroadcustDetailsData({userID : this.userId}).subscribe(
      response => {
       
        if (!response.error) {
          this.getBroadcustDetailsDataList = response.data;
          var now  = moment(response.data.endON).format("DD/MM/YYYY HH:mm:ss");
          var then = moment(response.data.startOn).format("DD/MM/YYYY HH:mm:ss");
          
       this.totalDuration =  moment.utc(moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(then,"DD/MM/YYYY HH:mm:ss"))).format("HH:mm:ss");
console.log(">>>>>>>>",this.totalDuration);



        }
        this.spinner.hide()
      },
      error => {
        this.spinner.hide()
      }
    )
  }

}
