import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-multi-beam',
  templateUrl: './multi-beam.component.html',
  styleUrls: ['./multi-beam.component.css']
})
export class MultiBeamComponent implements OnInit {
  videoOptions: MediaTrackConstraints = {
    width: {exact: 100},
    height: {exact: 100}
  };
  constructor() { }

  ngOnInit() {
  }

}
