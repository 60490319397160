import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, GlobalService, UploadFileService, UserService } from '../../../_services';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';


import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-userlineup',
  templateUrl: './userlineup.component.html',
  styleUrls: ['./userlineup.component.scss']
})
export class UserlineupComponent implements OnInit {
  lineUpType: '1'
  isDashboardMode = true
  title = 'LineUp Details'
  color = 'adding-lineup-head-bg'
  modalRef: BsModalRef;
  addLineUp: FormGroup;
  submitted = false
  typeData: any;
  getUserChannel: any;
  mytime: Date = new Date();
  HashTags: any = [];
  isShow = false;
  findhasTag: any;
  LineUpListData: any;
  moment: any = moment;
  showCurrentdate: any;
  minDate: Date;
  constructor(
    private modalService: BsModalService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private globalService: GlobalService,
    private userService: UserService,
    private spinner: NgxSpinnerService

  ) {  const Swal = require('sweetalert2')
  this.minDate = new Date();}

  getLineUpdata(a) {
    this.lineUpType = a;
    this.spinner.show();
    let currentUser: any = this.authenticationService.currentUserValue;

    this.route.paramMap.subscribe(params => { 
      
      this.userService.getBroadcastLineupByUserByType(
        {
          userID:  params.get('id'),
          lineupTypeID: this.lineUpType,
          fromUserID: currentUser.profile.FKuserID,
        }
      ).subscribe(
        data => {
          if (!data.error) {
  
            this.LineUpListData = data.data;
            console.log("lineupdata>>>>>>>>>", this.LineUpListData);
            this.spinner.hide();
          } else {
            //Error
            this.spinner.hide();
          }
        },
        error => {
          this.spinner.hide();
        }
      )
  });

  }
  LineUpTodayData() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    let todaydate = yyyy + '-' + mm + '-' + dd;
    // return this.LineUpListData && this.LineUpListData.filter(x => x.scheduleDate == todaydate);

    if (this.LineUpListData && this.LineUpListData.todaysLineup) {
      return this.LineUpListData.todaysLineup;
    }
    else {
      return [];
    }

  }
  LineUpNotTodayData() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    let todaydate = yyyy + '-' + mm + '-' + dd;
    // return this.LineUpListData && this.LineUpListData.filter(x => x.scheduleDate != todaydate);

    if (this.LineUpListData && this.LineUpListData.leterLineup) {
      return this.LineUpListData.leterLineup;
    }
    else {
      return [];
    }
  }
  getAllLineUp(isdata = '1') {

    this.spinner.show();
    let currentUser: any = this.authenticationService.currentUserValue;

   this.route.paramMap.subscribe(params => { 
      
      this.userService.getBroadcastLineupByUserByType(
        {
          userID: params.get('id'),
          lineupTypeID: isdata,
          fromUserID: currentUser.profile.FKuserID,
        }
      ).subscribe(
        data => {
          if (!data.error) {
  
            this.LineUpListData = data.data;
            console.log("lineupdata>>>>>>>>>", this.LineUpListData);
            this.spinner.hide();
          } else {
            //Error
            this.spinner.hide();
          }
        },
        error => {
  
        }
      )
  });
  
  }
  ngOnInit() {

    this.getAllLineUp();

  }
  addInterest(id){
    this.spinner.show();
    let currentUser: any = this.authenticationService.currentUserValue;
    this.route.paramMap.subscribe(params => { 
       
       this.userService.addInteresttoBroadcastLineupByUser(
         {
          userID: currentUser.profile.FKuserID,
           broadcastLineupID: id,
           status:"1"
         }
       ).subscribe(
         data => {
           if (!data.error) {
            this.toastr.success(data.message, 'Success!', {
              timeOut: 5000,
              progressBar: true,
              closeButton: true
            });
            this.getAllLineUp();
             this.spinner.hide();
           } else {
             //Error
             this.toastr.error(data.message, 'Error!', {
              timeOut: 5000,
              progressBar: true,
              closeButton: true
            });
             this.spinner.hide();
           }
         },
         error => {
   
         }
       )
   });
  }

}
