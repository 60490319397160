import { Component, OnInit } from '@angular/core';
import { AuthenticationService, GlobalService, UploadFileService, UserService } from '../../../../_services';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-user-settings-blocked-list',
  templateUrl: './user-settings-blocked-list.component.html',
  styleUrls: ['./user-settings-blocked-list.component.css']
})
export class UserSettingsBlockedListComponent implements OnInit {
  isDashboardMode = true
  title = 'Blocked List'
  color = 'blocked-list-head-bg'
  getAllBlockedUserData:any;
  constructor(
    private toastr: ToastrService,
    private authenticationService: AuthenticationService,
    private globalService: GlobalService,
    private userService: UserService,
    private spinner: NgxSpinnerService,

  ) {
    const Swal = require('sweetalert2')

   }
   getAllBlockedUser(){

    let currentUser: any = this.authenticationService.currentUserValue;
    const requestData = {
      userID: currentUser.profile.FKuserID,
    
    }
    this.spinner.show();
    this.userService.getAllBlockedUser(requestData).subscribe(
      data => {
        console.log(">>>>>>>",data);
        if (!data.error && data.data.length>0) {
          //Success
            this.getAllBlockedUserData = data.data;
           
           this.spinner.hide();
        } else {
          this.getAllBlockedUserData = [];
          //Error
          // this.toastr.error("You can add only 3 items!", 'Error!', {
          //   timeOut: 5000,
          //   progressBar: true,
          //   closeButton: true
          // });
          this.spinner.hide();
        }
      },
      error => {

      }
    )
   }
   unblockUser(id){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You Want to Unblock this user',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        let currentUser: any = this.authenticationService.currentUserValue; 
      
        this.spinner.show();
    
        const requestData = {
          userID: currentUser.profile.FKuserID,
          toUserID: id,
          status:0
        }
        this.userService.unBlockUser(requestData).subscribe(
          data => {
            if (!data.error) {
              //Success
              this.getAllBlockedUser();
             //  this.getUserFollowerDetailData = data.data;
               this.spinner.hide();
            } else {
              //Error
              this.toastr.error(data.message, 'Error!', {
                timeOut: 5000,
                progressBar: true,
                closeButton: true
              });
              this.spinner.hide();
            }
          },
          error => {
           // this.spinner.hide();
        
          }
        )    
    
      // For more information about handling dismissals please visit
      // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          '',
          'error'
        )
      }
    })

   }

  ngOnInit() {
    this.getAllBlockedUser();
  }

}
