import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService, GlobalService, UploadFileService, UserService } from '../../../../../_services';
import { NgxSpinnerService } from "ngx-spinner";

import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';
import { RechargeService } from 'src/app/_services/recharge.service';
import * as moment from 'moment';


@Component({
  selector: 'app-diamonds-tlog',
  templateUrl: './diamonds-tlog.component.html',
  styleUrls: ['./diamonds-tlog.component.scss']
})
export class DiamondsTlogComponent implements OnInit {
  moment: any = moment;
  diamondstLog: any;
  isDashboardMode = true
  title = 'Diamonds Transactions'
  color = 'transaction_headerbg'

  constructor(
    private renderer: Renderer2,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private globalService: GlobalService,
    private userService: UserService,
    private rechargeService: RechargeService,

    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.getTransactionLog();
  }

  getTransactionLog(){
    this.spinner.show();
    let currentUser: any = this.authenticationService.currentUserValue;
    this.rechargeService.getTransactionLog({
      userID: currentUser.profile.FKuserID,
      type: "diamonds",
    }).subscribe(
      response => {
        this.diamondstLog = response.data
        this.spinner.hide();
      },
      error => {

      }
    )
  }

}
