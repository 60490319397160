import { AbstractControl } from '@angular/forms';
import { GlobalService } from '../_services';
import { map } from 'rxjs/operators';

export class ValidateUsernameNotTaken {
  static createValidator(globalService: GlobalService, customerId: string = null) {
    return (control: AbstractControl) => {
      return globalService.checkUserNameNotTaken(control.value, customerId).pipe(map(res => {
        console.log("ChkUsername", res.error)
        return res.error ? {usernameTaken: true} : null;
      }));
    }
  }
}