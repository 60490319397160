import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css']
})
export class MainNavComponent implements OnInit {
  theme: string;

  constructor() { }

  ngOnInit() {
    console.log('Theme', localStorage.getItem('theme'))
    switch (localStorage.getItem('theme')) {
      case '1':
        this.theme = ''
        break;
      case '2':
        this.theme = '-green'
        break;
      case '3':
        this.theme = '-orange'
        break;
    
      default:
        break;
    }
  }

}
