import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import {FormControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { PollsService } from 'src/app/_services/polls.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-add-update-polls-modal',
  templateUrl: './add-update-polls-modal.component.html',
  styleUrls: ['./add-update-polls-modal.component.scss']
})
export class AddUpdatePollsModalComponent implements OnInit {
  @Input() poll;

  addPoll: FormGroup;
  submitted: boolean;
  modalRef: BsModalRef;
  polls:any;
  poll_id: any;
  questionid: any;
  question: any;
  optionValue1: any;
  optionValue3: any;
  optionValue4: any;
  optionValue2: any;
  addMode = true;

  constructor(
    public activeModal: NgbActiveModal,
    public spinner: NgxSpinnerService,
    public toastr: ToastrService,
    public pollsService: PollsService,
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
  ) { }

  ngOnInit() {
    console.log("poll", this.poll)
    if (this.poll) {
      //Edit
      this.addMode = false;

      this.poll_id = this.poll.id
      this.questionid = this.poll.questions[0].questionid
      this.question = this.poll.questions[0].question
      this.poll.questions[0].options.forEach((option : any, key :any) => {
        if (key == 0) {
          this.optionValue1 = option.options
        }
        if (key == 1) {
          this.optionValue2 = option.options
        }
        if (key == 2) { 
          this.optionValue3 = option.options
        }
        if (key == 3) {
          this.optionValue4 = option.options
        }
      });
      
    }else {
      //Add
      this.addMode = true;

      this.poll_id = '';
      this.questionid = '';
      this.optionValue1 = '';
      this.optionValue2 = '';
      this.optionValue3 = '';
      this.optionValue4 = '';
    }
    this.addPoll = this.fb.group({
      poll_id: [this.poll_id],
      questionid: [this.questionid],
      question: [this.question, [Validators.required]],
      option1: [this.optionValue1, [Validators.required]],
      option2: [this.optionValue2, [Validators.required]],
      option3: [this.optionValue3, []],
      option4: [this.optionValue4, []],
    });

  }
  get f() { return this.addPoll.controls; }

  onSubmit() {
    this.submitted = true;
    this.spinner.show();
    if (this.addPoll.invalid) {
      this.spinner.hide();
      return;
    }
    let currentUser: any = this.authenticationService.currentUserValue;
    let options = [];
    if (this.addPoll.value.option1) {
      options.push(
        {
          options : this.addPoll.value.option1
        }
      )
    }
    if (this.addPoll.value.option2) {
      options.push(
        {
          options : this.addPoll.value.option2
        }
      )
    }
    if (this.addPoll.value.option3) {
      options.push(
        {
          options : this.addPoll.value.option3
        }
      )
    }
    if (this.addPoll.value.option4) {
      options.push(
        {
          options : this.addPoll.value.option4
        }
      )
    }
    let questions = [
      {
        questionid : this.addPoll.value.questionid,
        question : this.addPoll.value.question,
        options : options
      }
    ];
    let requestData = {
      userID : currentUser.profile.FKuserID,
      id:this.addPoll.value.poll_id,
      title:this.addPoll.value.question,
      description: this.addPoll.value.question,
      questions: questions
    }
    this.pollsService.addUserPolls(requestData).subscribe(
      response => {
        this.activeModal.dismiss();
        this.spinner.hide();
        if (!response.error) {
          this.toastr.success(response.message, 'Success!');
        }
      },
      error => {
        this.modalRef.hide();
        this.spinner.hide();
      }
    )
  }

}
