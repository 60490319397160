import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService, UserService } from '../_services';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StatsComponent,LinksComponent } from "./index";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  userId :number
  user :any
  returnUrl :any
  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('currentUser'))
    this.spinner.show()
    if (this.user) {
      this.userId = this.user.profile.FKuserID
      this.userService.getUserDetails({id : this.user.profile.FKuserID}).subscribe(
        response => {
           if (response.error) {
             //Error
             this.logout();
             this.spinner.hide();
           } else {
             //Success
             this.spinner.hide();
             localStorage.setItem('currentUser', JSON.stringify(response.data))
             this.user = JSON.parse(localStorage.getItem('currentUser'))
           }
        },
        error => {
            //Error
            this.spinner.hide()
        }
      );
    }
    // get return url from route parameters or default to '/channels'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/login';
  }

  logout(){
    this.authenticationService.logout();
    this.router.navigate([this.returnUrl]);
  }

}
