import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxAgoraService, Stream, AgoraClient, ClientEvent, StreamEvent } from 'ngx-agora';
import { BroadcastService } from 'src/app/_services/broadcast.service';
import AgoraRTM from 'agora-rtm-sdk';
import { environment } from 'src/environments/environment';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Inviteh2hComponent } from '../modal/inviteh2h/inviteh2h.component';
import { InviteGuestComponent } from '../modal/invite-guest/invite-guest.component';
import { AssignPollsModalComponent } from '../modal/assign-polls-modal/assign-polls-modal.component';
import { CoinDropComponent } from '../modal/coin-drop/coin-drop.component';
import { BlockUserModalComponent } from '../modal/block-user-modal/block-user-modal.component';
import { interval } from 'rxjs';
import { BroadcastInfoModalComponent } from '../modal/broadcast-info-modal/broadcast-info-modal.component';
import { UserService } from 'src/app/_services';


@Component({
  selector: 'app-head-to-head',
  templateUrl: './head-to-head.component.html',
  styleUrls: ['./head-to-head.component.scss']
})
export class HeadToHeadComponent implements OnInit {
  title = 'angular-video';
  localCallId = 'agora_local';
  remoteCalls: string[] = [];

  private client: AgoraClient;
  private localStream: Stream;
  private uid: number;
  sub: any;
  broadcastId: string;
  btype: string;
  userID: string;
  broadcastPhysicalChannel: string;
  geolocation: any;
  token: any;
  broadcastTitle: any;
  broadcasterName: any;
  broadcasterAvtar: any;
  coHostName: any;
  coHostAvtar: any;
  startOn: any;
  audioMode: string;
  channel: any;
  rtmChatChannel: any;
  agoraRTM: any = AgoraRTM.createInstance(environment.agora.appId);
  emit: any;
  messages = [];
  msgText: any = '';
  emoji = false;
  ChatUser: any;
  broadcastType: any;
  user: any;
  broadcasterId: any;
  tokenForChat: string;
  userId: any;
  totalGiftRecvd: any;
  totalLikeFormated: any;
  totalFansFormated: any;
  totalFollowersFormated: any;
  totalViewer: any;
  totalGiftRecvdDiamondsFormated: any;
  totalActiveViewerFormated: any;
  totalGiftRecvdCoins: any;
  recStarted = false;
  chat = false;
  theme: string;
  section: string = '';
  startRecTime: Date;
  RtmToken: string;
  url: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private broadcastService: BroadcastService,
    private renderer: Renderer2,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private ngxAgoraService: NgxAgoraService,
    private _location: Location,
    private userService: UserService,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    switch (localStorage.getItem('theme')) {
      case '1':
        this.theme = ''
        break;
      case '2':
        this.theme = '-green'
        break;
      case '3':
        this.theme = '-orange'
        break;
    
      default:
        break;
    }

    this.startRecTime = new Date(localStorage.getItem('rec_start'))
    
    this.renderer.addClass(this.document.body, 'yellow-bg');
    this.renderer.addClass(this.document.body, 'mt-0');

    this.client = this.ngxAgoraService.createClient({ mode: 'live', codec: 'h264' });
    // this.client.setClientRole("audience")
    this.assignClientHandlers();
    this.sub = this.route.paramMap.subscribe(params => {
      this.btype = params.get('btype');
      this.uid = parseInt(params.get('userID'), 10);
      this.broadcastPhysicalChannel = params.get('broadcastPhysicalChannel');
      this.broadcastId = params.get('broadcastId');
      this.audioMode = params.get('audioMode');
      this.tokenForChat = localStorage.getItem('tokenForChat');
    });
    this.geolocation = JSON.parse(localStorage.getItem('geolocation'));
    const audio = this.audioMode == '1' ? false : true;
    this.localStream = this.ngxAgoraService.createStream({ streamID: this.uid, audio: true, video: audio, screen: false });
    this.assignLocalStreamHandlers();
    const rdetails = JSON.parse(localStorage.getItem('rdetails'));
    if (rdetails && rdetails.broadcastID == this.broadcastId && rdetails.sid) {
      this.recStarted = true;
    } else {
      this.recStarted = false;
    }
    
    this.getBroadcastStats()
    //this.localStream.setVideoProfile('720p');
    this.initLocalStream(() => this.join(uid => this.publish(), error => console.error(error)));
  }

  private assignLocalStreamHandlers(): void {
    this.localStream.on(StreamEvent.MediaAccessAllowed, () => {
      console.log('accessAllowed camera and mic');
    });

    // The user has denied access to the camera and mic.
    this.localStream.on(StreamEvent.MediaAccessDenied, () => {
      console.log('accessDenied camera and mic');
    });
  }

  private initLocalStream(onSuccess?: () => any): void {
    this.localStream.init(
      () => {
        // The user has granted access to the camera and mic.
        this.localStream.play(this.localCallId);
        if (onSuccess) {
          onSuccess();
        }
      },
      err => console.error('getUserMedia failed', err)
    );
  }
  /**
   * Attempts to connect to an online chat room where users can host and receive A/V streams.
   */
  join(onSuccess?: (uid: number | string) => void, onFailure?: (error: Error) => void): void {
    this.broadcastService.getBroadcastDetail({
      'userID': this.uid,
      'broadcastID': this.broadcastId,
      'lat': this.geolocation.latitude,
      'lng': this.geolocation.longitude
    }).subscribe(
      response => {
        console.log('H2H stream', response);
        if (response.error) {
          return this.toastr.error('Something went wrong', 'Error!', {
            timeOut: 2000,
            progressBar: true,
            closeButton: true
          });

        }
        // Set Brodcast Data
        this.broadcastTitle = response.data.broadcastDetails.title;
        var now = new Date(response.data.broadcastDetails.startOn);
        now.setMinutes(now.getMinutes() - 30);
        localStorage.setItem("start_time", now.toISOString())

        this.startOn = now.toISOString();
        this.broadcasterId  = response.data.broadcastDetails.broadcasterDetails.FKuserID;
        this.broadcasterName  = response.data.broadcastDetails.broadcasterDetails.username;
        this.broadcasterAvtar  = response.data.broadcastDetails.broadcasterDetails.Avtar;

        //this.startOn  = response.data.broadcastDetails.startOn;
        this.userService.getUserDetails({ id: this.uid }).subscribe(
          userData => {
            if (!userData.error) {
              this.broadcasterName = userData.data.profile.username;
              this.broadcasterAvtar = userData.data.profile.Avtar;
            }
          }
        );
        if (this.uid != response.data.broadcastDetails.broadcasterDetails.FKuserID) {
          this.broadcastService.getChannelTokenToJoinABroadcast({
            'userID': this.uid,
            'broadcastID': this.broadcastId,
            'lat': this.geolocation.latitude,
            'lng': this.geolocation.longitude
          }).subscribe(
            bresponse => {
              this.token = bresponse.data.token;
              this.client.join(this.token, this.broadcastPhysicalChannel, this.uid, onSuccess, onFailure);

              this.RtmToken  = bresponse.data.tokenForChat;
              this.ChatUser  = bresponse.data.ChatUser;
              console.log("RTM Guest Login Details", this.ChatUser, this.RtmToken);
              this.agoraRTM.login({ token: this.RtmToken, uid: this.ChatUser }).then(() => {
                console.log('AgoraRTM client login success');
                this.rtmChatChannel = this.agoraRTM.createChannel(this.broadcastPhysicalChannel);
                this.user = JSON.parse(localStorage.getItem('currentUser'));
                console.log('currentUser', this.user.profile.Avtar);
                this.agoraRTM.setLocalUserAttributes(
                  {
                    name : this.user.profile.ChatUser,
                    img : this.user.profile.Avtar ? this.user.profile.Avtar : 'assets/images/profile-icon.png'
                  });
          
                this.joinRtmChatChannel();
              }).catch(err => {
                console.log('AgoraRTM client login failure', err);
              });
            }
          )
        } else {
          // Set Brodcast Data
          this.token = response.data.broadcastDetails.token
          //this.token  = response.data.token;
          this.client.join(this.token, this.broadcastPhysicalChannel, this.uid, onSuccess, onFailure);
          
          this.RtmToken  = this.tokenForChat;
          this.ChatUser  = this.broadcasterName;
      
          this.agoraRTM.login({ token: this.RtmToken, uid: this.ChatUser }).then(() => {
            console.log('AgoraRTM client login success');
            this.rtmChatChannel = this.agoraRTM.createChannel(this.broadcastPhysicalChannel);
            this.user = JSON.parse(localStorage.getItem('currentUser'));
            console.log('currentUser', this.user.profile.Avtar);
            this.agoraRTM.setLocalUserAttributes(
              {
                name : this.user.profile.ChatUser,
                img : this.user.profile.Avtar ? this.user.profile.Avtar : 'assets/images/profile-icon.png'
              });
              
            this.joinRtmChatChannel();
          }).catch(err => {
            console.log('AgoraRTM client login failure', err);
          });
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  /**
   * Attempts to upload the created local A/V stream to a joined chat room.
   */
  publish(): void {
    this.client.publish(this.localStream, err => console.log('Publish local stream error: ' + err));
  }

  leaveChannel() {
    this.client.leave(() => {
      console.log('client leaves channel');
      this.broadcastService.exitHeadtoHead({
        userID: this.uid,
        broadcastID: this.broadcastId
      }).subscribe(
        respose => {
          console.log('exitHeadtoHead', respose);
          if (!respose.error) {
            this.broadcastService.stopChannelBrodcastByUser({
              userID: this.uid,
              broadcastID: this.broadcastId
            }).subscribe(
              response => {
                this.backClicked();
              }
            );
          }
        },
        error => {

        }
      );

    }, (err) => {
      console.log('client leave failed ', err); // error handling
    });
  }

  backClicked() {
    this.router.navigate(['/']);
  }

  private assignClientHandlers(): void {
    this.client.on(ClientEvent.LocalStreamPublished, evt => {
      console.log('Publish local stream successfully');
    });

    this.client.on(ClientEvent.Error, error => {
      console.log('Got error msg:', error.reason);
      if (error.reason === 'DYNAMIC_KEY_TIMEOUT') {
        this.client.renewChannelKey(
          '',
          () => console.log('Renewed the channel key successfully.'),
          renewError => console.error('Renew channel key failed: ', renewError)
        );
      } else {
        console.log('My Error', error.reason);
      }
    });

    this.client.on(ClientEvent.RemoteStreamAdded, evt => {
      const stream = evt.stream as Stream;
      this.client.subscribe(stream, { audio: true, video: true }, err => {
        console.log('Subscribe stream failed', err);
      });
    });

    this.client.on(ClientEvent.RemoteStreamSubscribed, evt => {
      const stream = evt.stream as Stream;
      const id = this.getRemoteId(stream);
      if (!this.remoteCalls.length) {
        this.userService.getUserDetails({ id: evt.stream.streamId }).subscribe(
          response => {
            if (!response.error) {
              this.coHostName = response.data.profile.username;
              this.coHostAvtar = response.data.profile.Avtar;
            }
          }
        );
        this.remoteCalls.push(id);
        setTimeout(() => stream.play(id), 1000);
      }
    });

    this.client.on(ClientEvent.RemoteStreamRemoved, evt => {
      const stream = evt.stream as Stream;
      if (stream) {
        stream.stop();
        this.remoteCalls = [];
        console.log(`Remote stream is removed ${stream.getId()}`);
      }
    });

    this.client.on(ClientEvent.PeerLeave, evt => {
      const stream = evt.stream as Stream;
      if (stream) {
        stream.stop();
        this.remoteCalls = this.remoteCalls.filter(call => call !== `${this.getRemoteId(stream)}`);
        console.log(`${evt.uid} left from this channel`);
      }
    });
  }

  private getRemoteId(stream: Stream): string {
    return `agora_remote-${stream.getId()}`;
    // return `agora_remote`;
  }

  getBroadcastStats() {
    this.broadcastService.getBroadcastDetail({
      userID: this.uid,
      broadcastID: this.broadcastId
    }).subscribe(
      response => {
        const statBroadcastDetails = response.data.broadcastDetails.stat;
        this.totalGiftRecvd = statBroadcastDetails.totalGiftRecvd;
        this.totalGiftRecvdCoins = statBroadcastDetails.totalGiftRecvdCoins;
        this.totalLikeFormated = statBroadcastDetails.totalLikeFormated;
        this.totalFansFormated = statBroadcastDetails.totalFansFormated;
        this.totalFollowersFormated = statBroadcastDetails.totalFollowersFormated;
        this.totalViewer = statBroadcastDetails.totalViewer;
        this.totalActiveViewerFormated = statBroadcastDetails.totalActiveViewerFormated;
        this.totalGiftRecvdDiamondsFormated = statBroadcastDetails.totalGiftRecvdDiamondsFormated;
        //console.log('getBroadcastDetail stat', response.data.broadcastDetails.stat);
      }
    );
  }

  rtmLogin() {
    this.RtmToken  = this.tokenForChat;
    this.ChatUser  = this.broadcasterName;

    this.agoraRTM.login({ token: this.RtmToken, uid: this.ChatUser }).then(() => {
      console.log('AgoraRTM client login success');
      this.rtmChatChannel = this.agoraRTM.createChannel(this.broadcastPhysicalChannel);
      this.user = JSON.parse(localStorage.getItem('currentUser'));
      console.log('currentUser', this.user.profile.Avtar);
      this.agoraRTM.setLocalUserAttributes(
        {
          name : this.user.profile.ChatUser,
          img : this.user.profile.Avtar ? this.user.profile.Avtar : 'assets/images/profile-icon.png'
        });

      this.joinRtmChatChannel();
    }).catch(err => {
      console.log('AgoraRTM client login failure', err);
    });
  }

  joinRtmChatChannel() {
    this.rtmChatChannel.join().then(() => {
        /* Your code for handling the event of a join-channel success. */
        console.log('Channel joined');
        this.subscribeChannelEvents();
      }).catch(error => {
        /* Your code for handling the event of a join-channel failure. */
        console.log('Channel join failed');
      });
  }

  private subscribeChannelEvents(): void {
    this.rtmChatChannel.on('ChannelMessage', (message, senderId) => { // text: text of the received channel message; senderId: user ID of the sender.
      /* Your code for handling events, such as receiving a channel message. */
      const enc = new TextDecoder('utf-8');
      //console.log('text recived', JSON.parse(enc.decode(message.rawMessage)));
      this.getBroadcastStats()

      const senderMessage = JSON.parse(enc.decode(message.rawMessage));
      if (senderMessage.msgType == 'chat') {
        if (this.messages.length >= 5) {
          this.messages.shift();
        }
        this.agoraRTM.getUserAttributes(senderId).then(sender => {
          this.messages.push({
            text: senderMessage.msg,
            senderName : senderMessage.userName,
            senderImg : senderMessage.avtar,
            sender : senderMessage,
          });
        });
      } else {

      }
    });
  }

  sendMessage() {
    if (this.msgText) {
      this.user = JSON.parse(localStorage.getItem('currentUser'));
      const encoder = new TextEncoder();
      const rawMessage = encoder.encode(JSON.stringify({
        msgDate : '',
        msg : this.msgText,
        msgType : 'chat',
        broadcastId : this.broadcastId.toString(),
        broadcaster_fkUserId : this.broadcasterId.toString(),
        broadcaster_avtar : this.broadcasterAvtar,
        broadcaster_firstName : this.broadcasterName,
        broadcaster_lastName : '',
        broadcaster_location : '',
        broadcaster_userName : this.broadcasterName,
        fkUserId : this.uid.toString(),
        userName : this.user.profile.ChatUser,
        firstName : this.user.profile.ChatUser,
        lastName : '',
        location : '',
        avtar : this.user.profile.Avtar ? this.user.profile.Avtar : 'assets/images/profile-icon.png',
      }));
      this.rtmChatChannel.sendMessage({ rawMessage,  messageType: 'RAW', description: '' }).then(() => {
        /* Your code for handling events, such as a channel message-send success. */
        this.agoraRTM.getUserAttributes(this.ChatUser).then(sender => {
          console.log('currentSender', sender);
          this.messages.push({
            text: this.msgText,
            senderName : sender.name,
            senderImg : sender.img
          });
          if (this.messages.length >= 5) {
            this.messages.shift();
          }
          this.msgText = '';
        });
      }).catch(error => {
        /* Your code for handling events, such as a channel message-send failure. */
        console.log('sendMessage failed');
      });
    }
  }

  muteUser(user, senderDetails) {
    senderDetails.msgType = 'muteInBroadcast';
    senderDetails.muted_fkUserId = senderDetails.fkUserId;
    this.broadcastService.muteViewer({
      broadcastID: this.broadcastId,
      userID: senderDetails.fkUserId,
      status: 1,
    }).subscribe(
      response => {

      },
      error => {

      }
    );
    console.log('Mute User Details', senderDetails);
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    const encoder = new TextEncoder();

    const rawMessage = encoder.encode(JSON.stringify(senderDetails));

    this.rtmChatChannel.sendMessage({ rawMessage,  messageType: 'RAW', description: '' }).then(() => {
      console.log('sendMessage success');
    }).catch(error => {
      /* Your code for handling events, such as a channel message-send failure. */
      console.log('sendMessage failed');
    });
  }

  coinDropMsg(text){
     const msg = {
      msgType: 'coinDropMsg',
      msg: text
     }
     this.user = JSON.parse(localStorage.getItem('currentUser'));
    const encoder = new TextEncoder();

    const rawMessage = encoder.encode(JSON.stringify(msg));
    this.rtmChatChannel.sendMessage({ rawMessage,  messageType: 'RAW', description: '' }).then(() => {
      console.log('Coin Drop sendMessage success');
    }).catch(error => {
      /* Your code for handling events, such as a channel message-send failure. */
      console.log('Coin Drop sendMessage failed');
    });
  }

  openInfoModal(){
    const modalRef = this.modalService.open(BroadcastInfoModalComponent);
    modalRef.componentInstance.userId = this.uid;
    modalRef.componentInstance.broadcastID = this.broadcastId;
  }

  openMsgSection(){
    this.chat = !this.chat
  }

  kickUser(user, senderDetails) {
    senderDetails.msgType = 'kickOutFromBroadcast';
    senderDetails.muted_fkUserId = senderDetails.fkUserId;
    this.broadcastService.removeViewer({
      broadcastID: this.broadcastId,
      userID: senderDetails.fkUserId,
    }).subscribe(
      response => {

      },
      error => {

      }
    );
    console.log('Kick User Details', senderDetails);
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    const encoder = new TextEncoder();

    const rawMessage = encoder.encode(JSON.stringify(senderDetails));

    this.rtmChatChannel.sendMessage({ rawMessage,  messageType: 'RAW', description: '' }).then(() => {
      console.log('sendMessage success');
    }).catch(error => {
      /* Your code for handling events, such as a channel message-send failure. */
      console.log('sendMessage failed');
    });
  }

  addEmoji(event) {
    this.msgText = this.msgText + '' + event.emoji.native;
    this.emoji = false;
  }

  Head2Head() {
    this.client.leave(() => {
      this.broadcastService.stopChannelBrodcastByUser({
        userID : this.uid,
        broadcastID : this.broadcastId
      }).subscribe(
        respose => {
          if (!respose.error) {
            // this.rtmChatChannel.leave()
            // this.agoraRTM.logout()
            const modalRef = this.modalService.dismissAll(Inviteh2hComponent);
            // tslint:disable-next-line: max-line-length
            this.url = 'go-live/head-to-head/' + this.btype + '/' + this.uid + '/' + this.broadcastPhysicalChannel + '/' + this.broadcastId + '/' + this.audioMode;
            return this.router.navigate([this.url]);
          }
        },
        error => {

        }
      );

    }, (err) => {
      console.log('client leave failed ', err); // error handling
    });
  }

  open() {
    const modalRef = this.modalService.open(Inviteh2hComponent);
    modalRef.componentInstance.userId = this.uid;
    modalRef.componentInstance.broadcastID = this.broadcastId;
  }

  openGuestInviteByUsername() {
    const modalRef = this.modalService.open(InviteGuestComponent);
    modalRef.componentInstance.userId = this.uid;
    modalRef.componentInstance.broadcastID = this.broadcastId;
  }

  coinDrop() {
    const modalRef = this.modalService.open(CoinDropComponent);
    modalRef.componentInstance.userId = this.uid;
    modalRef.componentInstance.broadcastID = this.broadcastId;
    modalRef.componentInstance.coinDropped.subscribe(($e) => {
      this.msgText = '😍'+ $e.coins + ' coins dropped. 😍';
      this.sendMessage();
     // this.coinDropMsg(this.msgText);
    });
  }

  openAssignPollsModal() {
    const modalRef = this.modalService.open(AssignPollsModalComponent);
    modalRef.componentInstance.userId = this.uid;
    modalRef.componentInstance.broadcastID = this.broadcastId;
    modalRef.componentInstance.pollAssigned.subscribe(($e) => {
      this.msgText = 'New poll assigned. Participate NOW!!! 😍';
      this.sendMessage();
    });
  }

  blockModal(senderName, senderDetails) {
    console.log('senderDetails', senderDetails);
    const modalRef = this.modalService.open(BlockUserModalComponent);
    modalRef.componentInstance.userId = this.uid;
    modalRef.componentInstance.senderName = senderName;
    modalRef.componentInstance.broadcastID = this.broadcastId;
    modalRef.componentInstance.kickUser.subscribe(($e) => {
      console.log('Kick User', $e.senderName);
      this.kickUser($e.senderName, senderDetails.sender);
    });
    modalRef.componentInstance.muteUser.subscribe(($e) => {
      console.log('Mute User', $e.senderName);
      this.muteUser($e.senderName, senderDetails.sender);
    });
  }

  startRec() {
    this.spinner.show();
    this.broadcastService.startRecording({
      userID : this.uid,
      broadcastID : this.broadcastId
    }).subscribe(
      response => {
        this.startRecTime = new Date();
        this.startRecTime.setMinutes(this.startRecTime.getMinutes()-30);
        localStorage.setItem('rec_start', this.startRecTime.toISOString())

        if (response.cr.sid) {
          this.recStarted = true;
          localStorage.setItem('rdetails', JSON.stringify({
            broadcastID : this.broadcastId,
            sid : response.cr.sid,
          }))
        } else {
          this.recStarted = false;
          localStorage.removeItem('rdetails');
        }
        this.spinner.hide();
      },
      error => {
        this.recStarted = false;
        this.spinner.hide();
      }
    )
  }

  stopRec() {
    this.spinner.show();
    this.broadcastService.stopRecording({
      userID : this.uid,
      broadcastID : this.broadcastId
    }).subscribe(
      response => {
        localStorage.removeItem('rec_start')
        this.recStarted = false;
        localStorage.removeItem('rdetails');
        this.spinner.hide();
      },
      error => {
        this.recStarted = false;
        localStorage.removeItem('rdetails');
        this.spinner.hide();
      }
    )
  }
}
