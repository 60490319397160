import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })

export class ThemesService {

  constructor(private http: HttpClient) { }

  getThemes() {
    return this.http.get<any>(`${environment.apiUrl}app/user/getThemes`);
  }

  updateUserProfileTheme(requestData) {
    return this.http.post<any>(`${environment.apiUrl}app/user/updateUserProfileTheme`, requestData);
  }
}
