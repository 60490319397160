import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService, GlobalService } from '../../_services';
import { NgxSpinnerService } from "ngx-spinner";

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.css']
})
export class OtpComponent implements OnInit {
  mobile :Number
  ccode :string
  otp:String
  returnUrl: string;

  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private globalService: GlobalService,
    private spinner: NgxSpinnerService
  ) { 
      // redirect to home if already logged in
      if (this.authenticationService.currentUserValue) { 
        this.globalService.redirectToHome();
      }

      let requiredData = JSON.parse(localStorage.getItem('verify-otp'));
      if (requiredData) {
        if (!requiredData.verify) {
          this.mobile = requiredData.mobile
          this.ccode = requiredData.country_code
        } else {
          this.router.navigate(['/add-basic-info']);
        }
        
      }else {
        this.router.navigate(['/register']);
      }
  }

  ngOnInit() {
    // get return url from route parameters or default to '/add-basic-info'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/add-basic-info';
  }

  onOtpChange(enteredOtp){
    console.log("enteredOtp", enteredOtp)
    var otpStr = new String(enteredOtp) 
    if (otpStr.length == 4) {
      this.otp = enteredOtp
      console.log("finalOtp", this.otp)
    }else {
      this.otp = null;
      console.log("finalOtp", this.otp)
    }
  }

  onClickVerifyOtp(){
    if (this.otp) {
      if (this.globalService.verifyOtp(this.otp)) {
        this.toastr.success("Otp verified", 'Success!', {
          timeOut: 2000,
          progressBar: true,
          closeButton: true
        });
        let verifyOtpData = localStorage.getItem('verify-otp')
        let verifyOtp = JSON.parse(verifyOtpData)
        verifyOtp.verify = true;
        localStorage.setItem('verify-otp', JSON.stringify(verifyOtp))
        this.router.navigate([this.returnUrl]);
      }else{
        this.toastr.error("Wrong OTP entered", 'Error!', {
          timeOut: 2000,
          progressBar: true,
          closeButton: true
        });
      }
    }else {
      this.toastr.error("Otp must be 4 digit", 'Error!', {
        timeOut: 2000,
        progressBar: true,
        closeButton: true
      });
    }
  }

  resendOtp(){
    const requestData = {
      phoneNo : this.mobile,
      countryCode : '+'+this.ccode,
    }
    this.spinner.show();
    this.globalService.checkUserPhoneNo(requestData).subscribe(
      response => {
        if (!response.error) {
          this.toastr.success(response.message, 'Success!', {
            timeOut: 2000,
            progressBar: true,
            closeButton: true
          });
          this.spinner.hide();
          //Need Fix, After sms integration will change this
          // this.toastr.success("Your OTP :"+response.data.OTP, 'OTP', {
          //   timeOut: 5000,
          //   progressBar: true,
          //   closeButton: true
          // });
          localStorage.setItem('verify-otp',JSON.stringify( 
          {
            country_code:this.ccode,
            mobile:this.mobile,
            otp:response.data.OTP,
            verify:false,
          }))
        } else {

          localStorage.removeItem('verify-otp');

          this.spinner.hide();
          this.toastr.error(response.message, 'Error!', {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
        }
      },
      error => {

      }
    )
  }

}
