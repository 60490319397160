import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home';
import { LoginComponent, MobileLoginComponent } from './login';
import { RegisterComponent,OtpComponent,BasicInfoComponent } from "./register";
import { ProfileComponent, DashboardComponent, EditProfileComponent } from "./profile";
import { ChannelComponent } from "./channel";
import { AuthGuard } from './_helpers';
import { ProfileDetailsComponent } from './profile/profile-details/profile-details.component';
import { UserFansComponent } from './profile/dashboard/dash-link-connections/user-fans/user-fans.component';
import { UserFamilyComponent } from './profile/dashboard/dash-link-connections/user-family/user-family.component';
import { UserFollowersComponent } from './profile/dashboard/dash-link-connections/user-followers/user-followers.component';
import { UserLikeComponent } from './profile/dashboard/dash-link-connections/user-like/user-like.component';

import { UserFriendsComponent } from './profile/dashboard/dash-link-connections/user-friends/user-friends.component';
import { UserSubscribersComponent } from './profile/dashboard/dash-link-connections/user-subscribers/user-subscribers.component';
import { UserGuardiansComponent } from './profile/dashboard/dash-link-connections/user-guardians/user-guardians.component';
import { UserFollowComponent } from './profile/dashboard/dash-link-connections/user-follow/user-follow.component';
import { UserRechargeComponent } from './profile/dashboard/dash-link-finance/user-recharge/user-recharge.component';
import { UserIncomeComponent } from './profile/dashboard/dash-link-finance/user-income/user-income.component';
import { UserTransactionHistoryComponent } from './profile/dashboard/dash-link-finance/user-transaction-history/user-transaction-history.component';
import { UserWalletComponent } from './profile/dashboard/dash-link-finance/user-wallet/user-wallet.component';
import { UserPollsComponent } from './profile/dashboard/dash-link-connections/user-polls/user-polls.component';
import { UserLineupComponent } from './profile/dashboard/dash-link-connections/user-lineup/user-lineup.component';
import { UserContestsComponent } from './profile/dashboard/dash-link-connections/user-contests/user-contests.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { UserSettingsBadgeComponent } from './profile/dashboard/dash-link-settings/user-settings-badge/user-settings-badge.component';
import { UserSettingsBlockedListComponent } from './profile/dashboard/dash-link-settings/user-settings-blocked-list/user-settings-blocked-list.component';
import { UserSettingsBroadcastComponent } from './profile/dashboard/dash-link-settings/user-settings-broadcast/user-settings-broadcast.component';
import { UserSettingsBroadcastAdministratorsComponent } from './profile/dashboard/dash-link-settings/user-settings-broadcast-administrators/user-settings-broadcast-administrators.component';
import { UserSettingsEStoreComponent } from './profile/dashboard/dash-link-settings/user-settings-e-store/user-settings-e-store.component';
import { UserSettingsPersonalizationComponent } from './profile/dashboard/dash-link-settings/user-settings-personalization/user-settings-personalization.component';
import { UserSettingsRecordingsComponent } from './profile/dashboard/dash-link-settings/user-settings-recordings/user-settings-recordings.component';
import { UserSettingsReportsComponent } from './profile/dashboard/dash-link-settings/user-settings-reports/user-settings-reports.component';
import { UserSettingsThemesComponent } from './profile/dashboard/dash-link-settings/user-settings-themes/user-settings-themes.component';
import { ConvertToCoinsComponent } from './profile/dashboard/dash-link-finance/user-income/convert-to-coins/convert-to-coins.component';
import { CashOutComponent } from './profile/dashboard/dash-link-finance/user-income/cash-out/cash-out.component';
import { PollDetailsComponent } from './profile/dashboard/dash-link-connections/user-polls/poll-details/poll-details.component';
import { BadgeDetailsComponent } from './profile/dashboard/dash-link-settings/user-settings-badge/badge-details/badge-details.component';
import { BroadcastDetailsComponent } from './profile/dashboard/dash-link-settings/user-settings-broadcast/broadcast-details/broadcast-details.component';
import { GoLiveComponent } from './go-live/go-live.component';

import { RetrivePasswordComponent } from './forgot-password/retrive-password/retrive-password.component';
import { LiveBrodcastComponent } from './go-live/live-brodcast/live-brodcast.component';
import { MultiLiveBrodcastComponent } from './go-live/multi-live-brodcast/multi-live-brodcast.component';
import { ViewLiveBrodcastComponent } from './go-live/view-live-brodcast/view-live-brodcast.component';
import { GlobalSearchComponent } from './profile/global-search/global-search.component';
import { HeadToHeadComponent } from './go-live/head-to-head/head-to-head.component';
import { DetailSearchComponent } from './profile/global-search/detail-search/detail-search.component';
import { UserlineupComponent } from './profile/about/userlineup/userlineup.component';
import { OTPForgotPasswordComponent } from './forgot-password/otp-forgot-password/otp-forgot-password.component';
import { ChatComponent } from './chat/chat.component';
import { ChatDetailsComponent } from './chat/chat-details/chat-details.component';
import { UserPointComponent } from './profile/dashboard/dash-link-finance/user-point/user-point.component';
import { DiamondsTlogComponent } from './profile/dashboard/dash-link-finance/user-income/diamonds-tlog/diamonds-tlog.component';

import { LeaderboardComponent } from './profile/dashboard/dash-link-connections/leaderboard/leaderboard.component';
import { ViewPollDetailsComponent } from './profile/dashboard/dash-link-connections/user-polls/poll-details/view-poll-details/view-poll-details.component';

import { SocialInfoComponent } from './login/social-info/social-info.component';
import { UserStoreCreditRechargeComponent } from './profile/dashboard/dash-link-finance/user-store-credit-recharge/user-store-credit-recharge.component';
import { LiveGuidesComponent } from './channel/live-guides/live-guides.component';
import { BankdetailsComponent } from './profile/dashboard/dash-link-finance/bankdetails/bankdetails.component';

const routes: Routes = [
    { path: '', component: ChannelComponent, canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },
    { path: 'login-mobile', component: MobileLoginComponent },
    { path: 'bankdetails', component: BankdetailsComponent, canActivate: [AuthGuard] },
    { path: 'channels', component: ChannelComponent, canActivate: [AuthGuard] },
    { path: 'channels/:id/:slug', component: ChannelComponent, canActivate: [AuthGuard] },
    { path: 'go-live', component: GoLiveComponent, canActivate: [AuthGuard] },
    { path: 'go-live/head-to-head/:btype/:userID/:broadcastPhysicalChannel/:broadcastId/:audioMode', component: HeadToHeadComponent, canActivate: [AuthGuard] },
    { path: 'go-live/broadcast/:btype/:userID/:broadcastPhysicalChannel/:broadcastId/:audioMode', component: LiveBrodcastComponent, canActivate: [AuthGuard] },
    { path: 'go-live/multi-broadcast/:btype/:userID/:broadcastPhysicalChannel/:broadcastId/:audioMode/:maxAllowedSlots', component: MultiLiveBrodcastComponent, canActivate: [AuthGuard] },
    { path: 'view-broadcast/:id', component: ViewLiveBrodcastComponent, canActivate: [AuthGuard] },
    { path: 'register', component: RegisterComponent },
    { path: 'verify-otp', component: OtpComponent },
    { path: 'add-basic-info', component: BasicInfoComponent },
    { path: 'user-profile', component: ProfileComponent, canActivate: [AuthGuard] },
    { path: 'user-dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
    { path: 'profile/:id', component: ProfileDetailsComponent },
    { path: 'edit-profile', component: EditProfileComponent, canActivate: [AuthGuard] },
    
    { path: 'chat', component: ChatComponent, canActivate: [AuthGuard] },
    { path: 'chat-details/:id', component: ChatDetailsComponent, canActivate: [AuthGuard] },
    
    { path: 'my-fans', component: UserFansComponent, canActivate: [AuthGuard] },
    { path: 'my-family', component: UserFamilyComponent, canActivate: [AuthGuard] },
    { path: 'my-followers', component: UserFollowersComponent, canActivate: [AuthGuard] },
    { path: 'my-like', component: UserLikeComponent, canActivate: [AuthGuard] },
    
    { path: 'my-friends', component: UserFriendsComponent, canActivate: [AuthGuard] },
    { path: 'my-subscribers', component: UserSubscribersComponent, canActivate: [AuthGuard] },
    { path: 'my-guardians', component: UserGuardiansComponent, canActivate: [AuthGuard] },
    { path: 'i-follow', component: UserFollowComponent, canActivate: [AuthGuard] },
    { path: 'my-polls', component: UserPollsComponent, canActivate: [AuthGuard] },
    { path: 'poll-details', component: PollDetailsComponent, canActivate: [AuthGuard] },
    { path: 'my-line-up', component: UserLineupComponent, canActivate: [AuthGuard] },
    { path: 'my-contests', component: UserContestsComponent, canActivate: [AuthGuard] },
    
    { path: 'recharge', component: UserRechargeComponent, canActivate: [AuthGuard] },
    { path: 'store-credit-recharge', component: UserStoreCreditRechargeComponent, canActivate: [AuthGuard] },
    { path: 'income', component: UserIncomeComponent, canActivate: [AuthGuard] },
    { path: 'income/convert-to-coins', component: ConvertToCoinsComponent, canActivate: [AuthGuard] },
    { path: 'income/cash-out', component: CashOutComponent, canActivate: [AuthGuard] },
    { path: 'income/diamonds-tlog', component: DiamondsTlogComponent, canActivate: [AuthGuard] },
    { path: 'transaction-history', component: UserTransactionHistoryComponent, canActivate: [AuthGuard] },
    { path: 'wallet', component: UserWalletComponent, canActivate: [AuthGuard] },
    { path: 'point', component: UserPointComponent, canActivate: [AuthGuard] },
    
    { path: 'badge', component: UserSettingsBadgeComponent, canActivate: [AuthGuard] },
    { path: 'badge-details', component: BadgeDetailsComponent, canActivate: [AuthGuard] },
    { path: 'recordings', component: UserSettingsRecordingsComponent, canActivate: [AuthGuard] },
    { path: 'blocked-list', component: UserSettingsBlockedListComponent, canActivate: [AuthGuard] },
    { path: 'broadcastd-data-details', component: UserSettingsBroadcastComponent, canActivate: [AuthGuard] },
    { path: 'broadcastd-details/:id', component: BroadcastDetailsComponent, canActivate: [AuthGuard] },
    { path: 'personalization', component: UserSettingsPersonalizationComponent, canActivate: [AuthGuard] },
    { path: 'themes', component: UserSettingsThemesComponent, canActivate: [AuthGuard] },
    { path: 'estore', component: UserSettingsEStoreComponent, canActivate: [AuthGuard] },
    { path: 'reports', component: UserSettingsReportsComponent, canActivate: [AuthGuard] },
    { path: 'broadcast-administrators', component: UserSettingsBroadcastAdministratorsComponent, canActivate: [AuthGuard] },

    { path: 'forgot-password', component: ForgotPasswordComponent },
    { path: 'otp-forgot-password', component: OTPForgotPasswordComponent },
    { path: 'recoverpassword/:token', component: RetrivePasswordComponent },
    { path: 'searchResult/:name', component: GlobalSearchComponent, canActivate: [AuthGuard]  },
    { path: 'detailResult/:type/:name', component: DetailSearchComponent, canActivate: [AuthGuard]  },
    { path: 'userlineup/:id', component: UserlineupComponent },
    { path: 'leaderboard', component: LeaderboardComponent },
    { path: 'view-poll-details', component: ViewPollDetailsComponent },
    { path: 'social-info', component: SocialInfoComponent },
    { path: 'channels/liveGuides', component: LiveGuidesComponent },
    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const appRoutingModule = RouterModule.forRoot(routes);