import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from './_services';
import { User } from './_models';
import { NgxSpinnerService } from "ngx-spinner";
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { H2hrequestComponent } from './_modal/h2hrequest/h2hrequest.component';
import { BroadcastService } from './_services/broadcast.service';

import { MessagingService } from './service/messaging.service';
import { interval } from 'rxjs/internal/observable/interval';

declare var $: any;
declare const Pusher: any;

@Component({ selector: 'app', templateUrl: 'app.component.html' })
export class AppComponent {
    currentUser: User;
    modalOptions: NgbModalOptions = {
        centered: true
    };
    user: any;
    userId: number;
    message;
  
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private spinner: NgxSpinnerService,
        private modalService: NgbModal,
        private broadcastService: BroadcastService,
        private messagingService: MessagingService
    ) {
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
        if (localStorage.getItem('theme')) {
          
        } else {
           localStorage.setItem('theme', '1')
        }
    }
    ngOnInit() {
        /** spinner starts on init */
        this.spinner.show();
     
        setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.spinner.hide();
        }, 1000);

        this.getLocation()
        interval(5000).subscribe(x => {
          if (!localStorage.getItem("requestModalOpen")) {
            console.log('requestModalOpen', localStorage.getItem("requestModalOpen"));
            this.h2hrequest()
          }
        })
        
        this.messagingService.requestPermission()
        this.messagingService.receiveMessage()
        this.message = this.messagingService.currentMessage
        this.updateFirebaseKeyForNotification();
    }
    updateFirebaseKeyForNotification(){
      if (Notification.permission == "granted")
    
      {
            console.log('allow')
      }
      else{
        console.log('not allow');
      }
    }
    h2hrequest(){
      console.log("Check H2H request")
      localStorage.setItem("requestModalOpen", 'yes')
      this.user = JSON.parse(localStorage.getItem('currentUser'))
      if(this.user){
        this.userId = parseInt(this.user.profile.FKuserID, 10);

        this.broadcastService.getActiveBroadcasterListForHeadToHead({
          userID: this.userId,
        }).subscribe(
          response => {
            console.log("H2H", response)
            this.broadcastService.getActiveGuestAppearanceRequest({userID: this.userId,}).subscribe(
              responseRequest => {
                console.log("Guest", response)
                this.broadcastService.getActiveMulticastRequest({
                  userID: this.userId,
                }).subscribe(
                  responseMulticast => {
                    console.log("Multicast Request", responseMulticast);
                    // tslint:disable-next-line: max-line-length
                    if ((!response.error && response.data.headToHeadRequest.length > 0) || (!responseRequest.error && responseRequest.data.headToHeadRequest.length > 0) || (!responseMulticast.error && responseMulticast.data.multicastReq.length > 0)) {
                      
                      const modalRef = this.modalService.open(H2hrequestComponent, this.modalOptions);
                      
                      modalRef.result.then(() => { localStorage.removeItem('requestModalOpen') }, () => { localStorage.removeItem('requestModalOpen')})
                      modalRef.componentInstance.headToHeadRequest = response.data.headToHeadRequest;
                      modalRef.componentInstance.guestRequest = responseRequest.data.headToHeadRequest;
                      modalRef.componentInstance.multiCastRequest = responseMulticast.data.multicastReq;
                      modalRef.componentInstance.currentUserId = this.userId;
                    } else {
                      localStorage.removeItem('requestModalOpen')
                    }
                  },
                  error=>{
        
                  }
                )
              }
            )
          },
          error => {
    
          }
        )
      }

    }

    getLocation(): void{
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position)=>{
              const longitude = position.coords.longitude;
              const latitude = position.coords.latitude;
              localStorage.setItem('geolocation', JSON.stringify({'latitude' : latitude, 'longitude' : longitude}));
              //console.log(longitude , latitude)
            });
        } else {
           localStorage.removeItem('geolocation');
           console.log("No support for geolocation")
        }
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }
}