import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService, GlobalService, UploadFileService, UserService } from '../../../../_services';
import { NgxSpinnerService } from "ngx-spinner";

import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';
import { RechargeService } from 'src/app/_services/recharge.service';
import * as moment from 'moment';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-user-transaction-history',
  templateUrl: './user-transaction-history.component.html',
  styleUrls: ['./user-transaction-history.component.css']
})
export class UserTransactionHistoryComponent implements OnInit {
  isDashboardMode = true
  moment: any = moment;
  title = 'Transaction History'
  color = 'transaction_headerbg'
  tLog: any;
  coinstLog: any;
  diamondstLog: any;
  cashouttLog: any;
  modalRef: BsModalRef;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private modalService: BsModalService,

    private renderer: Renderer2,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private globalService: GlobalService,
    private userService: UserService,
    private rechargeService: RechargeService,

    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.getTransactionLog();
  }
  openModalc(templateccc: any){
    
    this.modalRef = this.modalService.show(templateccc);
  }
  getTransactionLog(){
    this.spinner.show();
    let currentUser: any = this.authenticationService.currentUserValue;

    // const requestData = {
    //   userID: currentUser.profile.FKuserID,
    //   type: "coins / points / diamonds / gift / cashout",
    // }

    this.rechargeService.getTransactionLog({
      userID: currentUser.profile.FKuserID,
      type: "coins",
    }).subscribe(
      response => {
        this.coinstLog = response.data
        //this.spinner.hide();
      },
      error => {

      }
    )
    this.rechargeService.getTransactionLog({
      userID: currentUser.profile.FKuserID,
      type: "diamonds",
    }).subscribe(
      response => {
        this.diamondstLog = response.data
        //this.spinner.hide();
      },
      error => {

      }
    )
    this.rechargeService.getTransactionLog({
      userID: currentUser.profile.FKuserID,
      type: "cashout",
    }).subscribe(
      response => {
        this.cashouttLog = response.data
        this.spinner.hide();
      },
      error => {

      }
    )
  }

}
