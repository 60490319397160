import { Component, OnInit, Input } from '@angular/core';
import { BroadcastService } from 'src/app/_services/broadcast.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';

@Component({
  selector: 'app-channel-dashboard',
  templateUrl: './channel-dashboard.component.html',
  styleUrls: ['./channel-dashboard.component.css']
})
export class ChannelDashboardComponent implements OnInit {

  _channelId: any
  _channelSlug: any
  searchCode:any
  broadcasts = []
  limit = 8
  start = 0
  user: any;
  userId: any;
  lat:any;
  lng:any;
  advertiseData = [];
  //router.navigate(['/searchResult',search.value])
  @Input('channelId')
  set channelId(value) {
    this._channelId = value
    this.fetchBroadcast(this._channelId)
    //console.log('In set data channelId', this._channelId)
  }
  get channelId() {
    return this._channelId
  }
  @Input('channelSlug')
  set channelSlug(value) {
    this._channelSlug = value
    this.limit = 8;
    this.notscrolly = true;
    this.notEmpty = true;
  }
  get channelSlug() {
    return this._channelSlug
  }
  notscrolly : boolean = true;
  notEmpty : boolean = true;
  totalData : number = 0;

  constructor(
    private broadcast : BroadcastService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    

  ) { }
  
  ngOnInit() {    
    console.log("ChannelDashboard", this.channelId)
  }
  searchSubmit(){
    if(this.searchCode){
    this.router.navigate(['/searchResult',this.searchCode]);
    }
  }
  
  fetchBroadcast(channel){
    if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((res: Position) => {
				this.lat = res.coords.latitude;
        this.lng = res.coords.longitude;
        
        this.user = JSON.parse(localStorage.getItem('currentUser'))
        this.userId = this.user.profile.FKuserID
        var request = {
          'userID' : this.userId,
          'channelID' : channel ? channel : 12,
          'lat' : this.lat,
          'lng' : this.lng,
          'todaysdate' :  moment().format('YYYY-MM-DD'),      
          'limit' : this.limit,
          'start' : this.start,
         
        }
        this.spinner.show()
        this.broadcast.getAllBroadcastByChannels(request).subscribe(
          response => {
             if (response.error) {
               this.spinner.hide()
               return;
             }
             this.advertiseData = response.advertisement.data;
             this.broadcasts = response.data;
             this.totalData = response.total;
             console.log(">>>>",this.broadcasts);
             this.spinner.hide()
          },
          error => {
            this.spinner.hide()
          }
        )
		
			});
		}




  }

  onScroll() {
    if (this.notscrolly && this.notEmpty) {
      this.spinner.show();      
      this.notscrolly = false;
      this.loadNextData();
   }
  }

  loadNextData() {
    if(this.totalData >  this.limit) {
      this.limit += 8;   
      this.fetchBroadcast(this._channelId);
    } else {      
      this.notEmpty =  false;
      this.spinner.hide(); 
    }   
    this.notscrolly = true;
  }

}
