import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  @Input('isAuth') isAuth: any;
  @Input('isDashboardMode') isDashboardMode: any;
  @Input('title') title: any;
  @Input('color') color: any;
  @Input('hasFilter') hasFilter: any;
  @Output() routeChange = new EventEmitter(); 
  theme: string;
  
  constructor( 
    private _location: Location
  ) {
  }
  ngAfterViewInit() {

  }

  ngOnInit() {
    switch (localStorage.getItem('theme')) {
      case '1':
        this.theme = ''
        break;
      case '2':
        this.theme = '-green'
        break;
      case '3':
        this.theme = '-orange'
        break;
    
      default:
        break;
    }
    if (this.isAuth == undefined) {
      this.isAuth = true;
    }
    if (this.isDashboardMode == undefined) {
      this.isDashboardMode = false;
    }
    if (this.hasFilter == undefined) {
      this.hasFilter = false;
    }
    if (this.color == undefined) {
      this.color = 'my_dashboardbg';
    }
  }

  backClicked() {
    this.routeChange.emit();
    this._location.back();
  }

}
