import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { ChannelService } from '../_services';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { ChatService } from '../_services/chat.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {

  section = 'all';
  user: any;
  userId: any;
  userList = [];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private chatService: ChatService
  ) { }

  ngOnInit() {
    this.getUserFriendFamilyFollowerDetail();
  }

  changeSection(type){
    this.section = type;
    switch (type) {
      case 'all':
        this.getUserFriendFamilyFollowerDetail();
        break;
      case 'ifan':
        this.getUserIFanDetail();
        break;
      case 'fan':
        this.getUserFanDetail();
        break;
      case 'friend':
        this.getUserFriendDetail();
        break;
      case 'family':
        this.getUserFamilyDetail();
        break;
      case 'unknown':
        this.getUserUnknownDetail();
        break;
    
      default:
        this.getUserFriendFamilyFollowerDetail();
        break;
    }
  }
  getUserFamilyDetail(){
    this.user = JSON.parse(localStorage.getItem('currentUser'))
    this.userId = this.user.profile.FKuserID
    this.spinner.show();
    this.chatService.getUserFamilyDetail({
      userID : this.userId
    }).subscribe(
      response => {
        if (!response.error) {
          this.userList = response.data
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    )
  }

  getUserUnknownDetail(){
    this.userList = [];
  }

  getUserFriendDetail(){
    this.user = JSON.parse(localStorage.getItem('currentUser'))
    this.userId = this.user.profile.FKuserID
    this.spinner.show();
    this.chatService.getUserFriendDetail({
      userID : this.userId
    }).subscribe(
      response => {
        if (!response.error) {
          this.userList = response.data
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    )
  }

  getUserFanDetail(){
    this.user = JSON.parse(localStorage.getItem('currentUser'))
    this.userId = this.user.profile.FKuserID
    this.spinner.show();
    this.chatService.getUserFanDetail({
      userID : this.userId
    }).subscribe(
      response => {
        if (!response.error) {
          this.userList = response.data
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    )
  }

  getUserIFanDetail(){
    this.user = JSON.parse(localStorage.getItem('currentUser'))
    this.userId = this.user.profile.FKuserID
    this.spinner.show();
    this.chatService.getUserIFanDetail({
      userID : this.userId
    }).subscribe(
      response => {
        if (!response.error) {
          this.userList = response.data
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    )
  }

  getUserFriendFamilyFollowerDetail(){
    this.user = JSON.parse(localStorage.getItem('currentUser'))
    this.userId = this.user.profile.FKuserID
    this.spinner.show();
    this.chatService.getUserFriendFamilyFollowerDetail({
      fromUserID : this.userId,
      start : 0,
      limit : 100
    }).subscribe(
      response => {
        if (!response.error) {
          this.userList = response.data
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    )
  }

  goToChatDetails(chatData){
    
    if (chatData.ChatRoomDetails[0]) {
      if (chatData.ChatRoomDetails[0].chatRoomSettings.isEnableChat == '1') {
        this.router.navigate(['/chat-details/'+chatData.FKuserID]);
      }
    } else {
      this.router.navigate(['/chat-details/'+chatData.FKuserID]);
    }
  }

}
