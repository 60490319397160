import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BroadcastService } from 'src/app/_services/broadcast.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'fans-friends-family-follower-modal.component',
  templateUrl: './fans-friends-family-follower-modal.component.html',
  styleUrls: ['./fans-friends-family-follower-modal.component.scss']
})
export class FansFriendsFamilyFollowerModalComponent implements OnInit {
  @Input() userId;
  @Input() broadcastID;
  
  userList:any = []
  constructor(
    public activeModal: NgbActiveModal,
    public broadcastService: BroadcastService,
    public spinner: NgxSpinnerService,
    public toastr: ToastrService,
  ) {
    this.spinner.show()
   }

  ngOnInit() {
    this.getUserFriendFamilyFollowerDetail()
  }

  getUserFriendFamilyFollowerDetail(){

    this.broadcastService.getUserFriendFamilyFollowerDetail({
      userID: this.userId,
    }).subscribe(
      response => {
        if (!response.error) {
          this.userList = response.data
        }
        this.spinner.hide()
      },
      error => {

      }
    )
  }

  invite(toUserID){
    this.spinner.show();
    this.broadcastService.inviteUserForHeadToHead({
      userID : this.userId,
      broadcastID : this.broadcastID,
      toUserID : toUserID
    }).subscribe(
      response => {
        if (response.error) {
          //Error
          this.spinner.hide();
          return  this.toastr.error(response.message, 'Error!', {
            timeOut: 2000,
          });
        } else {
          //Success
          this.spinner.hide();
          return  this.toastr.success("Invitation Sent", 'Success!', {
            timeOut: 2000,
          });
        }
        this.spinner.hide();
      },
      error => {

      }
    )
  }

}
