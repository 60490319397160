import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/_services/authentication.service';
@Component({
  selector: 'app-block-user-modal',
  templateUrl: './block-user-modal.component.html',
  styleUrls: ['./block-user-modal.component.scss']
})
export class BlockUserModalComponent implements OnInit {
  @Input() userId;
  @Input() broadcastID;
  @Input() senderName;
  @Output() muteUser = new EventEmitter();
  @Output() kickUser = new EventEmitter();
  constructor(
    public activeModal: NgbActiveModal,
    public spinner: NgxSpinnerService,
    public toastr: ToastrService,
    private authenticationService: AuthenticationService,
  ) { }

  ngOnInit() {
  }
  kickOut() {
    this.kickUser.emit({senderName : this.senderName});
  }
  mute() {
    this.muteUser.emit({senderName : this.senderName});
  }
}
