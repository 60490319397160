import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { BroadcastService } from 'src/app/_services/broadcast.service';

@Component({
  selector: 'app-buy-store-credit',
  templateUrl: './buy-store-credit.component.html',
  styleUrls: ['./buy-store-credit.component.scss']
})
export class BuyStoreCreditComponent implements OnInit {
  @Input() videoId;
  @Input() msg;
  @Input() credit;
  @Output() coinPurchased = new EventEmitter();
  constructor(
    public activeModal: NgbActiveModal,
    public spinner: NgxSpinnerService,
    public toastr: ToastrService,
    private authenticationService: AuthenticationService,
    private broadcastService: BroadcastService,
  ) { }

  ngOnInit() {
  }

  buyCredit(){
    this.spinner.show();
    this.broadcastService.buyStoreCredit({
      userID: 15,
      storeCredit: this.credit
    }).subscribe(
      response => {
        this.spinner.hide();
        this.activeModal.close('Close click')
        this.coinPurchased.emit(response);
      },
      error => {

      }
    )

  }

}
