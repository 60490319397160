import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';


@Component({
  selector: 'app-poll-details',
  templateUrl: './poll-details.component.html',
  styleUrls: ['./poll-details.component.css']
})
export class PollDetailsComponent implements OnInit {
  moment: any = moment;

  isDashboardMode = true
  title = 'My Polls'
  color = 'my-pool-head-bg'
  poll:any
  constructor() { }

  ngOnInit() {
    this.poll = JSON.parse(localStorage.getItem('poll'))
    console.log(this.poll);
  }

}
