import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    getUserDetails(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/getUserDetails`, requestData);
    }
    shareProfile(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/shareProfile`, requestData);
    }
    purchaseCoinsWeb(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/purchaseCoinsWeb`, requestData);
    }
    shareBroadcast(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/broadcast/shareBroadcast`, requestData);
    }
    updateUserdata(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/updateUserData`, requestData);
    }

    updateUserImageS3(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/singleUploadToS3`, requestData);
    }

    updateUserAvtarRuntime(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/updateUserAvtarRuntieWeb`, requestData);
    }
    addBroadcastAdministrator(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/addBroadcastAdministrator`, requestData);
    }
    getAllMyAdministrator(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/getAllMyAdministrator`, requestData);
    }


    userSaveImage(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/saveuserProfilePic`, requestData);
    }

    getchannel() {
        return this.http.get<any>(`${environment.apiUrl}app/channel/getChannels?id=0&groupbyType=0&channelForBroadcast=1`);
    }

    followUser(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/followProfile`, requestData);
    }

    unFollowUser(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/unfollowProfile`, requestData);
    }
    removeMyfollower(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/removeMyfollower`, requestData);
    }
    removeFan(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/removeFan`, requestData);
    }
    unFriend(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/unFriend`, requestData);
    }

    getUserFollowingDetail(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/getUserFollowingDetail`, requestData);
    }

    getUserFollowerDetail(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/getUserFollowerDetail`, requestData);
    }

    getUserLikeDetail(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/getUserLikeDetail`, requestData);
    }

    getUserFanDetail(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/getUserFanDetail`, requestData);
    }

    getUserFriendDetail(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/getUserFriendDetail`, requestData);
    }

    likeUser(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/likeProfile`, requestData);
    }

    unLikeUser(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/unlikeProfile`, requestData);
    }


    getAllBlockedUser(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/getAllBlockedUser`, requestData);
    }

    unBlockUser(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/blockAUser`, requestData);
    }
    blockUser(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/blockAUser`, requestData);
    }

    globalSearch(requestData) {
        return this.http.get<any>(`${environment.apiUrl}app/common/globalSearch`, { params: requestData });
    }
    getBroadcastLineupType() {
        return this.http.get<any>(`${environment.apiUrl}app/common/getBroadcastLineupType`);
    }
    addBroadcastLineup(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/broadcastlineup/addBroadcastLineup`, requestData);
    }
    getHashtags(requestData) {
        return this.http.get<any>(`${environment.apiUrl}app/hashtag/getHashtags`, { params: requestData });
    }

    getBroadcastLineupByUserByType(requestData) {
        return this.http.get<any>(`${environment.apiUrl}app/broadcastlineup/getBroadcastLineupByUserByType`, { params: requestData });
    }
    deleteBroadcastLineup(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/broadcastlineup/deleteBroadcastLineup`, requestData);
    }
    cancelBroadcastLineup(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/broadcastlineup/cancelBroadcastLineup`, requestData);
    }

    sendOTPforUserForgetPassword(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/sendOTPforUserForgetPassword`, requestData);
    }

    forgotPassword(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/forgotPassword`, requestData);
    }
    checkPasswordRecoveryToken(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/checkPasswordRecoveryToken`, requestData);
    }

    updateUserPasswordRecovery(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/updateUserPasswordRecovery`, requestData);
    }

    getInterests() {
        return this.http.get<any>(`${environment.apiUrl}app/interest/getInterests`);
    }
    addInteresttoBroadcastLineupByUser(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/broadcastlineup/addInteresttoBroadcastLineupByUser`, requestData);
    }
    getAllBadgesForUser(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/getAllBadgesForUser`, requestData);
    }
    activeBadgeByUser(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/activeBadgeByUser`, requestData);
    }
    getbroadcastlineupTimeSlots(requestData) {
        return this.http.get<any>(`${environment.apiUrl}app/broadcastlineuptimeslots/getbroadcastlineupTimeSlots`, { params: requestData });
    }

    purchaseBadge(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/badge/purchaseBadge`, requestData);
    }


    getBroadcustDetailsData(requestData) {
        return this.http.get<any>(`${environment.apiUrl}app/broadcast/getAllBroadcastByUsers`, { params: requestData });
    }
    getBroadcustDetailsStatesData(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/broadcast/getBroadcastDetail`, requestData);
    }
    changeUserEmail(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/changeUserEmail`, requestData);
    }
    sendOTPtochangeUserEmail(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/sendOTPtochangeUserEmail`, requestData);
    }
    saveBankAccount(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/saveBankAccount`, requestData);
    }

    sendOTPtochangeUserPhone(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/sendOTPtochangeUserPhoneNo`, requestData);
    }

    changeuserPhone(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/changeUserPhoneNo`, requestData);
    }

    getAllVideosForUser(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/getAllVideosForUser`, requestData);
    }

    getAllAdminData() {
        return this.http.get<any>(`${environment.apiUrl}admin/settings/getMasterSetting`);
    }

    getLeaderboardDetailsData(requestData) {
        return this.http.get<any>(`${environment.apiUrl}app/common/getLeaderboadrd`, { params: requestData });
    }

    getUserSavedBankAccount(requestData) {
        return this.http.get<any>(`${environment.apiUrl}app/user/getUserSavedBankAccount`, { params: requestData });
    }


    getAllTimezone() {
        return this.http.get<any>(`${environment.apiUrl}common/settings/getCommonSettings`);
    }
    getLiveGuidesData(requestData) {
        return this.http.get<any>(`${environment.apiUrl}app/broadcast/getAllBroadcastByChannels`, { params: requestData });
    }

    getUserFamilyDetail(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/getUserFanDetail`, requestData);
    }

    removeFamily(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/removeFan`, requestData);
    }

    addFeaturedReq(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/addFeaturedReq`, requestData);
    }

}