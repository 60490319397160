import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { PollsService } from 'src/app/_services/polls.service';

@Component({
  selector: 'app-assign-polls-modal',
  templateUrl: './assign-polls-modal.component.html',
  styleUrls: ['./assign-polls-modal.component.scss']
})
export class AssignPollsModalComponent implements OnInit {
  @Input() userId;
  @Input() broadcastID;
  @Output() pollAssigned = new EventEmitter();
  polls = [];

  constructor(
    public activeModal: NgbActiveModal,
    public spinner: NgxSpinnerService,
    public toastr: ToastrService,
    public pollsService: PollsService,
    private authenticationService: AuthenticationService,
  ) { }

  ngOnInit() {
    this.getPolls()
  }
  assign(poll){
   this.pollsService.addPolsWithBroadcast({
    id : poll,
    broadcastID : this.broadcastID
   }).subscribe(
     response =>{
      this.pollAssigned.emit(response.data);
      this.getPolls();
     },
     error => {

     }
   )
  }
  getPolls(){
    this.spinner.show();
    let currentUser: any = this.authenticationService.currentUserValue;
    
    this.pollsService.getAllActivePollsByUserToAssign({
      userID : currentUser.profile.FKuserID,
      broadcastID : this.broadcastID
    }).subscribe(
      response => {
        this.spinner.hide();
        this.polls = response.data;
      },
      error => {
 
      }
    )
   }
}
