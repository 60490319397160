import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService, GlobalService, UploadFileService, UserService } from '../../../../_services';
import { NgxSpinnerService } from "ngx-spinner";

import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';
import { RechargeService } from 'src/app/_services/recharge.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StripeComponent } from 'src/app/_modal/stripe/stripe.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-store-credit-recharge',
  templateUrl: './user-store-credit-recharge.component.html',
  styleUrls: ['./user-store-credit-recharge.component.scss']
})
export class UserStoreCreditRechargeComponent implements OnInit {
  isDashboardMode = true
  title = 'Store Credit Recharge'
  color = 'recharge_headerbg'
  coins: any;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private globalService: GlobalService,
    private userService: UserService,
    private rechargeService: RechargeService,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService
  ) { }

  rechargeOptions = [];

  ngOnInit() {
    this.getDollerPurchaseOptons()
    this.userStat()
  }

  userStat(){
    this.spinner.show();
    let currentUser: any = this.authenticationService.currentUserValue;

    const requestData = {
      userID: currentUser.profile.FKuserID,
    }
    this.rechargeService.getUserStat(requestData).subscribe(
      response => {
        this.coins = response.data.totalStoreCredit
        this.spinner.hide();
      },
      error => {

      }
    )
  }

  getDollerPurchaseOptons(){
     this.rechargeService.getStoragePurchaseOptons().subscribe(
       response => {
         this.rechargeOptions = response.data;
       },
       error=>{}
     )
  }

  openCardModal(amount){
    let currentUser: any = this.authenticationService.currentUserValue;
    const modalRef = this.modalService.open(StripeComponent);
    modalRef.componentInstance.userId = currentUser.profile.FKuserID;
    modalRef.componentInstance.amount = amount;
    modalRef.componentInstance.cardToken.subscribe(($e) => {
      console.log('Purchase Coin', $e);
      this.spinner.show();
      this.userService.purchaseCoinsWeb($e).subscribe(
        response => {
          console.log('Purchase Coin Response', response);
          this.modalService.dismissAll(StripeComponent)
          this.spinner.hide();
          if (!response.error) {
            this.userStat()
            this.toastr.success(response.message, 'Success!', {
              timeOut: 5000,
              progressBar: true,
              closeButton: true
            });
          } else {
            this.toastr.error(response.message, 'Error!', {
              timeOut: 5000,
              progressBar: true,
              closeButton: true
            });
          }
        },
        error => {
          this.spinner.hide();
        }
      )
    });

  }

  purchaseCoins(amount){


    Swal.fire({
      title: '',
      text: 'Do you want to buy Storage Credits?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
    


        let currentUser: any = this.authenticationService.currentUserValue;

        const requestData = {
          userID: currentUser.profile.FKuserID,
          storeCredit: amount,
        }
        this.spinner.show();
        this.rechargeService.buyStoreCredit(requestData).subscribe(
          response => {
            if (!response.error) {
              this.userStat()
              this.toastr.success(response.message, 'Success!', {
                timeOut: 5000,
                progressBar: true,
                closeButton: true
              });
            } else {
              this.toastr.error(response.message, 'Error!', {
                timeOut: 5000,
                progressBar: true,
                closeButton: true
              });
            }
            this.spinner.hide();
          },
          error => {
    
          }
        )









      }
      else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          '',
          'error'
        )
      }
    })


  }
}
