import { Directive } from '@angular/core';

@Directive({
  selector: '[ngEnter]'
})
export class NgEnterDirective {

  constructor() { }

}
