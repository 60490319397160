import { Component, OnInit, Renderer2, Inject, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService, GlobalService, UploadFileService, UserService } from '../../_services';
import { NgxSpinnerService } from "ngx-spinner";

import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';
import { ValidateEmailNotTaken, ValidateUsernameNotTaken } from 'src/app/validators';
import * as moment from 'moment';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {
  addLineUp: FormGroup;
  countries = JSON.parse(localStorage.getItem('countries'));
  defaultCode = '1&United States of America';

  submitted = false
  editInfoForm: FormGroup;
  addOTPEmail: FormGroup;
  addPhoneUp : FormGroup;
  addOTPPhone :  FormGroup;
  isShow = false;
  isChannelsShow = false;
  isdefault: any = 0;
  storeIsDefault: any = [];
  HashTags: any = [];
  Channels: any = [];
  getCountry: any;
  getUserChannel: any = [];
  maxDate: any;
  isInterestShow = false;
  getUserInterest: any = [];
  Interest: any = Array();
  isImageChange = false;
  isDefaultChange = false;
  modalRef: BsModalRef;
  emailOTP: any = '';
  phoneOTP:any = '';

  toggleDisplay() {
    this.isShow = !this.isShow;
  }
  toggleChannelsDisplay() {
    this.isChannelsShow = !this.isChannelsShow;

  }

  toggleInterestDisplay() {
    this.isInterestShow = !this.isInterestShow;

  }
  selectCountry(ipData:any) {
    this.countries.forEach(country => {
      if (country.code === ipData.country) {
        this.defaultCode = country.dialCode+"&"+country.name
      }
    });
  }
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private modalService: BsModalService,
    private renderer: Renderer2,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private globalService: GlobalService,
    private userService: UserService,

    private spinner: NgxSpinnerService
  ) {
    // if (this.authenticationService.currentUserValue) {
    //   this.router.navigate(['/']);
    // }

  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    this.submitted = false;


  }

  openModalPhone(templatePhone: TemplateRef<any>) {
    this.modalRef = this.modalService.show(templatePhone);
    this.submitted = false;


  }



  saveChannels() {
    let val = (document.getElementById('inputChannels') as HTMLInputElement).value;
    let getUserChannel = Object.assign([], this.getUserChannel);
    let name = getUserChannel.filter(a => a.id == val);
    console.log('getUserChannel', this.getUserChannel, val, name);
    if (val) {
      if (this.Channels.length + 1 <= '3') {

        this.Channels.push({
          id: name[0].id,
          name: name[0].name
        });
        this.toggleDisplay();
        (document.getElementById('inputChannels') as HTMLInputElement).value = '';
        this.isChannelsShow = false;
      }
      else {

        this.toastr.error("You can add only 3 items!", 'Error!', {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
      }
    }

  }
  saveInterest() {
    let val = (document.getElementById('interest') as HTMLInputElement).value;

    let getUserInterest = Object.assign([], this.getUserInterest);
    let name = getUserInterest.filter(a => a.id == val);

    if (val) {
      if (this.Interest.length + 1 <= '3') {
        console.log("newss>>", this.Interest.length, this.Interest);
        // this.Interest = 
        this.Interest.push({
          id: name[0].id,
          name: name[0].name
        });

        console.log("newss>>", this.Interest.length, this.Interest);

        //  this.Interest = [...,{
        //   id: name[0].id,
        //   name: name[0].name
        // }]

        (document.getElementById('interest') as HTMLInputElement).value = '';
        this.isInterestShow = false;
      }
      else {

        this.toastr.error("You can add only 3 items!", 'Error!', {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
      }
    }
  }
  selected(a) {
    this.isdefault = '';
    this.isdefault = a;
    this.isImageChange = true;
    this.isDefaultChange = true;

  }


  saveHashtag() {
    let val = (document.getElementById('inputHastag') as HTMLInputElement).value;
    if (val) {
      if (this.HashTags.length + 1 <= '10') {

        this.HashTags.push(val);
        this.toggleDisplay();
        (document.getElementById('inputHastag') as HTMLInputElement).value = '';
      }
      else {

        this.toastr.error("You can add only 10 items!", 'Error!', {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
      }
    }

  }
  removeHashTags(e) {
    let rArray = this.HashTags;
    rArray.splice(e, 1);
    console.log(">>>-----", rArray);
  }
  removeChannels(e) {
    let cArray = this.Channels;
    cArray.splice(e, 1);

  }
  removeInterest(e) {
    let newArray = this.Interest;
    newArray.splice(e, 1);
  }
  clickImage() {
    document.getElementById('getImage').click();
  }

  imageArray: any = [];
  onFlyImageArray: any = [];
  addImageArray(event) {
    this.isImageChange = true;
    console.log("xyz>>>", this.imageArray.length + event.target.files.length);

    console.log("<uysttt>>>", event.target.files[0].size / 1024 / 1024);
    if (this.imageArray.length + event.target.files.length <= 8) {
      this.spinner.show();
      //   this.imageArray.push(event.target.files[0])
      console.log('imageArraynew---->>>>>>>>>', this.imageArray.length);
      if (event.target.files[0].size / 1024 / 1024 <= 5) {
        for (let index = 0; index < event.target.files.length; index++) {
          const formData = new FormData();
          formData.append('doc', event.target.files[index]);

          this.userService.updateUserImageS3(formData).subscribe(
            response => {

              console.log("response data S3>>>", response);
              this.imageArray.push(response.data);
              this.onFlyImageArray.push(response.data);
              this.storeIsDefault.push({ "imgDefault": "0", "img": response.data });
              // var reader = new FileReader();
              // reader.onload = (event: ProgressEvent) => {
              //   this.onFlyImageArray.push({ "bs64": (<FileReader>event.target).result });
              // }
              // reader.readAsDataURL(event.target.files[0]);

              if (event.target.files.length >= "4" && event.target.files.length <= '8') {
                setTimeout(() => {    //<<<---    using ()=> syntax
                  this.spinner.hide();
                }, 9000);
              }
              else if (event.target.files.length >= "2" && event.target.files.length < '4') {
                setTimeout(() => {    //<<<---    using ()=> syntax
                  this.spinner.hide();
                }, 5000);
              }
              else {
                this.spinner.hide();
              }


            },
            error => {

              this.spinner.hide();
            }
          );
        };
      }
      else {
        this.spinner.hide();
        this.toastr.error("Images are more than 5mb size not uploaded", 'Error!', {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });

      }
    }
    else {
      this.spinner.hide();
      this.toastr.error("You Can Add 8 Images only!", 'Error!', {
        timeOut: 5000,
        progressBar: true,
        closeButton: true
      });

    }
    // 
  }
  deleteImage(e) {
    console.log("previous>>>", this.onFlyImageArray)
    let cArray = this.onFlyImageArray;
    cArray.splice(e, 1);

    let aArray = this.imageArray;
    aArray.splice(e, 1);

    let imgArray = this.storeIsDefault;
    imgArray.splice(e, 1);

    // let aArray = this.imageArray;
    // aArray.splice(e, 1);
    this.isImageChange = true;
    console.log("deleteImage>>>", this.onFlyImageArray);
  }

  loadUser() {
    let currentUser: any = this.authenticationService.currentUserValue;

    const requestData = {
      id: currentUser.profile.FKuserID,

    }
    let rGetProfileData: any;
    this.globalService.getUserDetails(requestData).subscribe(
      response => {

        rGetProfileData = response.data;
        console.log('response', rGetProfileData)
        if (!response.error) {
          // this.loading = false;
          // this.toastr.success(response.message, 'Success!', {
          //   timeOut: 5000,
          //   progressBar: true,
          //   closeButton: true
          // });

          rGetProfileData.profilePic && rGetProfileData.profilePic.map(img => {

            if (img.profilePic != '') {
              this.storeIsDefault.push({ "imgDefault": img.isDefault, "img": img.profilePic });
              this.imageArray.push(img.profilePic);
              return this.onFlyImageArray.push(img.profilePic);
            }
            else {
              return this.onFlyImageArray;
            }

          })
          console.log("new errat>>>>>>>", this.storeIsDefault);


          this.HashTags = rGetProfileData.profile.HashTags ? rGetProfileData.profile.HashTags : '';
          this.Channels = rGetProfileData.profile.Channel ? rGetProfileData.profile.Channel : [];

          this.Interest = rGetProfileData.profile.Interest ? rGetProfileData.profile.Interest : [];
          this.editInfoForm.patchValue(
            {
               "username": rGetProfileData.profile.username,
              "firstname": rGetProfileData.profile.firstName,
              "lastname": rGetProfileData.profile.lastName,
              "email": rGetProfileData.profile.email,
              "country_code" : rGetProfileData.profile.countryCode,
              "phone" : rGetProfileData.profile.phoneNo,
              "dob": rGetProfileData.profile.dob,
              "aboutMe": rGetProfileData.profile.aboutMe,
              "location": rGetProfileData.profile.location,
              "city": rGetProfileData.profile.city,
              "state": rGetProfileData.profile.state,
              "country": rGetProfileData.profile.country,
              "gender": rGetProfileData.profile.gender,

              "hideMyLocation": rGetProfileData.profile.profileSettings && rGetProfileData.profile.profileSettings.hideMyLocation == '1' ? rGetProfileData.profile.profileSettings.hideMyLocation : '',

              "hideMyInterest": rGetProfileData.profile.profileSettings && rGetProfileData.profile.profileSettings.hideMyInterest == "1" ? rGetProfileData.profile.profileSettings.hideMyInterest : '',

              "hideMyBirthday": rGetProfileData.profile.profileSettings && rGetProfileData.profile.profileSettings.hideMyBirthday == '1' ? rGetProfileData.profile.profileSettings.hideMyBirthday : '',

              "hideMyRealName": rGetProfileData.profile.profileSettings && rGetProfileData.profile.profileSettings.hideMyRealName == "1" ? rGetProfileData.profile.profileSettings.hideMyRealName : ''

            }
          );
          console.log('editInfoForm', this.editInfoForm);


          this.spinner.hide();
        }
      },
      error => {

        this.toastr.error("Sorry, something went wrong there. Try again", 'Error!', {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
        this.spinner.hide();
      }
    );
  }
  saverange(e) {
    //alert(e);
  }
  onEmailUpdate(templateEdit) {
    this.submitted = true
    let currentUser: any = this.authenticationService.currentUserValue;

    if (!this.addLineUp.invalid) {

      this.spinner.show();
      this.userService.sendOTPtochangeUserEmail({
        userID: currentUser.profile.FKuserID,
        email: this.addLineUp.value.newEmail
      }).subscribe(
        response => {
         
       

          if (!response.error) {
            this.submitted = false;
            this.emailOTP = response.data.OTP;
            this.modalRef.hide();
            this.spinner.hide();
            this.openOTPEmailModal(templateEdit);  
          }
          this.toastr.success("Please check email for OTP", 'Success!', {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });

        },
        error => {


          this.toastr.error("Sorry, something went wrong there. Try again", 'Error!', {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
          this.spinner.hide();
        }
      );


    }

  }
  onPhoneUpdate(templateEditPhone){
    this.submitted = true
    let currentUser: any = this.authenticationService.currentUserValue;

    if (!this.addPhoneUp.invalid) {

      this.spinner.show();
      this.userService.sendOTPtochangeUserPhone({       
        phoneNo: this.addPhoneUp.value.newPhone,
        countryCode: this.addPhoneUp.value.country_code,
        userID: currentUser.profile.FKuserID,
      }).subscribe(
        response => {
         
       

          if (!response.error) {
            this.submitted = false;
            this.phoneOTP = response.data.OTP;
            this.modalRef.hide();
            this.spinner.hide();
            this.openOTPPhoneModal(templateEditPhone);  
          }
          this.toastr.success("Please check phone for OTP", 'Success!', {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });

        },
        error => {


          this.toastr.error("Sorry, something went wrong there. Try again", 'Error!', {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
          this.spinner.hide();
        }
      );


    }
  }
  openOTPEmailModal(templateEdit: TemplateRef<any>){
    this.modalRef = this.modalService.show(templateEdit);
  }
  openOTPPhoneModal(templateEditPhone: TemplateRef<any>){
    this.modalRef = this.modalService.show(templateEditPhone);
  }
  get fc() { return this.addLineUp.controls; }
  get ofc() { return this.addOTPEmail.controls; }
  get ofcp() { return this.addPhoneUp.controls; }
  get ofcpp() { return this.addOTPPhone.controls; }

  onOTPUpdate(){

    this.submitted = true
    let currentUser: any = this.authenticationService.currentUserValue;

    if (!this.addOTPEmail.invalid) {

      if(this.emailOTP != this.addOTPEmail.value.newOTP){
        this.toastr.error("Sorry, OTP Not Matched", 'Error!', {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
        this.spinner.hide();
      }
      else{
        this.emailOTP ='';
        this.spinner.show();
        this.userService.changeUserEmail({
          userID: currentUser.profile.FKuserID,
          email: this.addLineUp.value.newEmail
        }).subscribe(
          response => {
           
         
  
            if (!response.error) {
              this.submitted = false;
            
              this.modalRef.hide();
              this.spinner.hide();
            
            }
            this.toastr.success(response.message, 'Success!', {
              timeOut: 5000,
              progressBar: true,
              closeButton: true
            });
  
          },
          error => {
  
  
            this.toastr.error("Sorry, something went wrong there. Try again", 'Error!', {
              timeOut: 5000,
              progressBar: true,
              closeButton: true
            });
            this.spinner.hide();
          }
        );


      }
    }



  }
  onOTPUpdatePhone(){
    this.submitted = true
    let currentUser: any = this.authenticationService.currentUserValue;

    if (!this.addOTPPhone.invalid) {

      if(this.phoneOTP != this.addOTPPhone.value.newOTP){
        this.toastr.error("Sorry, OTP Not Matched", 'Error!', {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
        this.spinner.hide();
      }
      else{
        this.emailOTP ='';
        this.phoneOTP ='';
        this.spinner.show();
        this.userService.changeuserPhone({
          userID: currentUser.profile.FKuserID,
          phoneNo: this.addPhoneUp.value.newPhone,
          countryCode: this.addPhoneUp.value.country_code,
        }).subscribe(
          response => {
           
         
  
            if (!response.error) {
              this.submitted = false;
            
              this.modalRef.hide();
              this.spinner.hide();
              this.loadUser();
            }
            this.toastr.success(response.message, 'Success!', {
              timeOut: 5000,
              progressBar: true,
              closeButton: true
            });
  
          },
          error => {
  
  
            this.toastr.error("Sorry, something went wrong there. Try again", 'Error!', {
              timeOut: 5000,
              progressBar: true,
              closeButton: true
            });
            this.spinner.hide();
          }
        );


      }
    }



  }
  ngOnInit() {
    let ipData = localStorage.getItem("ipData")
    if (!ipData) {
     this.globalService.getIpdata().subscribe(
       reaponse =>{
         localStorage.setItem("ipData", JSON.stringify(reaponse))
         this.selectCountry(reaponse)
       }
     )
    }else {
      this.selectCountry(JSON.parse(ipData))
    }
    this.addLineUp = this.formBuilder.group({

      newEmail: ['', [Validators.required]],
     // newOTP: ['', [Validators.required]],
    });

    this.addOTPEmail = this.formBuilder.group({

      newOTP: ['',[Validators.required]],
    });
    this.addPhoneUp = this.formBuilder.group({
      country_code: ['',[Validators.required]],
      newPhone: ['',[Validators.required]],
    });
    let currentUser: any = this.authenticationService.currentUserValue;
      

       this.addPhoneUp.patchValue(
        {
          //  "username": rGetProfileData.profile.username,
          "country_code":  currentUser.profile.countryCode,

        });
        this.addOTPPhone = this.formBuilder.group({

          newOTP: ['',[Validators.required]],
        });
    // this.renderer.addClass(this.document.body, 'yellow-bg');
    // this.renderer.addClass(this.document.body, 'mt-0');
    this.loadUser();
    this.maxDate = new Date();

    this.maxDate.setDate(this.maxDate.getDate() - 6570);

    this.globalService.getCountries().pipe(first()).subscribe(
      data => {
        if (!data.error) {
          //Success
          console.log("channels>>>", data.data);
          this.getCountry = data.data;

        } else {
          //Error

        }
      },
      error => {

      }
    )
    this.userService.getchannel().pipe(first()).subscribe(
      data => {
        if (!data.error) {
          //Success
          console.log("channels>>>", data.data);
          this.getUserChannel = data.data;
          this.spinner.hide();
        } else {
          //Error
          this.spinner.hide();
        }
      },
      error => {

      }
    )

    this.userService.getInterests().pipe(first()).subscribe(
      data => {
        if (!data.error) {
          //Success
          console.log("channels>>>", data.data);
          this.getUserInterest = data.data;
          this.spinner.hide();
        } else {
          //Error
          this.spinner.hide();
        }
      },
      error => {

      }
    )
    this.editInfoForm = this.formBuilder.group({
     username: [''],
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      email: [''],
      country_code: [''],
      phone:[''],
      dob: ['', [Validators.required]],
      aboutMe: [''],
      location: [''],
      city: [''],
      state: [''],
      country: [''],
      gender: [''],
      hideMyLocation: [''],
      hideMyInterest: [''],
      hideMyBirthday: [''],
      hideMyRealName: ['1']
    });

    console.log(">>>", this.onFlyImageArray);
  }

  customFilter(array) {
    let ChannelsList = this.Channels.map(c => {
      return c.id;
    });
    return array.filter(item => !ChannelsList.includes(item.id))
  }
  customInterestFilter(array) {

    let InterestList = this.Interest && this.Interest.map(c => {
      return c.id;
    });
    return array.filter(item => !InterestList.includes(item.id))
  }


  get f() { return this.editInfoForm.controls; }
  onSubmit() {

    this.spinner.show();
    this.submitted = true
    // stop here if form is invalid
    console.log('editInfoForm', this.editInfoForm);
    console.log('onSubmit imageArray', this.imageArray);


    if (this.imageArray.length > 0 && this.isImageChange) {
      let currentUser: any = this.authenticationService.currentUserValue;

      let dataImage = {
        "userID": currentUser.profile.FKuserID,
        "profilePic": this.imageArray.map((img, index) => {
          let a;
          if (img) {
            if (index == this.isdefault && this.isDefaultChange) {
              a = {
                "url": img,
                "isDefault": 1
              }
            }
            else if (this.storeIsDefault[index].img == img && this.storeIsDefault[index].imgDefault == '1' && !this.isDefaultChange) {
              a = {
                "url": img,
                "isDefault": 1
              }

            }

            else {
              a = {
                "url": img,
                "isDefault": 0
              }
            }
          }

          return a;
        })

      }

      this.userService.userSaveImage(dataImage).subscribe(
        response => {

          console.log("response data S3>>>", response);
          this.imageArray.push(response.data)
        },
        error => {

          this.spinner.hide();
        }
      );

    }



    if (this.editInfoForm.invalid) {
      console.log('basicInfoFormError', this.editInfoForm);

      this.spinner.hide();
      return;
    }
    else {
      let currentUser: any = this.authenticationService.currentUserValue;
      let data = {
        isChangePass: 0,
        userID: currentUser.profile.FKuserID,
        firstName: this.editInfoForm.value.firstname,
        lastName: this.editInfoForm.value.lastname,
        location: this.editInfoForm.value.location,
        city: this.editInfoForm.value.city,
        state: this.editInfoForm.value.state,
        country: this.editInfoForm.value.country,
        gender: this.editInfoForm.value.gender,
        dob: moment(this.editInfoForm.value.dob).format('MM/DD/YYYY'),
        aboutMe: this.editInfoForm.value.aboutMe,
        HashTags: this.HashTags,
        Channel: this.Channels.map(e => {
          return e.id;
        }),
        interest: this.Interest.map(e => {
          return e.id;
        }),
        profileSettings: {
          hideMyBirthday: this.editInfoForm.value.hideMyBirthday ? 1 : 0,
          hideMyInterest: this.editInfoForm.value.hideMyInterest ? 1 : 0,
          hideMyLocation: this.editInfoForm.value.hideMyLocation ? 1 : 0,
          hideMyRealName: this.editInfoForm.value.hideMyRealName ? 1 : 0
        }
      };
      console.log('editInfoForm data', data);
      this.userService.updateUserdata(data).subscribe(
        response => {
          if (!response.error) {
            this.spinner.hide();
            this.isImageChange = false;
            this.isDefaultChange = false;
            this.toastr.success("Profile Successfully Updated!", 'Success!', {
              timeOut: 5000,
              progressBar: true,
              closeButton: true
            });
          }

        },
        error => {

          this.toastr.error("Sorry, something went wrong there. Try again", 'Error!', {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
          this.spinner.hide();
        }
      );
    }
  }



}
