import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BroadcastService } from 'src/app/_services/broadcast.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import {FormControl, FormBuilder, FormGroup} from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { startWith } from 'rxjs/internal/operators/startWith';
import { map } from 'rxjs/internal/operators/map';
import { GlobalService } from 'src/app/_services/global.service';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { User, IUserResponse } from 'src/app/_models/search';

@Component({
  selector: 'app-invite-guest',
  templateUrl: './invite-guest.component.html',
  styleUrls: ['./invite-guest.component.scss']
})
export class InviteGuestComponent implements OnInit {

  @Input() userId;
  @Input() broadcastID;
  
  usersForm: FormGroup;
  filteredUsers: Observable<IUserResponse>;
  selectedUser : any;
  user: any;
  constructor(
    public activeModal: NgbActiveModal,
    public broadcastService: BroadcastService,
    public spinner: NgxSpinnerService,
    public toastr: ToastrService,
    public globalService: GlobalService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.usersForm = this.fb.group({
      userInput: null
    })

    this.filteredUsers = this.usersForm.get('userInput').valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => this.globalService.search({keywored: value}, 1))
      );
  }
  displayFn(user?: User): string | undefined {
    if (user) { 
      return user.username;
    }
  }

  invite(){
    this.inviteH2H(this.usersForm.get('userInput').value)
  }
  inviteH2H(toUserID ?: User){
    if (!toUserID) {
      return  this.toastr.error("Select a proper user", 'Error!', {
        timeOut: 2000,
      });
    }
    if(toUserID.id == this.userId){
      return  this.toastr.error("You can't invite yourself", 'Error!', {
        timeOut: 2000,
      });
    }
    this.spinner.show();
    this.broadcastService.inviteUserForGuestAppearance({
      userID : this.userId,
      broadcastID : this.broadcastID,
      toUserID : toUserID.id
    }).subscribe(
      response => {
        if (response.error) {
          //Error
          this.spinner.hide();
          return  this.toastr.error(response.message, 'Error!', {
            timeOut: 2000,
          });
        } else {
          //Success
          this.spinner.hide();
          return  this.toastr.success("Invitation Sent", 'Success!', {
            timeOut: 2000,
          });
        }
      },
      error => {

      }
    )
  }
}
