import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService, GlobalService, UploadFileService, UserService } from '../../../../../_services';
import { NgxSpinnerService } from "ngx-spinner";

import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';
import { RechargeService } from 'src/app/_services/recharge.service';

@Component({
  selector: 'app-convert-to-coins',
  templateUrl: './convert-to-coins.component.html',
  styleUrls: ['./convert-to-coins.component.css']
})
export class ConvertToCoinsComponent implements OnInit {
  isDashboardMode = true
  title = 'Convert to Coins'
  color = 'coin_headerbg'
  
  coins = 0;
  diamonds_available = 0;
  total_cash_out = 0;
  diamondToCoinsOptions = [];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private globalService: GlobalService,
    private userService: UserService,
    private rechargeService: RechargeService,

    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.getUserStat();
    this.getDiamondToCoinsOptions();
  }
  
  getDiamondToCoinsOptions(){
    this.spinner.show();
    this.rechargeService.getDiamondToCoinsOptions().subscribe(
      response => {
        this.diamondToCoinsOptions = response.data;
        this.spinner.hide();
      },
      error => {

      }
    );
  }

  convertDiasmondtoCoin(amount){
    this.spinner.show();
    let currentUser: any = this.authenticationService.currentUserValue;
    const request = {
      amount : amount,
      userID : currentUser.profile.FKuserID
    }
    this.rechargeService.convertDiasmondtoCoin(request).subscribe(
      response => {
        this.getUserStat();
        this.toastr.success(response.message, 'Convert to Coins!', {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
        this.spinner.hide();
      },
      error => {

      }
    );
  }

  getUserStat(){
    this.spinner.show();
    let currentUser: any = this.authenticationService.currentUserValue;

    const requestData = {
      userID: currentUser.profile.FKuserID,
    }
    this.rechargeService.getUserStat(requestData).subscribe(
      response => {
        this.coins = response.data.totalCoinsFormated
        this.diamonds_available = response.data.totalDiamondsFormated
        this.total_cash_out = response.data.totalCashout
        this.spinner.hide();
      },
      error => {

      }
    )
  }

}
