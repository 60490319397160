import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { ChannelService } from '../_services';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { BroadcastService } from '../_services/broadcast.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-channel',
  templateUrl: './channel.component.html',
  styleUrls: ['./channel.component.css']
})
export class ChannelComponent implements OnInit {
  channels = []
  broadcastTypes = []
  sub;
  channelId :any
  channelSlug :any
  theme: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private channelService: ChannelService,
    private broadcastService: BroadcastService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {
    
   }

  ngOnInit() {
   
    console.log('Theme', localStorage.getItem('theme'))
    switch (localStorage.getItem('theme')) {
      case '1':
        this.theme = ''
        break;
      case '2':
        this.theme = 'left-side-bar-green'
        break;
      case '3':
        this.theme = 'left-side-bar-orange'
        console.log('Theme3', localStorage.getItem('theme'))
        break;
    
      default:
        console.log('ThemeD', localStorage.getItem('theme'))
        break;
    }

    this.renderer.removeClass(this.document.body, 'yellow-bg');
    this.renderer.removeClass(this.document.body, 'mt-0');
    this.renderer.removeClass(this.document.body, 'signup-with-phone');
    this.renderer.addClass(this.document.body, 'profie-page');
    this.spinner.show()
    this.channelService.getChannels().subscribe(
      response =>{
        if (response.error) {
          //Error
        } else {
          //Success
          // if (localStorage.getItem("channels")) {
          //   this.channels = JSON.parse(localStorage.getItem("channels")).reverse()
          // }else{
          //   localStorage.setItem("channels", JSON.stringify(response.data))
          //   this.channels = response.data;
          // }
          this.channels = response.data.reverse();

          let btype = localStorage.getItem("broadcastType")
          if (btype) {
            this.broadcastTypes = JSON.parse(btype)
          } else {
            this.broadcastService.getAllBroadcastType().subscribe(
              responseType => {
                if (!responseType.error) {
                  console.log("broadcastType", responseType.data);
                  localStorage.setItem("broadcastType", JSON.stringify(responseType.data))
                  this.broadcastTypes = responseType.data
                }
              },
              error => {
  
              }
            )
          }

        }
        this.spinner.hide();
      },error=>{
         //Error
         this.spinner.hide();
      }
    );
    this.sub=this.route.paramMap.subscribe(params => { 
      this.channelId = params.get('id');
      this.channelSlug = params.get('slug');
    });
  }

}
