import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.css']
})
export class TopNavComponent implements OnInit {
  currentUser:any
  profile:any
  stat:any
  @Input('color') color: any;

  constructor() { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'))
    if (this.currentUser) {
      this.profile = this.currentUser.profile
      this.stat = this.currentUser.profile.Stat
    }
    if (this.color == undefined) {
      this.color = 'my_dashboardbg';
    }
  }

}
