import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService, GlobalService, UserService } from '../../_services';
import { NgxSpinnerService } from "ngx-spinner";

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-otp-forgot-password',
  templateUrl: './otp-forgot-password.component.html',
  styleUrls: ['./otp-forgot-password.component.scss']
})
export class OTPForgotPasswordComponent implements OnInit {
  mobile :Number
  otp:String
  returnUrl: string;
  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private globalService: GlobalService,
    private spinner: NgxSpinnerService,
    private userService: UserService,
  ) { }

  ngOnInit() {
  }
  onOtpChange(enteredOtp){
    console.log("enteredOtp", enteredOtp)
    var otpStr = new String(enteredOtp) 
    if (otpStr.length == 4) {
      this.otp = enteredOtp
      console.log("finalOtp", this.otp)
    }else {
      this.otp = null;
      console.log("finalOtp", this.otp)
    }
  }
  onClickVerifyOtp(){
    if (this.otp) {
      if(this.otp == localStorage.getItem('passwordOTP')){
         
        this.userService.forgotPassword( JSON.parse(localStorage.getItem('passwordRequest'))).subscribe(
          responce => {
            if (!responce.error) {
              
              // this.router.navigate(["otp-forgot-password"]);
              // console.log("new responce>>>", responce.data.OTP);
              // localStorage.setItem("passwordOTP", responce.data.OTP);
              // localStorage.setItem("passwordRequest", requestData);
              this.toastr.success(responce.message +" Please check your registered email", 'Success!', {
                timeOut: 5000,
                progressBar: true,
                closeButton: true
              });
              this.spinner.hide();
            } else {
              //Error
              this.spinner.hide();
            }
          },
          error => {
    
          }
        )


      }
      else{
        this.toastr.error("OTP not matched!", 'Error!', {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
      }
    }
  }


}
