import { Component, OnInit, Input, Inject, Renderer2 } from '@angular/core';
import { AuthenticationService, UserService } from '../../_services';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';
import { AuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.css']
})
export class StatsComponent implements OnInit {
  avtarImg = 'assets/images/no-photo.png'
  user: any
  returnUrl: any
  currentUser: any
  isCurrentUser = false;
  isFollowed = false;
  isLiked = false;
  isAuth = false;
  isBlocked = false;
  isMale = false;
  shareCount = 0;
  shareText = 'Ryzelive Profile Link: '
  data = {
    firstName: '',
    lastName: '',
    username: '',
    aboutMe: '',
    profileID: '',
    totalCoinsFormated: '',
    userLevel: '',
    totalFansFormated: '',
    totalFollowersFormated: '',
    totalfriendsFormated: '',
    totalLikeFormated: '',
    location: '',
    city: '',
    country: '',
    identificationId: '000000',
    hideMyRealName: true,
    hideMyLocation: true,
    hideMyInterest: true,
    totalDiamond:'',
  }

  @Input('userId') userId: any;
  href: string;

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private authService: AuthService
  ) { }

  ngAfterViewInit() {
    if (this.userId) {
      this.load();
    }
  }
  ngOnInit() {
    this.renderer.removeClass(this.document.body, 'yellow-bg');
    this.renderer.removeClass(this.document.body, 'mt-0');
    this.renderer.removeClass(this.document.body, 'signup-with-phone');
    this.renderer.addClass(this.document.body, 'profie-page');

    //this.href = location.origin + this.router.url;
    // get return url from route parameters or default to '/channels'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/login';
  }
  shareDone(e) {
   console.log("Shared on", e)
   let currentUser: any = this.authenticationService.currentUserValue;
   if (this.user.profile.FKuserID != currentUser.profile.FKuserID) {
    this.userService.shareProfile({userID:this.user.profile.FKuserID, shareBY:currentUser.profile.FKuserID}).subscribe(
      response =>{
        if (!response.error) {
         this.shareCount++
        }
       //this.shareCount++
      },
      error=>{}
    )
   }
  }
  load() {

    let currentUser: any = this.authenticationService.currentUserValue;
    this.userService.getUserDetails({ id: this.userId, userID: currentUser ? currentUser.profile.FKuserID : 0 }).subscribe(
      response => {
        if (response.error) {
          //Error
          this.logout();
          this.toastr.error(response.message, 'Error!', {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
        } else {


          this.user = response.data;

          this.data.firstName = this.user.profile.firstName
          this.data.lastName = this.user.profile.lastName
          this.data.username = this.user.profile.username
          //Fix : Need to fix static url
          this.href = "https://ryzelive.firebaseapp.com/profile/"+this.user.profile.username;

          this.data.location = this.user.profile.location
          this.data.city = this.user.profile.city
        
          this.data.country = this.user.profile.CountryName
          this.data.aboutMe = this.user.profile.aboutMe
          this.data.profileID = this.user.profile.profileID
          if (this.user.profile.gender === "1") {
            this.isMale = true
          } else {
            this.isMale = false
          }
          this.data.totalCoinsFormated = this.user.profile.Stat.totalCoinsFormated
          this.data.totalDiamond = this.user.profile.Stat.totalDiamondsCollectedFormated
          this.data.userLevel = this.user.profile.Stat.userLevel
          this.data.totalFansFormated = this.user.profile.Stat.totalFansFormated
          this.data.totalFollowersFormated = this.user.profile.Stat.totalFollowersFormated
          this.data.totalfriendsFormated = this.user.profile.Stat.totalfriendsFormated
          this.data.totalLikeFormated = this.user.profile.Stat.totalLikeFormated

          if (this.user.profile.profileSettings) {
            this.data.hideMyRealName = this.user.profile.profileSettings.hideMyRealName != '1' ? false : true;
          }

          if (this.user.profile.profileSettings) {
            this.data.hideMyLocation = this.user.profile.profileSettings.hideMyLocation != '1' ? false : true;
          }

          if (this.user.profile.profileSettings) {
            this.data.hideMyInterest = this.user.profile.profileSettings.hideMyInterest != '1' ? false : true;
          }
          this.isFollowed = this.user.isFollow =='1' ? true:false;
          this.isLiked = this.user.isLiked =='1' ? true:false;
          this.isBlocked = this.user.isBlocked == '1' ? true: false;
          this.currentUser = JSON.parse(localStorage.getItem('currentUser'))

          if (this.currentUser) {
            this.shareCount = this.user.UserStatDetails.UserShareDetails.total
            //Get follower Details
            // this.user.UserStatDetails.UserFollowerDetails.data.forEach(element => {
            //   if (element.FKuserID === this.currentUser.profile.FKuserID) {
            //     this.isFollowed = true
            //     return;
            //   } else {
            //     this.isFollowed = false
            //   }
            // });

            //Get like Details
            // this.user.UserStatDetails.UserLikeDetails.data.forEach(element => {
            //   if (element.FKuserID === this.currentUser.profile.FKuserID) {
            //     this.isLiked = true
            //     return;
            //   } else {
            //     this.isLiked = false
            //   }
            // });

            if (this.user && this.currentUser.profile.username === this.user.profile.username) {
              this.isCurrentUser = true;
            }
            this.isAuth = true
          } else {
            this.isAuth = false
          }

          if (this.user && this.user.profile.Avtar) {
            this.avtarImg = this.user.profile.Avtar
          }
        }
      },
      error => {
        //Error
      }
    );
  }
  logout() {
    this.authService.signOut();
    this.authenticationService.logout();
    localStorage.removeItem("twitter-oauth-token");
    localStorage.removeItem("twitter-oauth-token-secret");
    this.router.navigate([this.returnUrl]);
  }

  follow() {
    let currentUserId = this.currentUser.profile.FKuserID;
    const request = {
      userID: this.user.profile.FKuserID,
      followedBY: currentUserId,
      status: this.isFollowed ? 0 : 1
    }
    if (this.isFollowed) {
      this.userService.unFollowUser(request).subscribe(
        response => {
          if (response.error) {
            //Error
          } else {
            //Success
            this.isFollowed = false;
            this.load();
          }
        },
        error => {

        }
      )
    } else {
      this.userService.followUser(request).subscribe(
        response => {
          if (response.error) {
            //Error
          } else {
            //Success
            this.isFollowed = true;
            this.load();
          }
        },
        error => {

        }
      )
    }

  }

  like() {
    let currentUserId = this.currentUser.profile.FKuserID;
    const request = {
      userID: this.user.profile.FKuserID,
      likeBY: currentUserId,
      status: this.isLiked ? 0 : 1
    }
    if (this.isLiked) {
      this.userService.unLikeUser(request).subscribe(
        response => {
          if (response.error) {
            //Error
          } else {
            //Success
            this.isLiked = false;
            this.load();
          }
        },
        error => {

        }
      )
    } else {
      this.userService.likeUser(request).subscribe(
        response => {
          if (response.error) {
            //Error
          } else {
            //Success
            this.isLiked = true;
            this.load();
          }
        },
        error => {

        }
      )
    }

  }


  blockedUser(id) {
    let currentUser: any = this.authenticationService.currentUserValue;
    const requestData = {
      userID: currentUser.profile.FKuserID,
      toUserID: id,
      status:  this.isBlocked ? 0 : 1
    }
    this.userService.blockUser(requestData).subscribe(
      data => {
        if (!data.error) {
          this.load();

        } else {
          //Error
          this.toastr.error(data.message, 'Error!', {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });

        }
      },
      error => {


      }
    )
  }
}
