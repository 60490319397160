import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-contests',
  templateUrl: './user-contests.component.html',
  styleUrls: ['./user-contests.component.css']
})
export class UserContestsComponent implements OnInit {
  isDashboardMode = true
  title = 'My Contests'
  color = 'contest-head-bg'
  constructor() { }

  ngOnInit() {
  }

}
