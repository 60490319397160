import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { User } from '../_models';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })

export class BroadcastService {
    constructor(
        private http: HttpClient,
        private route: ActivatedRoute,
        private router: Router,
    ) {

    }

    getNotifications(requestData) {
        return this.http.get<any>(`${environment.apiUrl}app/notification/getNotifications?userID=`+requestData.userID+`&start=0&limit=100`);
    }

    deleteNotifications(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/notification/deleteNotifications`, requestData);
    }
    
    getAllBroadcastByChannels(requestData) {
        return this.http.get<any>(`${environment.apiUrl}app/broadcast/getAllBroadcastByChannels?userID=` + requestData.userID + `&channelID=` + requestData.channelID + `&lat=` + requestData.lat + `&lng=` + requestData.lng + `&todaysdate=` + requestData.todaysdate + `&limit=` + requestData.limit + `&start=` + requestData.start + ``);
    }

    getChannelTokenForUserBroadcast(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/broadcast/getChannelTokenForUserBroadcast`, requestData);
    }
    getChannelTokenToJoinABroadcast(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/broadcast/getChannelTokenToJoinABroadcast`, requestData);
    }

    getBroadcastActiveViwerList(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/broadcast/getBroadcastActiveViwerList`, requestData);
    }

    leaveChannelBroadcastByUser(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/broadcast/leaveChannelBroadcastByUser`, requestData);
    }

    stopChannelBrodcastByUser(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/broadcast/stopChannelBrodcastByUser`, requestData);
    }

    exitHeadtoHead(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/broadcast/exitHeadtoHead`, requestData);
    }

    getActiveBroadcasterListForHeadToHead(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/broadcast/getActiveBroadcasterListForHeadToHead`, requestData);
    }

    getActiveGuestAppearanceRequest(requestData) {
        return this.http.get<any>(`${environment.apiUrl}app/broadcast/getActiveGuestAppearanceRequest?userID=` + requestData.userID);
    }

    getActiveMulticastRequest(requestData) {
        return this.http.get<any>(`${environment.apiUrl}app/broadcast/getActiveMulticastRequest?userID=` + requestData.userID);
    }

    getUserFriendFamilyFollowerDetail(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/getUserFriendFamilyFollowerDetail`, requestData);
    }

    inviteUserForHeadToHead(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/broadcast/inviteUserForHeadToHead`, requestData);
    }

    inviteUserForGuestAppearance(requestData) {
        return this.http.get<any>(`${environment.apiUrl}app/broadcast/inviteUserForGuestAppearance?userID=` + requestData.userID + `&broadcastID=` + requestData.broadcastID + `&toUserID=` + requestData.toUserID);
    }

    inviteUserForMulticast(requestData) {
        return this.http.get<any>(`${environment.apiUrl}app/broadcast/inviteUserForMulticast?userID=` + requestData.userID + `&broadcastID=` + requestData.broadcastID + `&toUserID=` + requestData.toUserID);
    }

    acceptHeadtoHeadReq(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/broadcast/acceptHeadtoHeadReq`, requestData);
    }

    acceptMulticastReq(requestData) {
        return this.http.get<any>(`${environment.apiUrl}app/broadcast/acceptMulticastReq?userID=` + requestData.userID + `&multicastReqID=` + requestData.multicastReqID + `&isAudioOnly=` + requestData.isAudioOnly);
    }

    rejectMulticastReq(requestData) {
        return this.http.get<any>(`${environment.apiUrl}app/broadcast/rejectMulticastReq?userID=` + requestData.userID + `&multicastReqID=` + requestData.multicastReqID);
    }
    accepGuestReq(requestData) {
        return this.http.get<any>(`${environment.apiUrl}app/broadcast/acceptGuestAppearanceReq?userID=` + requestData.userID + `&guestAppearanceReqID=` + requestData.guestAppearanceReqID);
    }

    acceptHeadtoHeadJoinReq(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/broadcast/acceptHeadtoHeadJoinReq`, requestData);
    }

    acceptGuestAppearanceJoinReq(requestData) {
        return this.http.get<any>(`${environment.apiUrl}app/broadcast/acceptGuestAppearanceJoinReq?userID=` + requestData.userID + `&guestAppearanceReqID=` + requestData.guestAppearanceReqID);
    }
    rejectGuestReq(requestData) {
        // tslint:disable-next-line: max-line-length
        return this.http.get<any>(`${environment.apiUrl}app/broadcast/rejectGuestAppearanceReq?userID=` + requestData.userID + `&guestAppearanceReqID=` + requestData.guestAppearanceReqID);
    }
    likeBroadcast(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/broadcast/likeBroadcast`, requestData);
    }
    sendRandomH2Hrequest(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/broadcast/sendRandomH2Hrequest`, requestData);
    }

    getAllBroadcastType() {
        return this.http.get<any>(`${environment.apiUrl}app/broadcast/getAllBroadcastType`);
    }

    getBroadcastDetail(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/broadcast/getBroadcastDetail`, requestData);
    }

    dropCoins(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/broadcast/dropCoins`, requestData);
    }

    collectCoin(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/broadcast/collectCoin`, requestData);
    }

    muteViewer(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/broadcast/muteViewer`, requestData);
    }

    removeViewer(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/broadcast/removeViewer`, requestData);
    }

    enableChatRoom(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/chat/enableChatRoom`, requestData);
    }

    startRecording(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/broadcast/startRecording`, requestData);
    }

    stopRecording(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/broadcast/stopRecording`, requestData);
    }

    addHeadToHeadJoinRequest(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/broadcast/AddHeadToHeadJoinRequest`, requestData);
    }

    addGuestAppearanceJoinRequest(requestData) {
        return this.http.get<any>(`${environment.apiUrl}app/broadcast/addGuestAppearanceJoinRequest?userID=`+requestData.userID+`&broadcastID=`+requestData.broadcastID+`&toUserID=`+requestData.toUserID);
    }

    addMulticastJoinRequest(requestData) {
        return this.http.get<any>(`${environment.apiUrl}app/broadcast/AddMulticastJoinRequest?userID=`+requestData.userID+`&broadcastID=`+requestData.broadcastID+`&toUserID=`+requestData.toUserID);
    }

    recordingQuery(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/broadcast/recordingQuery`, requestData);
    }

    saveBroadcastData(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/broadcast/saveBroadcastData`, requestData);
    }

    buyStoreCredit(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/broadcast/buyStoreCredit`, requestData);
    }

    getAllVideosForUser(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/getAllVideosForUser`, requestData);
    }

    activeUserVideoForProfile(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/activeUserVideoForProfile`, requestData);
    }

    deleteUserVideo(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/user/deleteUserVideo`, requestData);
    }
    
    viewBroadcastData(requestData) {
        return this.http.post<any>(`${environment.apiUrl}app/broadcast/makePublicBroadcastData`, requestData);
    }

    acceptMulticastJoinReq(requestData) {
        return this.http.get<any>(`${environment.apiUrl}app/broadcast/acceptMulticastJoinReq?userID=` + requestData.userID + `&multicastReqID=` + requestData.multicastReqID);
    }
    
}
