import { Component, OnInit, Renderer2, Inject,TemplateRef } from '@angular/core';
import { ChannelService, UserService } from '../_services';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxAgoraService, Stream, AgoraClient, ClientEvent, StreamEvent } from 'ngx-agora';
import { request } from 'https';
import { DOCUMENT, Location, LocationStrategy } from '@angular/common';
import { BroadcastService } from '../_services/broadcast.service';
import { SetLogoModalComponent } from "../_modal/set-logo-modal/set-logo-modal.component";
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-go-live',
  templateUrl: './go-live.component.html',
  styleUrls: ['./go-live.component.css']
})
export class GoLiveComponent implements OnInit {
  isDashboardMode = true
  titleHead = 'Go Live'
  color = 'my_dashboardbg'

  audioMode = false
  videoMode = true
  multiBeam = false
  standard = true

  channels = []
  hashTags = []
  tag = '';

  items = [];
  title = '';
  channel_sected = '1';
  user: any;
  userId: number;
  geolocation: any;
  showChannelModal=true;
  broadcastPhysicalChannel: string
  token: string
  btype: string
  url: string;
  broadcastId: any;
  modalRef: BsModalRef;
  modalRef2: BsModalRef;
  disabled = false;
  ShowFilter = true;
  redirectGotoLiveData =false;
  limitSelection = true;
  showTagInput = false;
  dropdownSettings: any = {};
  selectedChannelsIds = [];
  selectedItems = [];
  profile: any;
  multibeam = 9;
  tokenForChat: string;
  showPrimeryMenu=false;
  showSharemenu = false;
  chkbox = true;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private modalService2: BsModalService,
    private modalService3: BsModalService,
    private renderer: Renderer2,
    private channelService: ChannelService,
    private broadcastService: BroadcastService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private ngxAgoraService: NgxAgoraService,
    private _location: Location,
    private modalService: NgbModal,
    private userService: UserService,
  ) {

  }

  handleChangeChk(){
    
    this.chkbox = !this.chkbox;
  }
  showMenu(){   
    this.showPrimeryMenu = !this.showPrimeryMenu;
    this.showSharemenu = false;
  }
  shareButton(){
    this.showSharemenu = !this.showSharemenu;
    this.showPrimeryMenu = false;    
  }
  userImageUpload() {
    document.getElementById('getImageNew').click();
  }
  imageArray: any = [];
  onFlyImageArray: any = [];
  addImageArray(event) {

    console.log("xyz>>>", this.imageArray.length + event.target.files.length);

    console.log("<uysttt>>>", event.target.files[0].size / 1024 / 1024);
    if (this.imageArray.length + event.target.files.length <= 8) {
      this.spinner.show();
      //   this.imageArray.push(event.target.files[0])
      console.log('imageArraynew---->>>>>>>>>', this.imageArray.length);
      if (event.target.files[0].size / 1024 / 1024 <= 5) {
        for (let index = 0; index < event.target.files.length; index++) {
          const formData = new FormData();
          formData.append('userID', this.user.profile.FKuserID);
          formData.append('avtar', event.target.files[index]);

          this.userService.updateUserAvtarRuntime(formData).subscribe(
            response => {

              this.userService.getUserDetails({ id: this.user.profile.FKuserID }).subscribe(
                response => {
                  if (response.error) {
                    //Error

                    this.spinner.hide();
                  } else {
                    //Success
                    this.spinner.hide();
                    localStorage.setItem('currentUser', JSON.stringify(response.data))
                    
                    this.user = JSON.parse(localStorage.getItem('currentUser'))
                    this.profile = this.user.profile
                  }
                },
                error => {
                  //Error
                  this.spinner.hide()
                }
              );


              this.spinner.hide();



            },
            error => {

              this.spinner.hide();
            }
          );
        };
      }
      else {
        this.spinner.hide();
        this.toastr.error("Images are more than 5mb size not uploaded", 'Error!', {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });

      }
    }
    else {
      this.spinner.hide();
      this.toastr.error("You Can Add 8 Images only!", 'Error!', {
        timeOut: 5000,
        progressBar: true,
        closeButton: true
      });

    }
    // 
  }
  ngOnInit() {
    this.renderer.addClass(this.document.body, 'yellow-bg');
    this.renderer.addClass(this.document.body, 'mt-0');

    this.user = JSON.parse(localStorage.getItem('currentUser'))
    this.profile = this.user.profile
    this.selectedItems = this.user.profile.Channel
    this.selectedItems.forEach(element => {
      this.selectedChannelsIds.push(element.id)
    });

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      limitSelection: 3,
      allowSearchFilter: this.ShowFilter
    };

    //Get Channels
    this.channelService.getOnlyChannels(this.user.profile.FKuserID).subscribe(
      response => {
        if (!response.error) {
          this.channels = response.data
        }
      }
    )
  }
  selectMultiBeamOption(option) {
    this.multibeam = option
  }
  onItemSelect(item: any,template2: TemplateRef<any>) {

    if(this.showChannelModal){
      this.modalRef2 = this.modalService3.show(template2);
    }
    console.log('onItemSelect', item);
    this.selectedChannelsIds.push(item.id)
  }
  onItemDeSelect(item: any) {
    console.log('onItemDeSelect', item);
    this.selectedChannelsIds.splice(this.selectedChannelsIds.indexOf(item.id), 1);
  }

  onSelectAll(items: any) {
    console.log('onSelectAll', items);
  }
  toogleShowFilter() {
    this.ShowFilter = !this.ShowFilter;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, { allowSearchFilter: this.ShowFilter });
  }

  handleLimitSelection() {
    if (this.limitSelection) {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: 2 });
    } else {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: null });
    }
  }
  audio() {
    this.audioMode = !this.audioMode
    this.videoMode = !this.videoMode
  }

  video() {
    this.audioMode = false
    this.videoMode = true
  }
  standardSelect() {
    this.multiBeam = false
    this.standard = true
  }
  multiBeamSelect() {
    this.multiBeam = true
    this.standard = false
  }
  redirectGotoLive(a,t){
  if(this.chkbox){
    this.redirectGotoLiveData = true;
    this.modalRef.hide();
    this.goToLive(a,t);
   }
   else{
    this.toastr.error("Please accept terms and condition!", 'Error!', {
      timeOut: 2000,
      progressBar: true,
      closeButton: true
    });
   }
  
  }
  hideModalSet(){
    this.modalRef2.hide();
    this.showChannelModal=false;
  }
  goToLive(btype = 'standard',template: TemplateRef<any>) {
    if (!this.title) {
      return this.toastr.error("Enter Brodcast title", 'Error!', {
        timeOut: 2000,
        progressBar: true,
        closeButton: true
      });
    }

    if(this.title && !this.redirectGotoLiveData){
      this.modalRef = this.modalService2.show(template);
    }
    if(this.redirectGotoLiveData){
    this.items.forEach(tag => {
      this.hashTags.push(tag.value)
    });


    this.userId = parseInt(this.user.profile.FKuserID, 10);
    this.geolocation = JSON.parse(localStorage.getItem('geolocation'))

    if (!this.geolocation) {
      this.toastr.info("Please allow location access all the time", 'Location access', {
        timeOut: 2000,
        progressBar: true,
        closeButton: true
      });
      this._location.back();
    }
    // console.log("selectedChannelsIds", this.selectedChannelsIds);

    const request = {
      "userID": this.userId,
      "channelID": this.selectedChannelsIds,
      "title": this.title,
      "broadcastType": btype == 'standard' ? '1' : '3',
      "isAudioOnly": this.audioMode ? '1' : '0',
      "lat": this.geolocation.latitude,
      "lng": this.geolocation.longitude,
      "hashTags": this.hashTags,
      "maxAllowedSlots": this.multibeam
    }
    this.spinner.show()
    this.broadcastService.getChannelTokenForUserBroadcast(request).subscribe(
      response => {
        if (!response.error) {
          this.spinner.hide()
          this.broadcastPhysicalChannel = response.data.broadcastPhysicalChannel
          this.token = response.data.token
          this.broadcastId = response.data.id
          this.btype = btype
          this.tokenForChat = response.data.tokenForChat
          const audio = this.audioMode ? '1' : '0'
          localStorage.setItem('broadcastToken', this.token)
          localStorage.setItem('tokenForChat', this.tokenForChat)
          //this.url = 'go-live/broadcast/'+this.btype+'/'+this.userId+'/'+this.broadcastPhysicalChannel+'/'+this.broadcastId+'/'+this.tokenForChat
          // console.log('btype',this.btype);
          // console.log('userId',this.userId);
          // console.log('broadcastPhysicalChannel',this.broadcastPhysicalChannel);
          // console.log('broadcastId',this.broadcastId);
          // console.log('tokenForChat',this.tokenForChat);
          localStorage.removeItem('start_time')
          if (btype == 'standard') {
            this.url = 'go-live/broadcast/' + this.btype + '/' + this.userId + '/' + this.broadcastPhysicalChannel + '/' + this.broadcastId + '/' + audio
          } else {
            this.url = 'go-live/multi-broadcast/' + this.btype + '/' + this.userId + '/' + this.broadcastPhysicalChannel + '/' + this.broadcastId + '/' + audio + '/' + this.multibeam
          }
          //console.log('url',this.url);
          return this.router.navigate([this.url]);
        }
        this.toastr.error(response.message, 'Error!', {
          timeOut: 2000,
          // progressBar: true,
          // closeButton: true
        });
      },
      error => {

      }
    )
    }
  }

  setLogoModalOpen() {
    const modalRef = this.modalService.open(SetLogoModalComponent);
    // modalRef.componentInstance.userId = this.uid;
    // modalRef.componentInstance.broadcastID = this.broadcastId;
  }

  showHideTagInput() {
    this.showTagInput = !this.showTagInput
  }

  addTag() {
    if (this.tag) {
      this.items = this.tag.split(',')
      this.tag = '';
      this.showHideTagInput();
    }
  }

  removeTag(i) {
    let all_items = this.items;
    all_items.splice(i, 1);
    this.items = all_items;
  }
}
