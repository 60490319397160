import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-guardians',
  templateUrl: './user-guardians.component.html',
  styleUrls: ['./user-guardians.component.css']
})
export class UserGuardiansComponent implements OnInit {
  isDashboardMode = true
  title = 'My Guardians'
  color = 'myfan_head-bg'
  constructor() { }

  ngOnInit() {
  }

}
