import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/_services/user.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-user-settings-badge',
  templateUrl: './user-settings-badge.component.html',
  styleUrls: ['./user-settings-badge.component.css']
})
export class UserSettingsBadgeComponent implements OnInit {
  isDashboardMode = true
  title = 'My Badge'
  color = 'my-badge-head-bg'
  user: any;
  userId: any;
  activeBadges = [];
  earnedBadges = [];
  allAvailableBadges = [];

  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('currentUser'))
    this.userId = this.user.profile.FKuserID
    this.getAllBadgesForUser();
  }

  getAllBadgesForUser(){
    this.spinner.show()
    this.userService.getAllBadgesForUser({userID : this.userId}).subscribe(
      response => {
        console.log("getAllBadgesForUser", response);
        if (!response.error) {
          this.activeBadges = response.data.activeBadges;
          this.allAvailableBadges = response.data.allAvailableBadges;
          this.earnedBadges = response.data.earnedBadges;
        }
        this.spinner.hide()
      },
      error => {
        this.spinner.hide()
      }
    )
  }

  activeInactiveBadge(badgeId, status){
    this.spinner.show()
    status = !status 
    this.userService.activeBadgeByUser({
      userID : this.userId,
      badgeID : badgeId,
      status : status ? '1' : '0',
    }).subscribe(
      response => {
        if (!response.error) {
          this.spinner.hide()
          this.getAllBadgesForUser();
        }
      },
      error => {

      }
    )
  }

  buy(item){
    localStorage.setItem('badge', JSON.stringify(item))
  }

}
