import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dash-link-connections',
  templateUrl: './dash-link-connections.component.html',
  styleUrls: ['./dash-link-connections.component.css']
})
export class DashLinkConnectionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
