import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService, GlobalService, UserService } from '../../_services';
import { NgxSpinnerService } from "ngx-spinner";
import { PasswordValidator } from '../../_validators/password.validator';

import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-retrive-password',
  templateUrl: './retrive-password.component.html',
  styleUrls: ['./retrive-password.component.css']
})
export class RetrivePasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  loading = false;
  submitted = false;
  token: any;
  error: any;


  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private globalService: GlobalService,
    private spinner: NgxSpinnerService,
    private userService: UserService,
  ) { }
  get f() { return this.resetPasswordForm.controls; }
  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.get('password').value;
    let cnfPassword = group.get('cnfPassword').value;

    return pass === cnfPassword ? null : { notSame: true }
  }
  ngOnInit() {

    this.resetPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required,  PasswordValidator.strong]],
      cnfPassword: ['',[Validators.required,  PasswordValidator.strong]],

    }, { validator: this.checkPasswords });


    this.spinner.show();
    this.route.paramMap.subscribe(params => {
      this.token = params.get('token');


      this.userService.checkPasswordRecoveryToken({ token: this.token }).subscribe(
        response => {
          if (response.error) {
            this.spinner.hide();
            this.router.navigate(["forgot-password"]);
            this.toastr.error(response.message, 'Error!', {
              timeOut: 5000,
              progressBar: true,
              closeButton: true
            });

            //Error
          } else {
            //Success



            this.spinner.hide();
          }
        },
        error => {
          this.spinner.hide();
          //Error
        }
      )
    });

  }

  onSubmit() {
    this.submitted = true;
    this.spinner.show();
    // stop here if form is invalid
    if (this.resetPasswordForm.invalid) {
      console.log('error', this.resetPasswordForm);
      this.spinner.hide();
      return;
    }

    this.route.paramMap.subscribe(params => {
      this.token = params.get('token');


      this.userService.updateUserPasswordRecovery({ token: this.token, newpassword: this.f.password.value }).subscribe(
        response => {
          if (response.error) {
            this.spinner.hide();
            this.router.navigate(["forgot-password"]);
            this.toastr.error(response.message, 'Error!', {
              timeOut: 5000,
              progressBar: true,
              closeButton: true
            });

            //Error
          } else {
            //Success
            this.router.navigate(["login-mobile"]);
            this.toastr.success(response.message, 'Success!', {
              timeOut: 2000,
              progressBar: true,
              closeButton: true
            });

            this.spinner.hide();
          }
        },
        error => {
          this.spinner.hide();
          //Error
        }
      )
    });




  }

}
