import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService, UserService } from '../../_services';
import * as moment from 'moment'; 

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss']
})

export class GlobalSearchComponent implements OnInit {
isDashboardMode = true
title = 'Search Result'
color = 'my-badge-head-bg'
searchValue:any;
searchResultData:any;
moment: any = moment;
userData: any;
boradcustData: any;
userDataFinal:any;
boradcustDataFinal:any;
  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.spinner.show();
   this.route.paramMap.subscribe(params => { 
      this.searchValue = params.get('name');   
      
      this.userService.globalSearch({keywored : this.searchValue}).subscribe(
        response => {
          if (response.error) {
            this.spinner.hide();
            //Error
          } else {
            //Success
            this.searchResultData = response.data;

            if(this.searchResultData && this.searchResultData.USER){
              this.userData = this.searchResultData.USER;
              this.userDataFinal = this.searchResultData.USER.data
            }
            else{
              this.userData = [];
              this.userDataFinal =[];
            }

            if(this.searchResultData && this.searchResultData.BROADCAST){
             this.boradcustData = this.searchResultData.BROADCAST;
             this.boradcustDataFinal = this.searchResultData.BROADCAST.data;
            }
            else{
              this.boradcustData = [];
              this.boradcustDataFinal =[];
            }



            this.spinner.hide();
          }
        },
        error => {
          this.spinner.hide();
          //Error
        }
      )
  });
  }
  // filterSearchResultData(){
  //   return this.searchResultData && this.searchResultData.filter(x => x.TYPE == "USER");

  // }
  // filterSearchResultDataBrodCust(){
  // return this.searchResultData && this.searchResultData.filter(x => x.TYPE == "BROADCAST");

  // }
}
