import { Component, OnInit, Renderer2, Inject, HostListener } from '@angular/core';
import { DOCUMENT, Location, LocationStrategy } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { NgxAgoraService, Stream, AgoraClient, ClientEvent, StreamEvent } from 'ngx-agora';
import { BroadcastService } from 'src/app/_services/broadcast.service';
import { Observable, interval } from 'rxjs';
import Swal from 'sweetalert2';
import { UserService, AuthenticationService } from 'src/app/_services';
import AgoraRTM from 'agora-rtm-sdk';
import { environment } from 'src/environments/environment';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VotePollsModalComponent } from '../modal/vote-polls-modal/vote-polls-modal.component';
import { GiftModalComponent } from '../modal/gift-modal/gift-modal.component';
import { BlockUserModalComponent } from '../modal/block-user-modal/block-user-modal.component';

@Component({
  selector: 'app-view-live-brodcast',
  templateUrl: './view-live-brodcast.component.html',
  styleUrls: ['./view-live-brodcast.component.scss']
})
export class ViewLiveBrodcastComponent implements OnInit {
  private client: AgoraClient;
  remoteCalls: string[] = [];
  hostName: string[] = [];
  hostAvtar: string[] = [];
  hostLevel : string = '';
  sub: any;
  broadcastId: string;
  broadcastTitle: string;
  broadcasterName: string;
  broadCustUserId: string;
  broadcasterAvtar: string;
  geolocation: any;
  user: any;
  userId: any;
  token: any;
  coHostName: any;
  coHostAvtar: any;
  hostNames: any;
  hostAvtars: any;
  startOn: any;
  isLiked = true;
  isFollowed=false;
  href: string;
  shareCount = 0;
  agoraRTM: any = AgoraRTM.createInstance(environment.agora.appId);
  msgText = '';
  RtmToken: string;
  rtmChatChannel: any;
  messages = [];
  emoji = false;
  muteChat = true;
  ChatUser: any;
  broadcastLogo: any;
  broadcastLogoPosition: any;
  broadcastLogoClass: string;
  audioMode: string = '0';
  load = false;
  broadcastType: any;
  broadcasterId: any;
  maxAllowedSlots: any;
  totalGiftRecvd: any;
  totalLikeFormated: any;
  totalFansFormated: any;
  totalFollowersFormated: any;
  totalViewer: any;
  totalGiftRecvdDiamondsFormated: any;
  isShown: boolean = false ;
  chat: boolean = false ;
  alertMsg: any;
  theme: string;
  section: string = 'golive';
  slots: any =[];
  giftReciveddata : any=[];
  testInterval:any;
  
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private ngxAgoraService: NgxAgoraService,
    private broadcastService: BroadcastService,
    private _location: Location,
    private location: LocationStrategy,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
  ) {
    const Swal = require('sweetalert2');
    // history.pushState(null, null, window.location.href);
    // this.location.onPopState(() => {
    //   history.pushState(null, null, window.location.href);
    //   this.leaveChannel();
    // });

    router.events.subscribe((val) => {
      // see also
      if (this.load) {
        this.client.leave(() => {
          // this.broadcastService.leaveChannelBroadcastByUser({
          //   userID : this.userId,
          //   broadcastID : this.broadcastId
          // }).subscribe(
          //   respose => {
          //     if (!respose.error) {
          //       // this.leaveChannel();
          //       //this.rtmChatChannel.leave()
          //       //this.agoraRTM.logout()
          //     }
          //   },
          //   error => {

          //   }
          // )

        }, (err) => {
          // console.log('client leave failed ', err); //error handling
        });
      }
      if (val instanceof NavigationEnd) {
        this.load = true;
      }

    });
  }


  ngOnInit() {
    
    switch (localStorage.getItem('theme')) {
      case '1':
        this.theme = ''
        break;
      case '2':
        this.theme = '-green'
        break;
      case '3':
        this.theme = '-orange'
        break;
    
      default:
        break;
    }
    
    this.href = location.origin + this.router.url;

    this.client = this.ngxAgoraService.createClient({ mode: 'live', codec: 'h264' });
    this.assignClientHandlers();
    this.client.setClientRole('audience', function(e) {
      if (!e) {
        // console.log("setHost audience success");
      } else {
        // console.log("setHost error", e);
      }
    });
    this.sub = this.route.paramMap.subscribe(params => {
      this.broadcastId = params.get('id');
      this.geolocation = JSON.parse(localStorage.getItem('geolocation'));
      const kick = JSON.parse(localStorage.getItem('kick'));
      // console.log("kick details", kick)
      if (kick && kick.broadcastId == this.broadcastId) {
        this.toastr.info(`You don't have permission to watch this broadcast`, 'Kickout!!', {
          timeOut: 2000,
          progressBar: true,
          closeButton: true
        });
        this._location.back();
      }
      if (!this.geolocation) {
        this.toastr.info('Please allow location access all the time', 'Location access', {
          timeOut: 2000,
          progressBar: true,
          closeButton: true
        });
        this._location.back();
      }
      this.user = JSON.parse(localStorage.getItem('currentUser'));

      if (this.user) {
        this.userId = parseInt(this.user.profile.FKuserID, 10);
      } else {
        this.userId = 0;
      }

      this.broadcastService.getBroadcastDetail({
        userID: this.userId,
        broadcastID: this.broadcastId
      }).subscribe(
        response => {
          const statBroadcastDetails = response.data.broadcastDetails.stat;
          this.totalGiftRecvd = statBroadcastDetails.totalGiftRecvd;
          this.totalLikeFormated = statBroadcastDetails.totalLikeFormated;
          this.totalFansFormated = statBroadcastDetails.totalFansFormated;
          this.totalFollowersFormated = statBroadcastDetails.totalFollowersFormated;
          this.totalViewer = statBroadcastDetails.totalViewer;
          this.totalGiftRecvdDiamondsFormated = statBroadcastDetails.totalGiftRecvdDiamondsFormated;
          console.log('getBroadcastDetail stat', response.data.broadcastDetails.stat);
        }
      );

      this.broadcastService.getChannelTokenToJoinABroadcast({
        userID: this.userId,
        broadcastID: this.broadcastId,
        lat: this.geolocation.latitude,
        lng: this.geolocation.longitude
      }).subscribe(
        response => {
          if (response.error) {
            this.toastr.error('Something went wrong', 'Error!', {
              timeOut: 2000,
              progressBar: true,
              closeButton: true
            });
            this._location.back();
          }
          // Set Brodcast Data
          this.broadcastLogo  = response.data.broadcastDetails.logos.length > 0 ? response.data.broadcastDetails.logos[0].logo : '';
          // tslint:disable-next-line: max-line-length
          this.broadcastLogoPosition  = response.data.broadcastDetails.logos.length > 0 ? response.data.broadcastDetails.logos[0].position : '';
          if (this.broadcastLogoPosition == '2') {
            this.broadcastLogoClass = 'top-left';
          }
          if (this.broadcastLogoPosition == '1') {
            this.broadcastLogoClass = 'top-right';
          }
          if (this.broadcastLogoPosition == '3') {
            this.broadcastLogoClass = 'bottom-right';
          }
          if (this.broadcastLogoPosition == '4') {
            this.broadcastLogoClass = 'bottom-left';
          }

          this.broadcastType  = response.data.broadcastDetails.FKbroadcatTypeID;
          this.maxAllowedSlots  = response.data.broadcastDetails.maxAllowedSlots;
          console.log('maxAllowedSlots', this.maxAllowedSlots)
          for (let i = 0; i < parseInt(this.maxAllowedSlots)-1; i++) {
            console.log ("Block statement execution no." + i);
            this.slots.push(i)
          }
          this.broadcastTitle  = response.data.broadcastDetails.title;
          this.audioMode  = response.data.broadcastDetails.isAudioOnly;
          var now = new Date(response.data.broadcastDetails.startOn);
          now.setMinutes(now.getMinutes()-30);
          localStorage.setItem("start_time", now.toISOString())
        
          this.startOn  = now.toISOString();
         // this.startOn  = response.data.broadcastDetails.startOn;
          this.broadcasterId  = response.data.broadcastDetails.broadcasterDetails.FKuserID;
          this.broadcasterName  = response.data.broadcastDetails.broadcasterDetails.username;
          this.broadcasterAvtar  = response.data.broadcastDetails.broadcasterDetails.Avtar;
          this.broadCustUserId = response.data.broadcastDetails.FKuserID;
          this.isFollowed = response.data.broadcastDetails.broadcasterDetails.isFollow;
          this.token  = response.data.token;
          const channel = response.data.broadcastPhysicalChannel;

          this.client.join(this.token, channel, this.userId, function(uid) {
            // console.log('User ' + uid + ' join channel successfully');
          }, function(err) {
              // console.log('[ERROR] : join channel failed', err);
          });
          this.RtmToken  = response.data.tokenForChat;
          this.ChatUser  = response.data.ChatUser;
          //// console.log("RtmToken view", this.RtmToken);
          // tslint:disable-next-line: max-line-length
          // this.RtmToken  = '00645a50897856242ea86e9ad68c74e2cd2IABYuYOW7ph4dvGvW1tmjzf2TJxTIpOWoyPMKiLxa923kCJ2cIoAAAAAEACPawAA27cVXgEA6ANrdBRe'
          this.agoraRTM.login({ token: this.RtmToken, uid: this.ChatUser }).then(() => {
            // console.log('AgoraRTM client login success');
            this.rtmChatChannel = this.agoraRTM.createChannel(channel);

            this.agoraRTM.setLocalUserAttributes(
              {
                name : this.user.profile.username,
                img : this.user.profile.Avtar ? this.user.profile.Avtar : 'assets/images/profile-icon.png',
              });

            const mute = JSON.parse(localStorage.getItem('mute'));
            // console.log("Mute details", mute)
            if (mute && mute.broadcastId == this.broadcastId) {
                this.muteChat = true;
              } else {
                this.muteChat = false;
              }
             // console.log("muteChat", this.muteChat)
            this.joinRtmChatChannel();
          }).catch(err => {
            // console.log('AgoraRTM client login failure', err);
          });

        },
        error => {
          // console.log(error);
        }
      );
    });
    this.testInterval = setInterval(() => {
      this.getBroadcastActiveViwerList();

    }, 10000);
    
  }

  ngOnDestroy() {
    if ( this.testInterval) {
      clearInterval( this.testInterval);
    }
  }
  getBroadcastActiveViwerList() {
    this.broadcastService.getBroadcastActiveViwerList({
      "broadcastID": this.broadcastId
    }).subscribe(
      responseData => {

        console.log("===view broadcust details===", responseData.data);
        this.giftReciveddata = responseData.data;

      },
      errorData => {

      }
    )
  }
  joinRtmChatChannel() {
    this.rtmChatChannel.join().then(() => {
        /* Your code for handling the event of a join-channel success. */
        // console.log('Channel joined');
        this.msgText = 'Joined the Broadcast';
        this.sendMessage();
        this.subscribeChannelEvents();
      }).catch(error => {
        /* Your code for handling the event of a join-channel failure. */
        // console.log('Channel join failed');
      });
  }
  openMsgSection(){
    this.chat = !this.chat
  }
  
  AddHeadToHeadJoinRequest(){
     let req = {
      userID : this.userId,
      broadcastID : this.broadcastId,
      toUserID : this.broadcasterId
     }
     this.spinner.show()
     this.broadcastService.addHeadToHeadJoinRequest(req).subscribe(
       response => {
        console.log(response)
        if (!response.error) {
          //Success
          this.toastr.success('H2H Request sent successfully', 'Success!', {
            timeOut: 2000,
            progressBar: true,
            closeButton: true
          });
        } else {
          //Error
          this.toastr.error('H2H Request already sent', 'Error!', {
            timeOut: 2000,
            progressBar: true,
            closeButton: true
          });
        }
        this.spinner.hide()
       },
       error => {
        this.spinner.hide()
       }
     )
  }

  addGuestAppearanceJoinRequest(){
     let req = {
      userID : this.userId,
      broadcastID : this.broadcastId,
      toUserID : this.broadcasterId
     }
     this.spinner.show()
     this.broadcastService.addGuestAppearanceJoinRequest(req).subscribe(
       response => {
        console.log(response)
        if (!response.error) {
          //Success
          this.toastr.success('Guest Request sent successfully', 'Success!', {
            timeOut: 2000,
            progressBar: true,
            closeButton: true
          });
        } else {
          //Error
          this.toastr.error('Guest Request already sent', 'Error!', {
            timeOut: 2000,
            progressBar: true,
            closeButton: true
          });
        }
        this.spinner.hide()
       },
       error => {
        this.spinner.hide()
       }
     )
  }

  addMulticastJoinRequest(){
     let req = {
      userID : this.userId,
      broadcastID : this.broadcastId,
      toUserID : this.broadcasterId
     }
     this.spinner.show()
     this.broadcastService.addMulticastJoinRequest(req).subscribe(
       response => {
        console.log(response)
        if (!response.error) {
          //Success
          this.toastr.success('Multicast Join Request sent successfully', 'Success!', {
            timeOut: 2000,
            progressBar: true,
            closeButton: true
          });
        } else {
          //Error
          this.toastr.error('Multicast Join Request already sent', 'Error!', {
            timeOut: 2000,
            progressBar: true,
            closeButton: true
          });
        }
        this.spinner.hide()
       },
       error => {
        this.spinner.hide()
       }
     )
  }

  sendMessage() {
    if (this.muteChat) {
      return this.toastr.info('Your chat is muted for this broadcast', 'Info!', {
        timeOut: 2000,
        progressBar: true,
        closeButton: true
      });
    }
    if (this.msgText) {
      const encoder = new TextEncoder();
      const rawMessage = encoder.encode(JSON.stringify({
        msgDate : '',
        msg : this.msgText,
        msgType : 'chat',
        broadcastId : this.broadcastId.toString(),
        broadcaster_fkUserId : this.broadcasterId.toString(),
        broadcaster_avtar : this.broadcasterAvtar,
        broadcaster_firstName : this.broadcasterName,
        broadcaster_lastName : '',
        broadcaster_location : '',
        broadcaster_userName : this.broadcasterName,
        fkUserId : this.userId.toString(),
        userName : this.user.profile.ChatUser,
        firstName : this.user.profile.ChatUser,
        lastName : '',
        location : '',
        avtar : this.user.profile.Avtar ? this.user.profile.Avtar : 'assets/images/profile-icon.png',
      }));
      this.rtmChatChannel.sendMessage({ rawMessage,  messageType: 'RAW', description: '' }).then(() => {
          /* Your code for handling events, such as a channel message-send success. */
          this.agoraRTM.getUserAttributes(this.ChatUser).then(sender => {
            this.messages.push({
              text: this.msgText,
              senderName : sender.name,
              senderImg : sender.img
            });
            if (this.messages.length >= 5) {
              this.messages.shift();
              this.getBroadcastActiveViwerList();
            }
            this.msgText = '';
          });
        }).catch(error => {
          /* Your code for handling events, such as a channel message-send failure. */
          // console.log('sendMessage failed');
          this.msgText = '';
      });
    }
  }
  addEmoji(event) {
    this.msgText = this.msgText + '' + event.emoji.native;
    this.emoji = false;
  }
  private subscribeChannelEvents(): void {
    this.rtmChatChannel.on('ChannelMessage', (message, senderId) => { // text: text of the received channel message; senderId: user ID of the sender.
      /* Your code for handling events, such as receiving a channel message. */
      const enc = new TextDecoder('utf-8');
      console.log("text recived", JSON.parse(enc.decode(message.rawMessage)))
      const senderMessage = JSON.parse(enc.decode(message.rawMessage));
      this.getBroadcastActiveViwerList();
      if (senderMessage.msgType == 'chat') {
        if (this.messages.length >= 5) {
          this.messages.shift();
          this.getBroadcastActiveViwerList();
        }
        this.agoraRTM.getUserAttributes(senderId).then(sender => {
          // console.log("getUserAttributes", sender)
          this.messages.push({
            text: senderMessage.msg,
            senderName : senderMessage.userName,
            senderImg : senderMessage.avtar
          });
        });
      }
      if (senderMessage.msgType == 'muteInBroadcast') {
        if (this.userId == senderMessage.fkUserId) {
          const msg = 'You have been muted by ' + senderMessage.broadcaster_firstName;
          this.toastr.info(msg, 'Info!', {
            timeOut: 2000,
            progressBar: true,
            closeButton: true
          });
          localStorage.setItem('mute', JSON.stringify(senderMessage));
          this.muteChat = true;
        }
      }
      if (senderMessage.msgType == 'kickOutFromBroadcast') {
        if (this.userId == senderMessage.fkUserId) {
          console.log('kickOutFromBroadcast', senderMessage);
          localStorage.setItem('kick', JSON.stringify(senderMessage));
          this.leaveChannel();
        }
      }
      if (senderMessage.msgType == 'coinDropMsg') {
        this.alertMsg = senderMessage.msg
        this.toggleShow()
        setTimeout(() => {
          this.toggleShow()
        }, 5000);
      }
    });
  }
  toggleShow() {
    this.isShown = ! this.isShown;
  }
  private assignClientHandlers(): void {
    this.client.on(ClientEvent.LocalStreamPublished, evt => {
      // console.log('Publish local stream successfully');
    });

    this.client.on(ClientEvent.PeerLeave, evt => {
      // console.log("Remote stream has left the channel: " + evt.uid);
      evt.stream.stop(); // stop the stream
      this.toastr.info('Host left the channel', 'Info!', {
        timeOut: 2000,
        progressBar: true,
        closeButton: true
      });
      this._location.back();
    });

    this.client.on(ClientEvent.Error, error => {
      // console.log('Got error msg:', error.reason);
      if (error.reason === 'DYNAMIC_KEY_TIMEOUT') {
        this.client.renewChannelKey(
          '',
          () => // console.log('Renewed the channel key successfully.'),
          renewError => console.error('Renew channel key failed: ', renewError)
        );
      } else {
        // console.log("My Error", error.reason)
      }
    });

    this.client.on(ClientEvent.RemoteStreamAdded, evt => {
      // console.log("RemoteStreamAdded on channel")
      const stream = evt.stream as Stream;
      this.client.subscribe(stream, { audio: true, video: true }, err => {
        // console.log('Subscribe stream failed', err);
      });
    });

    this.client.on(ClientEvent.RemoteStreamSubscribed, evt => {
      const stream = evt.stream as Stream;
      // console.log("My stream", stream);
      if (!stream) {
        this.toastr.info('Broadcast Not strated or stopped', 'Info!', {
          timeOut: 2000,
          progressBar: true,
          closeButton: true
        });
        // this.leaveChannel();
        this._location.back();
      }
      const id = this.getRemoteId(stream);
      // console.log("remoteCalls", this.remoteCalls)
      // if (!this.remoteCalls.length) {
      this.userService.getUserDetails({id : evt.stream.streamId}).subscribe(
          response => {
            if (!response.error) {
              this.hostName.push(response.data.profile.username);
              this.hostAvtar.push(response.data.profile.Avtar);
              this.hostLevel = response.data.profile && response.data.profile.Stat && response.data.profile.Stat.userLevel ? 'Level '+response.data.profile.Stat.userLevel : '';
            }
          }
        );
      this.remoteCalls.push(id);
      setTimeout(() => stream.play(id), 1000);
      // }
    });

    // this.client.on(ClientEvent.RemoteStreamRemoved, evt => {
    //   const stream = evt.stream as Stream;
    //   if (stream) {
    //     stream.stop();
    //     this.remoteCalls = [];
    //     //console.log(`Remote stream is removed ${stream.getId()}`);
    //   }
    // });

    // this.client.on(ClientEvent.PeerLeave, evt => {
    //   const stream = evt.stream as Stream;
    //   if (stream) {
    //     stream.stop();
    //     this.remoteCalls = this.remoteCalls.filter(call => call !== `${this.getRemoteId(stream)}`);
    //     //console.log(`${evt.uid} left from this channel`);
    //   }
    // });
  }
  leaveChannel() {
    this.msgText = 'left the Broadcast 😔';
    this.sendMessage();
    this.client.leave(() => {
      // console.log('client leaves channel');
      this.broadcastService.leaveChannelBroadcastByUser({
        userID : this.userId,
        broadcastID : this.broadcastId
      }).subscribe(
        respose => {
          // console.log("leaveChannelBroadcastByUser", respose);
          if (!respose.error) {
            // this.leaveChannel();
            // this.rtmChatChannel.leave()
            // this.agoraRTM.logout()

            this.backClicked();
          }
        },
        error => {

        }
      );

    }, (err) => {
      // console.log('client leave failed ', err); //error handling
    });
  }
  private getRemoteId(stream: Stream): string {
    return `agora_remote-${stream.getId()}`;
  }
  follow() {
    const currentUser: any = this.authenticationService.currentUserValue;

    let currentUserId = currentUser.profile.FKuserID;
    const request = {
      userID: this.broadCustUserId,
      followedBY: currentUserId,
      status: this.isFollowed ? 0 : 1
    }
    if (this.isFollowed) {
      this.userService.unFollowUser(request).subscribe(
        response => {
          if (response.error) {
            //Error
          } else {
            //Success
            this.isFollowed = false;
           
          }
        },
        error => {

        }
      )
    } else {
      this.userService.followUser(request).subscribe(
        response => {
          if (response.error) {
            //Error
          } else {
            //Success
            this.isFollowed = true;
           
          }
        },
        error => {

        }
      )
    }

  }

  like() {
    if (this.isLiked) {
      this.isLiked = false;
    } else {
      this.isLiked = true;
    }
    this.msgText = 'Liked the Broadcast 👍❤️';
    this.sendMessage();
    this.broadcastService.likeBroadcast({
      likeBY : this.userId,
      broadcastID: this.broadcastId,
      status: this.isLiked ? 0 : 1
    }).subscribe();
  }

  backClicked() {
    this._location.back();
  }
  shareDone(e) {
    // console.log("Shared on", e)
    const currentUser: any = this.authenticationService.currentUserValue;
    this.userService.shareBroadcast({broadcastID: this.broadcastId, shareBY: currentUser.profile.FKuserID}).subscribe(
      response => {
        if (!response.error) {
          this.msgText = 'Shared the Broadcast 🤩🤩';
          this.sendMessage();
         this.shareCount++;
        }
       // this.shareCount++
      },
      error => {}
    );
   }
   catchCoin() {
     this.spinner.show();
     this.broadcastService.collectCoin({
      userID : this.userId,
      broadcastID : this.broadcastId,
     }).subscribe(
       response => {
        if (response.data) {
          this.msgText = response.message + '🤑🤑🤑';
          this.sendMessage();
        }
        
        this.toastr.info(response.message, 'Info!', {
          timeOut: 2000,
          progressBar: true,
          closeButton: true
        });
        this.spinner.hide();
       },
       error => {
        this.spinner.hide();
       }
     );
   }
   openVotePollsModal() {
    const modalRef = this.modalService.open(VotePollsModalComponent);
    modalRef.componentInstance.userId = this.broadcasterId;
    modalRef.componentInstance.broadcastID = this.broadcastId;
    modalRef.componentInstance.pollAns.subscribe(($e) => {
      this.msgText = 'Participated in poll 😍';
      this.sendMessage();
    });
  }
   openGiftModal() {
    const modalRef = this.modalService.open(GiftModalComponent);
    modalRef.componentInstance.broadcasterId = this.broadcasterId;
    modalRef.componentInstance.broadcastID = this.broadcastId;
    modalRef.componentInstance.giftSend.subscribe(($e) => {
      this.msgText = 'Sended a Gift 😍';
      this.sendMessage();
    });
  }
  blockModal(senderName, senderDetails) {
    // console.log('senderDetails', senderDetails);
    // const modalRef = this.modalService.open(BlockUserModalComponent);
    // modalRef.componentInstance.userId = this.uid;
    // modalRef.componentInstance.senderName = senderName;
    // modalRef.componentInstance.broadcastID = this.broadcastId;
    // modalRef.componentInstance.kickUser.subscribe(($e) => {
    //   console.log('Kick User', $e.senderName);
    //   this.kickUser($e.senderName, senderDetails.sender);
    // });
    // modalRef.componentInstance.muteUser.subscribe(($e) => {
    //   console.log('Mute User', $e.senderName);
    //   this.muteUser($e.senderName, senderDetails.sender);
    // });
  }
  kickUser(user, senderDetails) {
    senderDetails.msgType = 'kickOutFromBroadcast';
    senderDetails.muted_fkUserId = senderDetails.fkUserId;
    this.broadcastService.removeViewer({
      broadcastID: this.broadcastId,
      userID: senderDetails.fkUserId,
    }).subscribe(
      response => {

      },
      error => {

      }
    );
    console.log('Kick User Details', senderDetails);
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    const encoder = new TextEncoder();

    const rawMessage = encoder.encode(JSON.stringify(senderDetails));

    this.rtmChatChannel.sendMessage({ rawMessage,  messageType: 'RAW', description: '' }).then(() => {
      console.log('sendMessage success');
    }).catch(error => {
      /* Your code for handling events, such as a channel message-send failure. */
      console.log('sendMessage failed');
    });
  }
  muteUser(user, senderDetails) {
    senderDetails.msgType = 'muteInBroadcast';
    senderDetails.muted_fkUserId = senderDetails.fkUserId;
    this.broadcastService.muteViewer({
      broadcastID: this.broadcastId,
      userID: senderDetails.fkUserId,
      status: 1,
    }).subscribe(
      response => {

      },
      error => {

      }
    );
    console.log('Mute User Details', senderDetails);
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    const encoder = new TextEncoder();

    const rawMessage = encoder.encode(JSON.stringify(senderDetails));

    this.rtmChatChannel.sendMessage({ rawMessage,  messageType: 'RAW', description: '' }).then(() => {
      console.log('sendMessage success');
    }).catch(error => {
      /* Your code for handling events, such as a channel message-send failure. */
      console.log('sendMessage failed');
    });
  }

}
