import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '../../_services';
import { User } from '../../_models';
import { NgxSpinnerService } from "ngx-spinner";
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { H2hrequestComponent } from '../../_modal/h2hrequest/h2hrequest.component';
import { BroadcastService } from '../../_services/broadcast.service';

import { MessagingService } from '../../service/messaging.service';
import { interval } from 'rxjs/internal/observable/interval';
import { NotificationModalComponent } from './notification-modal/notification-modal.component';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
declare const Pusher: any;
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  @Input('theme') theme: any;
  @Input('section') section: any;

  modalOptions: NgbModalOptions = {
    centered: true,
    scrollable: true,
    size: 'lg',
  };
  user: any;
  userId: any;
  geolocation: any;

  newNotification = '';
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private spinner: NgxSpinnerService,
    public toastr: ToastrService,
    private modalService: NgbModal,
    private broadcastService: BroadcastService,
    private messagingService: MessagingService
  ) {
    Pusher.logToConsole = true;
    
    var pusher = new Pusher('264ce99a5c9f90460a8b', {
      cluster: 'ap2'
    });
    this.user = JSON.parse(localStorage.getItem('currentUser'))
    this.userId = this.user.profile.FKuserID
    //this.newNotification = 'active'
    var channel = pusher.subscribe('ryzeLivePush');
    channel.bind('notification-'+this.userId, (data) => {
      this.processNotification(data)
    });

   }

  ngOnInit() {
  }

  processNotification(notification){
    this.newNotification = 'active'
    this.toastr.info(notification.title, notification.message);
    console.log(notification, 'Notification')
    if(notification.type == 'acceptmulticastinvitationTojoin'){
        if(notification.type_data.toid == this.userId) {
          this.redirectAfterAcceptJoinRequest(notification);
        }
      
    }
    // if(notification.type == 'H2Haccept'){
    //     if(notification.type_data.toid == this.userId) {
    //       this.redirectAfterAcceptH2HRequest(notification);
    //     }
    // }
  }

  openNotificationModal(){
    this.newNotification = ''
    const modalRef = this.modalService.open(NotificationModalComponent, this.modalOptions);
  }

  redirectAfterAcceptJoinRequest(notification){
      this.geolocation = JSON.parse(localStorage.getItem('geolocation'))
      this.broadcastService.getBroadcastDetail({
        "userID":notification.type_data.toid, 
        "broadcastID":notification.type_id, 
        "lat":this.geolocation.latitude, 
        "lng":this.geolocation.longitude
      }).subscribe(
        response => {
          if (response.error) {
            return this.toastr.error("Something went wrong", 'Error!', {
              timeOut: 2000,
              progressBar: true,
              closeButton: true
            });
            
          }
          if(response.data.broadcastDetails){
            let broadcastDetails = response.data.broadcastDetails
            let url = 'go-live/multi-broadcast/multibeam'+'/'+notification.type_data.toid+'/'+broadcastDetails.broadcastPhysicalChannel+'/'+notification.type_id+'/'+broadcastDetails.isAudioOnly+'/'+broadcastDetails.maxAllowedSlots
            return this.router.navigate([url]);
          }
      },
      error => {
        console.log(error);
      }
  )

  }
  redirectAfterAcceptH2HRequest(notification){
    this.geolocation = JSON.parse(localStorage.getItem('geolocation'))
    this.broadcastService.getBroadcastDetail({
      "userID":notification.type_data.toid, 
      "broadcastID":notification.type_id, 
      "lat":this.geolocation.latitude, 
      "lng":this.geolocation.longitude
    }).subscribe(
      response => {
        if (response.error) {
          return this.toastr.error("Something went wrong", 'Error!', {
            timeOut: 2000,
            progressBar: true,
            closeButton: true
          });
          
        }
        if(response.data.broadcastDetails){
          let broadcastDetails = response.data.broadcastDetails
          let url = 'go-live/head-to-head/standard/' + notification.type_data.toid + '/' + broadcastDetails.broadcastPhysicalChannel + '/' + broadcastDetails.id + '/' + broadcastDetails.isAudioOnly;
          return this.router.navigate([url]);
        }
    },
    error => {
      console.log(error);
    }
)

  }

}
