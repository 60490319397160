import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { BroadcastService } from 'src/app/_services/broadcast.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { interval } from 'rxjs';
import { BuyStoreCreditComponent } from './modal/buy-store-credit/buy-store-credit.component';
import { ViewVideoComponent } from './modal/view-video/view-video.component';
import * as moment from 'moment';
import { AuthenticationService } from 'src/app/_services';
import { RechargeService } from 'src/app/_services/recharge.service';

@Component({
  selector: 'app-user-settings-recordings',
  templateUrl: './user-settings-recordings.component.html',
  styleUrls: ['./user-settings-recordings.component.css']
})
export class UserSettingsRecordingsComponent implements OnInit {
  isDashboardMode = true;
  title = 'My Recordings';
  color = 'my-recording-head-bg';
  user: any;
  videos = [];
  moment: any = moment;
  coins: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private broadcastService: BroadcastService,
    private renderer: Renderer2,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private rechargeService: RechargeService,
    private authenticationService: AuthenticationService,
  ) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.getVideos();
  }
  setProfile(videoId, status){
    this.spinner.show();
    console.log("Status", videoId, status)
    this.broadcastService.activeUserVideoForProfile({
      userID: this.user.profile.FKuserID,
      videoID: videoId,
      status: status == '0' ? 1 : 0,
    }).subscribe(
       response => {
        this.spinner.hide();
        this.getVideos();
       },
       error => {

       }
    )
  }
  deleteUserVideo(videoId){
    this.spinner.show();
    this.broadcastService.deleteUserVideo({
      userID: this.user.profile.FKuserID,
      videoID: videoId
    }).subscribe(
       response => {
        this.spinner.hide();
        this.getVideos();
       },
       error => {

       }
    )
  }

  goToStoreCreditRecharge(){
    this.router.navigate(['store-credit-recharge'])
  }
  getVideos() {
    this.userStat()
    this.spinner.show();
    console.log(this.user);
    this.broadcastService.getAllVideosForUser({
      userID: this.user.profile.FKuserID
    }).subscribe(
      response => {
        this.videos = response.data;
        this.spinner.hide();
        console.log('getAllVideosForUser', response);
      },
      error => {

      }
    );
  }

  saveBroadcast(videoId, credit) {
    this.spinner.show();
    console.log('videoId', videoId);
    this.broadcastService.saveBroadcastData({
      id: videoId
    }).subscribe(
      response => {
         if (response.error) {
          console.log('Modal open');
          const modalRef = this.modalService.open(BuyStoreCreditComponent);
          modalRef.componentInstance.videoId = videoId;
          modalRef.componentInstance.msg = response.message;
          modalRef.componentInstance.credit = credit;
          modalRef.componentInstance.coinPurchased.subscribe(($e) => {
             this.saveBroadcast(videoId, credit);
          });
         } else {
          this.getVideos();
         }
         this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    );
  }

  view(videoId, url){   
    this.spinner.show();
    this.broadcastService.viewBroadcastData({
      id: videoId
    }).subscribe(
      response => {
         if (response.error) {
          this.toastr.info("Your video upload is in process, Please wait for sometime.", 'View Video', {
            timeOut: 2000,
            progressBar: true,
            closeButton: true
          });
         } else {
          const modalRef = this.modalService.open(ViewVideoComponent);
          modalRef.componentInstance.url = url;
         }
         this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    );
  }

  round(num) {
    return Math.ceil(num);
  }
  userStat(){
    this.spinner.show();
    let currentUser: any = this.authenticationService.currentUserValue;

    const requestData = {
      userID: currentUser.profile.FKuserID,
    }
    this.rechargeService.getUserStat(requestData).subscribe(
      response => {
        this.coins = response.data.totalStoreCredit
        this.spinner.hide();
      },
      error => {

      }
    )
  }
}
