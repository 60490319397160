import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, GlobalService, UploadFileService, UserService, ChannelService } from '../../../../_services';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';


import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss']
})
export class LeaderboardComponent implements OnInit {
  totalItems: any = 0;
  pageSize: any = 10;
  currentPage: any = 1;
  p: number = 1;
  getAllLeaderBoardViewData:any;
  optionDate ='year';
  optionType = 'ALL';
  unit:any;
  lineUpType:any = '1';
  isDashboardMode = true
  title = 'Leaderboard'
  color = 'leaderboard_headerbg'
  addLineUp: FormGroup;

  constructor(
    private modalService: BsModalService,
    private channelService: ChannelService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private globalService: GlobalService,
    private userService: UserService,
    private spinner: NgxSpinnerService
  ) { }
  getLeaderBoardData(date,type){
    if(date == 'day'){
      var formdate =  moment().format('YYYY-MM-DD');
      var todate =  moment().format('YYYY-MM-DD');
    }
    else if(date == 'week'){
      var formdate = moment().startOf('week').format('YYYY-MM-DD');
      var todate   = moment().endOf('week').format('YYYY-MM-DD');
    }
    else if(date == 'month'){
      var formdate = moment().startOf('month').format('YYYY-MM-DD');
      var todate   = moment().endOf('month').format('YYYY-MM-DD');
    }
    else{
      var formdate = moment().startOf('year').format('YYYY-MM-DD');
      var todate   = moment().endOf('year').format('YYYY-MM-DD');
    }


    this.spinner.show();
    let sT =  (this.currentPage - 1) * this.pageSize;

 



    this.userService.getLeaderboardDetailsData({type : type,fromdate:formdate,todate:todate,start:sT,limit:'100'}).subscribe(
      response => {
       console.log(">>>>",response);
        if (!response.error) {
   
          this.totalItems = response.data.total;
          this.unit = response.data.unit;
    
          this.getAllLeaderBoardViewData = response.data.data;
          console.log("hello>>>",this.getAllLeaderBoardViewData);
          this.spinner.hide();
        }
        
      },
      error => {
        this.spinner.hide()
      }
    )

  }

  selectDate(){
    this.pageSize = 10;
    this.currentPage = 1;
    this.p = 1;
    this.optionDate = (document.getElementById('inputDate') as HTMLInputElement).value;
    if(this.optionDate!='custom'){
      this.getLeaderBoardData(this.optionDate,this.optionType);
    }
    
  }
  changeType(){
    this.pageSize = 10;
    this.currentPage = 1;
    this.p = 1;
    this.optionType = (document.getElementById('inputType') as HTMLInputElement).value;
    this.getLeaderBoardData(this.optionDate,this.optionType);
  }
  ngOnInit() {
    this.addLineUp = this.formBuilder.group({

      inputDate: ['day'],
      inputType: ['ALL'],
     
    });
    this.getLeaderBoardData('year','ALL');
  }

}
