import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { BroadcastService } from 'src/app/_services/broadcast.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

declare var $: any;
declare const Pusher: any;

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss']
})
export class NotificationModalComponent implements OnInit {
  
  notifications = [];
  currentUser: any;
  userId: any;
  moment: any = moment;
  url: any;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private router: Router,
    private broadcastService: BroadcastService,
    public spinner: NgxSpinnerService,
    public toastr: ToastrService,
  ) {

   }

  ngOnInit() {
    this.getNotifications()
  }

  getNotifications(){
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'))
    this.userId = this.currentUser.profile.FKuserID
    this.broadcastService.getNotifications({
      userID: this.userId
    }).subscribe(
      response => {
        this.notifications = response.data
      },
      error => {

      }
    )
  }

  deleteNotification(id){
    this.broadcastService.deleteNotifications({
      id : id
    }).subscribe(
      response => {
        this.getNotifications()
      },
      error => {

      }
    )
  }

  rejectRequest(notification){
    let type_data = JSON.parse(notification.type_data)
    this.getBroadcastDetailsAndProcess(notification.id, notification.type_id, type_data.toid, type_data.sendBY, type_data.reqID, notification.type, 'reject')
  }

  acceptRequest(notification){
    let type_data = JSON.parse(notification.type_data)
    this.getBroadcastDetailsAndProcess(notification.id, notification.type_id, type_data.toid, type_data.sendBY, type_data.reqID, notification.type, 'accept')
  }

  getBroadcastDetailsAndProcess(notificationId, broadcastId, reqSendToId, reqSendById,reqID, reqType, action){
    //console.log(broadcastId, reqSendToId, reqSendById)
    this.spinner.show()
    this.broadcastService.getBroadcastDetail({
      userID : reqSendToId,
      broadcastID : broadcastId
    }).subscribe(
      response => {
        this.spinner.hide()
        let broadcastDetails = response.data.broadcastDetails
        //console.log('broadcastDetails',broadcastDetails)
        let broadcastInfo = {
          notificationId : notificationId,
          broadcatTypeID : broadcastDetails.FKbroadcatTypeID,
          broadcastPhysicalChannel : broadcastDetails.broadcastPhysicalChannel,
          isAudioOnly : broadcastDetails.isAudioOnly,
          isLive : broadcastDetails.isLive,
          maxAllowedSlots : broadcastDetails.maxAllowedSlots,
          broadcastTitle : broadcastDetails.title,
          broadcastId : broadcastDetails.id,
          reqID : reqID,
          reqType : reqType,
          reqAction : action,
          reqSendById : reqSendById,
          reqSendToId : reqSendToId,
          broadcasterDetails : {
            id : broadcastDetails.broadcasterDetails.id,
            firstName : broadcastDetails.broadcasterDetails.firstName,
            lastName : broadcastDetails.broadcasterDetails.lastName,
            username : broadcastDetails.broadcasterDetails.username,
            gender : broadcastDetails.broadcasterDetails.GenderName,
            country : broadcastDetails.broadcasterDetails.CountryName,
          }
        }
        this.processReq(broadcastInfo);
      },
      error => {
        this.spinner.hide()
      }
    )
  }

  processReq(req){
    //console.log(req);
    if (req.isLive == '1') {
      //Process
      switch (req.reqType) {
        case 'guestInvitation':
          if (req.reqAction == 'accept') {
            //accept_guest_invite
            //console.log('accept_guest_invite', req)
            this.accept_guest_invite(req.notificationId, req.reqID, req.reqSendToId, req.broadcastPhysicalChannel, req.broadcastId);
          } else {
            //reject_guest_invite
            //console.log('reject_guest_invite', req)
            this.reject_guest_invite(req.notificationId, req.reqID, req.reqSendToId)
          }
          break;
        case 'h2hinvite':
          if (req.reqAction == 'accept') {
            //console.log('accept_h2h_invite', req)
            this.accept_h2h_invite(req.notificationId, req.reqID, req.reqSendToId, req.broadcastPhysicalChannel, req.broadcastId);
          } else {
            //console.log('reject_h2h_invite', req)
            this.reject_h2h_invite(req.notificationId, req.reqID, req.reqSendToId)
          }
          break;
        case 'multicastinvite':
          if (req.reqAction == 'accept') {
            //console.log('multicast_invite', req)
            this.accept_multi_invite(req.notificationId, req.reqID, req.reqSendToId, req.broadcastPhysicalChannel, req.broadcastId, req.isAudioOnly, req.maxAllowedSlots);
          } else {
            //console.log('multicast_invite', req)
            this.reject_multi_invite(req.notificationId, req.reqID, req.reqSendToId)
          }
          break;
        case 'guestappearanceinterest':
          if (req.reqAction == 'accept') {
            this.acceptGuestAppearanceJoinReq(req.notificationId, req.reqID, req.reqSendToId, req.broadcastPhysicalChannel, req.broadcastId);
          } else {
            this.activeModal.close();
            this.deleteNotification(req.notificationId);
          }
          break;
        case 'acceptguestinterest':
          if (req.reqAction == 'accept') {
            this.acceptguestinterest(req.notificationId, req.reqID, req.reqSendToId, req.broadcastPhysicalChannel, req.broadcastId, req.isAudioOnly);
          } else {
            this.activeModal.close();
            this.deleteNotification(req.notificationId);
          }
          break;
        case 'H2Hinterest':
          if (req.reqAction == 'accept') {
            this.acceptHeadtoHeadJoinReq(req.notificationId, req.reqID, req.reqSendToId, req.broadcastPhysicalChannel, req.broadcastId);
          } else {
            this.activeModal.close();
            this.deleteNotification(req.notificationId);
          }
          break;
        case 'H2Haccept':
          if (req.reqAction == 'accept') {
            this.H2Haccept(req.notificationId, req.reqID, req.reqSendToId, req.broadcastPhysicalChannel, req.broadcastId, req.isAudioOnly);
          } else {
            this.activeModal.close();
            this.deleteNotification(req.notificationId);
          }
          break;
        case 'multicastinterest':
          if (req.reqAction == 'accept') {
            console.log('multicastinterest', req)
            this.acceptMulticastJoinReq(req.notificationId, req.reqID, req.reqSendToId);
          } else {
            this.activeModal.close();
            this.deleteNotification(req.notificationId);
          }
          break;
      
        default:
          break;
      }
    }else{
      //delete Notification with proper message
      this.deleteNotification(req.notificationId);
      this.toastr.info("Sorry!! This broadcast is not live now","Broadcast Not Live")
    }
  }

  accept_multi_invite(notificationId, multicastReqID, currentUserId, physicalChannel, broadcastId, isAudioOnly, maxAllowedSlots){
    this.spinner.show();
    this.broadcastService.acceptMulticastReq({
      userID : currentUserId,
      multicastReqID : multicastReqID,
      isAudioOnly : isAudioOnly
    }).subscribe(
      response => {
        this.spinner.hide();
        if (!response.error) {
          this.activeModal.close();
          this.deleteNotification(notificationId);
          this.url = 'go-live/multi-broadcast/multibeam'+'/'+currentUserId+'/'+physicalChannel+'/'+broadcastId+'/'+isAudioOnly+'/'+maxAllowedSlots
          return this.router.navigate([this.url]);
        } else {
          return  this.toastr.error(response.message, 'Error!', {
            timeOut: 2000,
          });
        }
      },
      error => {

      }
    )
  }

  reject_multi_invite(notificationId, multicastReqID, currentUserId){
    this.spinner.show()
    this.broadcastService.rejectMulticastReq({
      userID : currentUserId,
      multicastReqID : multicastReqID,
      status : 0
    }).subscribe(
      response => {
        this.spinner.hide()
        if (!response.error) {
          this.activeModal.close();
          this.deleteNotification(notificationId);
          return  this.toastr.success('Multicast Request rejected', 'Success!', {
            timeOut: 2000,
          });
        } else {
          return  this.toastr.error(response.message, 'Error!', {
            timeOut: 2000,
          });
        }
      },
      error => {

      }
    )
  }

  accept_h2h_invite(notificationId, headToHeadRequestId, currentUserId, physicalChannel, broadcastId){
    this.spinner.show()
    this.broadcastService.acceptHeadtoHeadReq({
      userID : currentUserId,
      headToHeadReqID : headToHeadRequestId,
      status : 1
    }).subscribe(
      response => {
        this.spinner.hide()
        if (!response.error) {
          this.activeModal.close();
          this.deleteNotification(notificationId);
          // tslint:disable-next-line: max-line-length
          this.url = 'go-live/head-to-head/standard'+'/'+currentUserId+'/'+physicalChannel+'/'+broadcastId+'/'+response.data.isAudioOnly;
          return this.router.navigate([this.url]);
        } else {
          return  this.toastr.error(response.message, 'Error!', {
            timeOut: 2000,
          });
        }
      },
      error => {

      }
    )
  }

  reject_h2h_invite(notificationId, headToHeadRequestId, currentUserId){
    this.broadcastService.acceptHeadtoHeadReq({
      userID : currentUserId,
      headToHeadReqID : headToHeadRequestId,
      status : 0
    }).subscribe(
      response => {
        if (!response.error) {
          this.activeModal.close();
          this.deleteNotification(notificationId);
          return  this.toastr.success('H2H Request rejected', 'Success!', {
            timeOut: 2000,
          });
        } else {
          return  this.toastr.error(response.message, 'Error!', {
            timeOut: 2000,
          });
        }
      },
      error => {

      }
    )
  }

  acceptHeadtoHeadJoinReq(notificationId, headToHeadRequestId, currentUserId, physicalChannel, broadcastId){
    this.spinner.show()
    this.broadcastService.acceptHeadtoHeadJoinReq({
      userID : currentUserId,
      headToHeadReqID : headToHeadRequestId,
      status : 1
    }).subscribe(
      response => {
        this.spinner.hide()
        if (!response.error) {
          this.activeModal.close();
          this.deleteNotification(notificationId);
          // tslint:disable-next-line: max-line-length
          this.url = 'go-live/head-to-head/standard'+'/'+currentUserId+'/'+physicalChannel+'/'+broadcastId+'/'+response.data.isAudioOnly;
          return this.router.navigate([this.url], { queryParams: { is_h2h: 'true' } });
        } else {
          return  this.toastr.error(response.message, 'Error!', {
            timeOut: 2000,
          });
        }
      },
      error => {

      }
    )
  }

  H2Haccept(notificationId, headToHeadRequestId, currentUserId, physicalChannel, broadcastId, isAudioOnly){
    this.activeModal.close();
    this.deleteNotification(notificationId);
    this.url = 'go-live/head-to-head/standard'+'/'+currentUserId+'/'+physicalChannel+'/'+broadcastId+'/'+isAudioOnly;
    return this.router.navigate([this.url]);
  }

  accept_guest_invite(notificationId, guestAppearanceReqID, currentUserId, physicalChannel, broadcastId){
    this.spinner.show()
    this.broadcastService.accepGuestReq({
      userID : currentUserId,
      guestAppearanceReqID : guestAppearanceReqID,
      status : 1
    }).subscribe(
      response => {
        if (!response.error) {
          this.activeModal.close();
          this.deleteNotification(notificationId);
          this.spinner.hide()
          this.url = 'go-live/broadcast/standard'+'/'+currentUserId+'/'+physicalChannel+'/'+broadcastId+'/'+response.data.isAudioOnly
          return this.router.navigate([this.url]);
        } else {
          return  this.toastr.error(response.message, 'Error!', {
            timeOut: 2000,
          });
        }
      },
      error => {

      }
    )
  }

  reject_guest_invite(notificationId, guestAppearanceReqID, currentUserId){
    this.spinner.show()
    this.broadcastService.rejectGuestReq({
      userID : currentUserId,
      guestAppearanceReqID : guestAppearanceReqID,
      status : 0
    }).subscribe(
      response => {
        this.spinner.hide()
        if (!response.error) {
          this.activeModal.close();
          this.deleteNotification(notificationId);
          return  this.toastr.success('Guest Request rejected', 'Success!', {
            timeOut: 2000,
          });
        } else {
          return  this.toastr.error(response.message, 'Error!', {
            timeOut: 2000,
          });
        }
      },
      error => {

      }
    )
  }

  acceptguestinterest(notificationId, guestAppearanceReqID, currentUserId, physicalChannel, broadcastId, isAudioOnly){
    this.activeModal.close();
    this.deleteNotification(notificationId);
    this.url = 'go-live/broadcast/standard'+'/'+currentUserId+'/'+physicalChannel+'/'+broadcastId+'/'+isAudioOnly
    return this.router.navigate([this.url]);
  }

  acceptGuestAppearanceJoinReq(notificationId, guestAppearanceReqID, currentUserId, physicalChannel, broadcastId){
    this.spinner.show()
    this.broadcastService.acceptGuestAppearanceJoinReq({
      userID : currentUserId,
      guestAppearanceReqID : guestAppearanceReqID,
      status : 1
    }).subscribe(
      response => {
        if (!response.error) {
          this.activeModal.close();
          this.deleteNotification(notificationId);
          this.spinner.hide()
        } else {
          return  this.toastr.error(response.message, 'Error!', {
            timeOut: 2000,
          });
        }
      },
      error => {

      }
    )
  }

  acceptMulticastJoinReq(notificationId, multicastReqID, currentUserId){
    this.spinner.show()
    this.broadcastService.acceptMulticastJoinReq({
      userID : currentUserId,
      multicastReqID : multicastReqID
    }).subscribe(
      response => {
        if (!response.error) {
          this.activeModal.close();
          this.deleteNotification(notificationId);
          this.spinner.hide()
        } else {
          return  this.toastr.error(response.message, 'Error!', {
            timeOut: 2000,
          });
        }
      },
      error => {

      }
    )
  }
}
