import { Component, OnInit,Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService, UserService } from '../../_services';
import * as moment from 'moment'; 


@Component({
  selector: 'app-live-guides',
  templateUrl: './live-guides.component.html',
  styleUrls: ['./live-guides.component.scss']
})
export class LiveGuidesComponent implements OnInit {
  isDashboardMode = true
  title = 'My Income'
  color = 'income_bg'
  
  coins = 0;
  @Input('user') user: any;
  liveGuideData:any;
  timeSlot:any =[];
  channelSlot:any =[];
  lineupData:any =[];
  userId: any;
  getDate:any=moment().format('DD-MMM-YYYY');
  isprevious:any =true;
  cDate:any = moment().format('YYYY-MM-DD');
  isnextDate:any=true;
  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {


    this.getAllLeaderboardData(this.cDate);
  
  }
  getAllLeaderboardData(vData){

    this.spinner.show();
    this.user = JSON.parse(localStorage.getItem('currentUser'))
    this.userId = this.user.profile.FKuserID;

    this.userService.getLiveGuidesData({timeZone : 'Asia/Kolkata',userID : this.userId,channelID:'10', todaysdate :  vData}).subscribe(
      response => {
        console.log("getAllBadgesForUser", response);
        if (!response.error) {
          this.spinner.hide();
          var a=[], b={};
          b=response.data;
          a.push(b); 
          var d=[], ec={};     
          console.log(">>>other >",a);
          this.channelSlot = a[0].Channels;
          this.timeSlot =   a[0].TimeSlots;
          ec = a[0].LineUps;
          this.lineupData = Object.values(ec);
          console.log("new>>",response.data);

console.log("this.timeSlot>>",this.lineupData);



// for(var key in this.lineupData) {
//   for(this.lineupData[key]){

//   }
//   allUsers.push(ec[key]);
// }

//  ss = Object.values(this.lineupData[5]);
// console.log("vale>>>>",ss[0].id);
          // var c = response.data;
          // console.log("new>>>",c);
          // this.liveGuideData[0]=c;
          // appendObjTo(this.liveGuideData, {hello: "world!"});
          // this.channelSlot = this.liveGuideData[0].Channels;
          // console.log(">>>",this.liveGuideData[0]);
          // this.timeSlot = this.liveGuideData[0].TimeSlots;
         
         // this.liveGuideData = response.data.activeBadges;
        }
        this.spinner.hide()
      },
      error => {
        this.spinner.hide()
      }
    )
  }
  goToPreviousDay(dt){
  
    var toDay =  moment().format('YYYY-MM-DD');
    var todaysPreviousDay =  moment(toDay).subtract(1, 'day').valueOf();
    var previousDay =  moment(dt).subtract(1, 'day').valueOf();
    console.log(toDay+"===="+todaysPreviousDay+"=="+previousDay);
    this.cDate = moment(dt).subtract(1, 'day').format('YYYY-MM-DD');
    this.getDate = moment(dt).subtract(1, 'day').format('DD-MMM-YYYY')
    if(previousDay > todaysPreviousDay){
      this.isprevious = true;
    }
    else{
      this.isprevious = false;
    }
this.getAllLeaderboardData(this.cDate);


  }

  getFrontDate(dt){
  
    var toDay =  moment(dt).format('YYYY-MM-DD');
    var checkDate = moment().format('YYYY-MM-DD');
    var sevenDateFromTodate =  moment(checkDate, "YYYY-MM-DD").add(7, 'days').valueOf();

    var nextDate = moment(toDay, "YYYY-MM-DD").add(1, 'days').valueOf();
    var cnextDate = moment(dt, "YYYY-MM-DD").add(1, 'days').valueOf();
    console.log(sevenDateFromTodate +"===="+cnextDate);

    if(sevenDateFromTodate > cnextDate){
      this.isnextDate = true;
      this.isprevious = true;
    }
    else{
      this.isnextDate = false;
      this.isprevious = true;
    }
    var sendNextDate= moment(toDay, "YYYY-MM-DD").add(1, 'days').format('YYYY-MM-DD');
    this.cDate = moment(toDay, "YYYY-MM-DD").add(1, 'days').format('YYYY-MM-DD');
    this.getDate = moment(toDay, "YYYY-MM-DD").add(1, 'days').format('DD-MMM-YYYY');
    this.getAllLeaderboardData(sendNextDate);

    
  }

}
