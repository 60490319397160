import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { User } from '../_models';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RechargeService {

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
  ) { 

  }

  getDollerPurchaseOptons() {
    return this.http.get<any>(`${environment.apiUrl}app/user/getDollerPurchaseOptons`);
  }

  getStoragePurchaseOptons() {
    return this.http.post<any>(`${environment.apiUrl}app/broadcast/getStoragePurchaseOptons`, {});
  }

  getDiamondToCoinsOptions() {
    return this.http.get<any>(`${environment.apiUrl}app/user/getDiamondToCoinsOptions`);
  }

  getCashoutOPtions() {
    return this.http.get<any>(`${environment.apiUrl}app/user/getCashoutOPtions`);
  }

  convertDiasmondtoCoin(requestData) {
    return this.http.post<any>(`${environment.apiUrl}app/user/convertDiasmondtoCoin`, requestData);
  }

  cashout(requestData) {
    return this.http.post<any>(`${environment.apiUrl}app/user/cashout`, requestData);
  }
  cashoutConfirm(requestData) {
    return this.http.post<any>(`${environment.apiUrl}app/user/getCashoutCalculationDetails`, requestData);
  }

  purchaseCoins(requestData) {
    return this.http.post<any>(`${environment.apiUrl}app/user/purchaseCoins`, requestData);
  }

  buyStoreCredit(requestData) {
    return this.http.post<any>(`${environment.apiUrl}app/broadcast/buyStoreCredit`, requestData);
  }

  getUserStat(requestData) {
    return this.http.post<any>(`${environment.apiUrl}app/user/getUserStat`, requestData);
  }

  getTransactionLog(requestData) {
    return this.http.post<any>(`${environment.apiUrl}app/user/getTransactionLog`, requestData);
  }
}
