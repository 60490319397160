import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { PollsService } from 'src/app/_services/polls.service';

@Component({
  selector: 'app-gift-modal',
  templateUrl: './gift-modal.component.html',
  styleUrls: ['./gift-modal.component.scss']
})
export class GiftModalComponent implements OnInit {
  gifts = [];

  @Input() broadcasterId;
  @Input() broadcastID;
  @Output() giftSend = new EventEmitter();

  constructor(
    public activeModal: NgbActiveModal,
    public spinner: NgxSpinnerService,
    public toastr: ToastrService,
    public pollsService: PollsService,
    private authenticationService: AuthenticationService,
  ) { }

  ngOnInit() {
    this.getGifts()
  }

  getGifts(){
    this.spinner.show();
    let currentUser: any = this.authenticationService.currentUserValue;
    this.gifts = [];
    this.pollsService.getAllGift({
      userID : currentUser.profile.FKuserID,
    }).subscribe(
      response => {
        this.spinner.hide();
        this.gifts = response.data;
      },
      error => {
 
      }
    )
  }

  sendGift(giftID){
    console.log("giftSend")
    this.spinner.show();
    let currentUser: any = this.authenticationService.currentUserValue;
    this.pollsService.sendGift({
      giftID : giftID,
      broadcastID : this.broadcastID,
      userID : currentUser.profile.FKuserID,
    }).subscribe(
      response => {
        
        this.spinner.hide();
        if (!response.error) {
          this.giftSend.emit(response.data);
          console.log("giftSend", response.data)
          return  this.toastr.success(response.message, 'Success!', {
            timeOut: 2000,
          }); 
        } else {
          return  this.toastr.error(response.message, 'Error!', {
            timeOut: 2000,
          });
        }
      },
      error => {
 
      }
    )
  }

}
