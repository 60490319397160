import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { BroadcastService } from 'src/app/_services/broadcast.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { UploadFileService } from 'src/app/_services';

@Component({
  selector: 'app-set-logo-modal',
  templateUrl: './set-logo-modal.component.html',
  styleUrls: ['./set-logo-modal.component.scss']
})
export class SetLogoModalComponent implements OnInit {
  selectedFiles: any;
  profileImgUrl: any;
  positions = [];
  settingsStatus = [];
  selectedPosition = [];
  selectedSettings = [];
  dropdownSettings: any = {};
  closeDropdownSelection=true;
  disabled=false;
  user: any;
  logoPosition: number;
  includeInBroadcast: number;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private router: Router,
    private broadcastService: BroadcastService,
    public spinner: NgxSpinnerService,
    public toastr: ToastrService,
    private uploadService: UploadFileService,
  ) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('currentUser'))

    this.positions = ['Top Right', 'Top Left', 'Bottom Right', 'Bottom Left'];
    this.settingsStatus = ['Display in broadcast', `Don't display in broadcast`];
    this.spinner.show()
    this.uploadService.getuserLogos({userID : this.user.profile.FKuserID}).subscribe(
      response => {
         if (!response.error && response.data.length > 0) {
           //Success
           this.profileImgUrl = response.data[0].logo

            //'Top Right', 'Top Left', 'Bottom Right', 'Bottom Left'
            if (response.data[0].position =='1') {
              this.logoPosition = 1
              this.selectedPosition = ['Top Right'];
            } else if(response.data[0].position =='2'){
              this.logoPosition = 2
              this.selectedPosition = ['Top Left'];
            } else if(response.data[0].position =='3'){
              this.logoPosition = 3
              this.selectedPosition = ['Bottom Right'];
            } else {
              this.logoPosition = 4
              this.selectedPosition = ['Bottom Left'];
            }
            //'Display in broadcast', `Don't display in broadcast`
            if (response.data[0].includeInBroadcast == '1') {
              this.includeInBroadcast = 1;
              this.selectedSettings = ['Display in broadcast'];
            } else {
              this.includeInBroadcast = 0;
              this.selectedSettings = [`Don't display in broadcast`];
            }
         } else {
          this.profileImgUrl ='';

          this.logoPosition = 1
          this.selectedPosition = ['Top Right'];

          this.includeInBroadcast = 1;
          this.selectedSettings = ['Display in broadcast'];
         }

        const request = {
          userID : this.user.profile.FKuserID,
          logo : [
            {
              logo : this.profileImgUrl,
              position : this.logoPosition.toString(),
              includeInBroadcast : this.includeInBroadcast.toString(),
            }
          ]
        }
        
        localStorage.setItem("logoSettings", JSON.stringify(request))

        this.spinner.hide()
      },
      error =>{

      }
    );
    this.dropdownSettings = {
        singleSelection: true,
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        allowSearchFilter: true,
        closeDropDownOnSelection: this.closeDropdownSelection
    };
  }

  onItemSelect(item: any) {
    console.log('onItemSelect', item);
    this.selectedPosition = [item];
    this.saveuserLogos();
  }

  onItemDeSelect(item: any) {
    console.log('onItemDeSelect', item);
    this.selectedPosition = ['Top Right'];
    this.saveuserLogos();
  }

  onSettingsSelect(item: any) {
    console.log('onSettingsSelect', item);
    this.selectedSettings = [item];
    this.saveuserLogos();
  }

  onSettingsDeSelect(item: any) {
    console.log('onSettingsDeSelect', item);
    this.selectedSettings = ['Display in broadcast'];
    this.saveuserLogos();
  }

  toggleCloseDropdownSelection() {
      this.closeDropdownSelection = !this.closeDropdownSelection;
      this.dropdownSettings = Object.assign({}, this.dropdownSettings,{closeDropDownOnSelection: this.closeDropdownSelection});
  }

  selectFile(event) {
    this.spinner.show();
    this.selectedFiles = event.target.files;
    const file = this.selectedFiles.item(0);
    this.uploadService.uploadFile(file).subscribe(
      response => {
        this.profileImgUrl = response.data
        this.saveuserLogos();
        this.spinner.hide();
      },
      error=>{
        console.log("Error", error)
        this.spinner.hide();
      }
    )
  }

  saveuserLogos(){
    if (this.profileImgUrl) {
      //'Top Right', 'Top Left', 'Bottom Right', 'Bottom Left'
      if (this.selectedPosition[0] =='Top Right') {
        this.logoPosition = 1
      } else if(this.selectedPosition[0] =='Top Left'){
        this.logoPosition = 2
      } else if(this.selectedPosition[0] =='Bottom Right'){
        this.logoPosition = 3
      } else {
        this.logoPosition = 4
      }
      //'Display in broadcast', `Don't display in broadcast`
      if (this.selectedSettings[0] == 'Display in broadcast') {
        this.includeInBroadcast = 1;
      } else {
        this.includeInBroadcast = 0;
      }
      
      const request = {
        userID : this.user.profile.FKuserID,
        logo : [
          {
            logo : this.profileImgUrl,
            position : this.logoPosition.toString(),
            includeInBroadcast : this.includeInBroadcast.toString(),
          }
        ]
      }
      
      this.spinner.show();
      this.uploadService.saveuserLogos(request).subscribe(
        response =>{
           this.spinner.hide();
           if (!response.error) {
             //Success
             localStorage.setItem("logoSettings", JSON.stringify(request))
             this.toastr.success("Settings updated", 'Success!', {
              timeOut: 2000,
              progressBar: true,
              closeButton: true
            });
           }else {
             //Error
             this.toastr.error(response.message, 'Error!', {
              timeOut: 2000,
              progressBar: true,
              closeButton: true
            });
           }
           console.log("Response saveuserLogos", response)
        },
        error =>{
          this.toastr.error(error.message, 'Error!', {
            timeOut: 2000,
            progressBar: true,
            closeButton: true
          });
        }
      )
    }else {
      this.toastr.error("You don't have any logo", 'Error!', {
        timeOut: 2000,
        progressBar: true,
        closeButton: true
      });
    }
  }

}
