import { Component, OnInit } from '@angular/core';
import { AuthenticationService, GlobalService, UploadFileService, UserService } from '../../../../_services';
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-user-like',
  templateUrl: './user-like.component.html',
  styleUrls: ['./user-like.component.css']
})
export class UserLikeComponent implements OnInit {
  isDashboardMode = true
  title = 'My Likes'
  color = 'myfan_head-bg'
  getUserFollowerDetailData:any;
  constructor(
    private toastr: ToastrService,

    private authenticationService: AuthenticationService,
    private globalService: GlobalService,
    private userService: UserService,
    private router: Router,
    private spinner: NgxSpinnerService,

  ) {
    const Swal = require('sweetalert2')


   }
  removeFollow(id){


    Swal.fire({
      title: 'Are you sure?',
      text: 'You Want to Unfollow this user',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        let currentUser: any = this.authenticationService.currentUserValue; 
        let unFollowId = id;
        this.spinner.show();
    
        const requestData = {
          userID: currentUser.profile.FKuserID,
          followerID: unFollowId
        }
        this.userService.removeMyfollower(requestData).subscribe(
          data => {
            if (!data.error) {
              //Success
              this.getUserFollow();
             //  this.getUserFollowerDetailData = data.data;
               this.spinner.hide();
            } else {
              //Error
              this.toastr.error(data.message, 'Error!', {
                timeOut: 5000,
                progressBar: true,
                closeButton: true
              });
              this.spinner.hide();
            }
          },
          error => {
           // this.spinner.hide();
        
          }
        )    
    
      // For more information about handling dismissals please visit
      // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          '',
          'error'
        )
      }
    })

 
    }

   getUserFollow(){
    let currentUser: any = this.authenticationService.currentUserValue;

    const requestData = {
      userID: currentUser.profile.FKuserID,

    }
    this.spinner.show();
    this.userService.getUserLikeDetail(requestData).subscribe(
      data => {
        if (!data.error) {
          //Success
        
           this.getUserFollowerDetailData = data.data;
           this.spinner.hide();
        } else {
          //Error
          this.toastr.error("You can add only 3 items!", 'Error!', {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
          this.spinner.hide();
        }
      },
      error => {

      }
    )
   } 
  ngOnInit() {  
    this.getUserFollow();


  }

}
