import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import { Email, Username } from '../_models';

@Injectable({ providedIn: 'root' })
export class ChannelService {
    constructor(
        private http: HttpClient,
        private route: ActivatedRoute,
        private router: Router,
    ) { 

    }
    getChannels() {
        return this.http.get<any>(`${environment.apiUrl}app/channel/getChannels?id=0&groupbyType=1&channelForBroadcast=0`);
    }
    
    getOnlyChannels(userId) {
        return this.http.get<any>(`${environment.apiUrl}app/channel/getChannels?id=0&groupbyType=0&channelForBroadcast=1&userID=`+userId);
    }
}