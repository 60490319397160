import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-settings-reports',
  templateUrl: './user-settings-reports.component.html',
  styleUrls: ['./user-settings-reports.component.css']
})
export class UserSettingsReportsComponent implements OnInit {
  isDashboardMode = true
  title = 'My Reports'
  color = 'my-reports-head-bg'
  constructor() { }

  ngOnInit() {
  }

}
