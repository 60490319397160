import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService, GlobalService, UploadFileService } from '../../_services';
import { NgxSpinnerService } from "ngx-spinner";

import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';
import { ValidateEmailNotTaken, ValidateUsernameNotTaken } from 'src/app/validators';
import * as moment from 'moment'; 
import { PasswordValidator } from '../../_validators/password.validator';

@Component({
  selector: 'app-social-info',
  templateUrl: './social-info.component.html',
  styleUrls: ['./social-info.component.scss']
})
export class SocialInfoComponent implements OnInit {
  selectedFiles: FileList;

  returnUrl :string = null
  profileImgUrl :string = ''
  error :any
  loading :any
  userID :any
  
  customerId :string = null
  firstName :string
  lastName :string
  submitted  = false
  
  maxDate:any;

  basicInfoForm: FormGroup;
  countries = JSON.parse(localStorage.getItem('countries'));
  defaultCode = '1&United States of America'
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private uploadService: UploadFileService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private globalService: GlobalService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.maxDate = new Date();
   
    this.maxDate.setDate(this.maxDate.getDate() - 6570);
    this.renderer.addClass(this.document.body, 'signup-with-phone');

    let requiredData = JSON.parse(localStorage.getItem('currentUser'));
    let ipData = localStorage.getItem("ipData")
   if (!ipData) {
    this.globalService.getIpdata().subscribe(
      reaponse =>{
        localStorage.setItem("ipData", JSON.stringify(reaponse))
        this.selectCountry(reaponse)
      }
    )
   }else {
     this.selectCountry(JSON.parse(ipData))
   }
if(requiredData.updateInfo !='1'){
  this.router.navigate(['/channels']);
}
    this.basicInfoForm = this.formBuilder.group({
      firstName: [requiredData.profile.firstName, Validators.required],
      lastName: [requiredData.profile.lastName, Validators.required],
      phoneNo: [requiredData.profile.phoneNo],
      countryCode: [this.defaultCode ? this.defaultCode : requiredData.profile.countryCode],
      username: ['', [Validators.required,Validators.minLength(6)]],
      email: [requiredData.profile.email, [Validators.required, Validators.email]],
   
    
      //aboutMe: [''],
      dob: [''],
      gender: ['male',Validators.required],
      agree: [false,Validators.pattern('true')],
      userID: [requiredData.profile.FKuserID ]
    });

    // this.basicInfoForm.controls['email'].setAsyncValidators(ValidateEmailNotTaken.createValidator(this.globalService, this.customerId));
    // this.basicInfoForm.controls['username'].setAsyncValidators(ValidateUsernameNotTaken.createValidator(this.globalService, this.customerId));
    // get return url from route parameters or default to '/channels'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/channels';
  }
  // checkPasswords(group: FormGroup) { // here we have the 'passwords' group
  // let pass = group.get('password').value;
  // let cnfPassword = group.get('cnfPassword').value;

  //   return pass === cnfPassword ? null : { notSame: true }     
  // }
  // convenience getter for easy access to form fields


  selectCountry(ipData:any) {
    this.countries.forEach(country => {
      if (country.code === ipData.country) {
        this.defaultCode = country.dialCode+"&"+country.name
      }
    });
  }
  get f() { return this.basicInfoForm.controls; }
  get fe() { return this.basicInfoForm.errors; }
  get email() { return this.basicInfoForm.get('email') }
  get username() { return this.basicInfoForm.get('username') }

  //Prefered not to use uploadService for security issue
  selectFile(event) {
    this.spinner.show();
    this.selectedFiles = event.target.files;
    const file = this.selectedFiles.item(0);
    this.uploadService.uploadFile(file).subscribe(
      response => {
        console.log("response", response)
        this.profileImgUrl = response.data
        this.spinner.hide();
      },
      error=>{
        console.log("Error", error)
        this.spinner.hide();
      }
    )
  }

  onSubmit() {
    console.log(">>>>>>>>>>",this.basicInfoForm);
    
    this.spinner.show();
    this.submitted = true
    // stop here if form is invalid
    if (this.basicInfoForm.invalid) {
        console.log('basicInfoFormError', this.basicInfoForm);

        this.spinner.hide();
        return;
    }

    let res:any;
    let str = this.f.countryCode.value;
    res = str.split("&");

    const requestData = {
      userID: this.f.userID.value,
      phoneNo : this.f.phoneNo.value,
      countryCode :  "+" + res[0],
      username: this.f.username.value,
      email: this.f.email.value,
     
      firstName: this.f.firstName.value,
      lastName: this.f.lastName.value,
      //aboutMe: this.f.aboutMe.value,
      dob: moment(this.f.dob.value).format('MM/DD/YYYY'),
      gender: this.f.gender.value ? this.f.gender.value === 'male' ? 1 : 2 : null ,
 
      
    }
    this.authenticationService.socialInfoUpdate(requestData).subscribe(
      response => {
        if (response.error) {
          //Error
          this.toastr.error(response.message, 'Error!', {
            timeOut: 2000,
            progressBar: true,
            closeButton: true
          });
        } else {
          //Success
          this.router.navigate(['/channels']);
          
        }
      },
      error => {
        this.toastr.error("Sorry, something went wrong there. Try again", 'Error!', {
          timeOut: 2000,
          progressBar: true,
          closeButton: true
        });
      }
    )
  }
}
