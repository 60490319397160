import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-all-chat-user',
  templateUrl: './all-chat-user.component.html',
  styleUrls: ['./all-chat-user.component.scss']
})
export class AllChatUserComponent implements OnInit {
  @Input() user: any;
  constructor() { }

  ngOnInit() {
    console.log("Users", this.user.USERS[1].avtar)
  }

}
