import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, GlobalService, UploadFileService, UserService, ChannelService } from '../../../../_services';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';


import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-lineup',
  templateUrl: './user-lineup.component.html',
  styleUrls: ['./user-lineup.component.css']
})

export class UserLineupComponent implements OnInit {
  lineUpType:any = '1';
  isDashboardMode = true
  title = 'My Line up'
  color = 'adding-lineup-head-bg'
  modalRef: BsModalRef;
  addLineUp: FormGroup;
  submitted = false
  typeData: any;
  getUserChannel: any;
  mytime: Date = new Date();
  HashTags: any = [];
  channels = [];
  adminSettings:any =[];
  isShow = false;
  findhasTag: any;
  LineUpListData: any;
  moment: any = moment;
  showCurrentdate: any;
  broadcastlineupTimeSlots: any;
  minDate: Date;
  required: any;
  requiredTime: any[];
  requiredsTime: any;
  dropdownSettings: any = {};
  limitSelection = true;
  disabled = false;
  selectedChannelsIds = [];
  privateminDate: Date;
  privatemaxDate: Date;

  globalminDate: Date;
  globalmaxDate: Date;
  timezone:any;
  ShowFilter = true;
  constructor(
    private modalService: BsModalService,
    private channelService: ChannelService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private globalService: GlobalService,
    private userService: UserService,
    private spinner: NgxSpinnerService
  ) {



    const Swal = require('sweetalert2')
    this.minDate = new Date();
  }
  toggleDisplay() {
    this.isShow = !this.isShow;
  }
  getLineUpdata(a) {
    this.lineUpType = a;
    if (this.lineUpType == '1') {
      this.dropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        itemsShowLimit: 3,
        limitSelection: 3,
        allowSearchFilter: this.ShowFilter
      };
      this.requiredTime = []
      this.requiredsTime = [Validators.required]
      this.addLineUp = this.formBuilder.group({

        title: ['', [Validators.required]],
        selectedChannel: [''],
        type: [this.lineUpType, [Validators.required]],

        time_slot: ['', this.requiredTime],
        sdate: ['', [Validators.required]],
        sTime: ['', this.requiredsTime],
        addhasTag: [''],
        editid: [''],
        selectTimezone:['America/New_York']
      });
    } else {
      this.dropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        itemsShowLimit: 1,
        limitSelection: 1,
        allowSearchFilter: this.ShowFilter
      };
      this.requiredTime = [Validators.required]
      this.requiredsTime = []
      this.addLineUp = this.formBuilder.group({

        title: ['', [Validators.required]],
        type: [this.lineUpType, [Validators.required]],
        selectedChannel: [''],
        time_slot: ['', this.requiredTime],
        sdate: ['', [Validators.required]],
        sTime: ['', this.requiredsTime],
        addhasTag: [''],
        editid: [''],
        selectTimezone:['America/New_York']
      });
    }

    let currentUser: any = this.authenticationService.currentUserValue;

    this.userService.getBroadcastLineupByUserByType(
      {
        userID: currentUser.profile.FKuserID,
        lineupTypeID: this.lineUpType
      }
    ).subscribe(
      data => {
        if (!data.error) {

          this.LineUpListData = data.data;
          console.log("lineupdata>>>>>>>>>", this.LineUpListData);

        } else {
          //Error

        }
      },
      error => {

      }
    )

  }
  LineUpTodayData() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    let todaydate = yyyy + '-' + mm + '-' + dd;
    // return this.LineUpListData && this.LineUpListData.filter(x => x.scheduleDate == todaydate);
    if (this.LineUpListData && this.LineUpListData.todaysLineup) {
      return this.LineUpListData.todaysLineup;
    }
    else {
      return [];
    }
  }
  LineUpNotTodayData() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    let todaydate = yyyy + '-' + mm + '-' + dd;
    // return this.LineUpListData && this.LineUpListData.filter(x => x.scheduleDate != todaydate);
    if (this.LineUpListData && this.LineUpListData.leterLineup) {
      return this.LineUpListData.leterLineup;
    }
    else {
      return [];
    }
  }
  addUserLineup() {
    //alert('hi');
  }

  openModal(template: TemplateRef<any>) {
   
    this.modalRef = this.modalService.show(template,  {
      backdrop: 'static'  
    });
    this.submitted = false;
    this.addLineUp.patchValue(
      {
        "editid": '',
        "title": '',
        "type": this.lineUpType,

        "sdate": '',
        "sTime": '',
        "selectTimezone":'America/New_York'
      })
    this.HashTags = [];

  }
  editValue: any = [];
  selectedChannelsIdsForEdit: any = [];
  editOpenModal(template2: TemplateRef<any>, item) {
    this.selectedChannelsIds = [];
    this.selectedChannelsIdsForEdit =[];
    this.editValue = item;
    console.log('editOpenModal', this.editValue);
    this.modalRef = this.modalService.show(template2,{
      backdrop: 'static'  
    });
    this.editValue && this.editValue.ChannelDetails && this.editValue.ChannelDetails.map((v, i) => {
      if (v) {
        this.selectedChannelsIds.push(v.id);
        this.selectedChannelsIdsForEdit.push({ "id": v.id, "name": v.name });
      }
    });
    this.addLineUp.patchValue(
      {
        "editid": this.editValue.id,
        "title": this.editValue.title,
        "type": this.editValue.FKlineupTypeID,
        "selectedChannel": this.selectedChannelsIdsForEdit,
        "time_slot":this.editValue.FKtimeSlotID,
        "sdate": moment(this.editValue.scheduleDate).format('MM/DD/YYYY'),
        "sTime": new Date(this.editValue.scheduleDate + " " + this.editValue.scheduleTime),
        "selectTimezone": this.editValue.timeZone
      })

    console.log("edit cahannel >>", this.selectedChannelsIds);


    this.HashTags = this.editValue.HashTags;

  }
  cancelBroadcastLineup(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You Want To Cancel This Schedule',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        let currentUser: any = this.authenticationService.currentUserValue;
        let broadcastLineupID = id;
        this.spinner.show();

        const requestData = {
          broadcastLineupID: broadcastLineupID
        }
        this.userService.cancelBroadcastLineup(requestData).subscribe(
          data => {
            if (!data.error) {
              //Success
              this.toastr.success(data.message, 'Success!', {
                timeOut: 5000,
                progressBar: true,
                closeButton: true
              });
              this.getAllLineUp(this.lineUpType);
              //  this.getUserFollowerDetailData = data.data;
              this.spinner.hide();
            } else {
              //Error
              this.toastr.error(data.message, 'Error!', {
                timeOut: 5000,
                progressBar: true,
                closeButton: true
              });
              this.spinner.hide();
            }
          },
          error => {
            // this.spinner.hide();

          }
        )

        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      }



      else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          '',
          'error'
        )
      }
    })
  }


  removeMyLineUp(id) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You Want To Remove This Schedule',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        let currentUser: any = this.authenticationService.currentUserValue;
        let broadcastLineupID = id;
        this.spinner.show();

        const requestData = {
          userID: currentUser.profile.FKuserID,
          broadcastLineupID: broadcastLineupID
        }
        this.userService.deleteBroadcastLineup(requestData).subscribe(
          data => {
            if (!data.error) {
              //Success
              this.getAllLineUp(this.lineUpType);
              //  this.getUserFollowerDetailData = data.data;
              this.spinner.hide();
            } else {
              //Error
              this.toastr.error(data.message, 'Error!', {
                timeOut: 5000,
                progressBar: true,
                closeButton: true
              });
              this.spinner.hide();
            }
          },
          error => {
            // this.spinner.hide();

          }
        )

        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      }



      else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          '',
          'error'
        )
      }
    })


  }

  editLineUpDetails() {
    this.submitted = true;
    if (this.addLineUp.invalid) {
      console.log('basicInfoFormError', this.addLineUp);

      this.spinner.hide();
      return;
    }

    
    let currentUser: any = this.authenticationService.currentUserValue;
    var sTime;
    if(this.addLineUp.value.type == '1'){
      sTime= moment(this.addLineUp.value.sTime).format('HH:mm:ss');
    }
    else{
      sTime = '';
    
    }
    let data = {
      id: this.addLineUp.value.editid,
      userID: currentUser.profile.FKuserID,
      title: this.addLineUp.value.title,
      lineupTypeID: this.addLineUp.value.type,
      channelID: this.selectedChannelsIds,

      timeSlotID: this.addLineUp.value.time_slot,
      scheduleDate: moment(this.addLineUp.value.sdate).format('YYYY-MM-DD'),
      scheduleTime: sTime,
      status: "1",
      hashTags: this.HashTags,
      todaysdate: moment().format('YYYY-MM-DD'),
      timeZone:this.addLineUp.value.selectTimezone,

    };
    this.userService.addBroadcastLineup(data).subscribe(
      response => {
        if (!response.error) {
          this.getAllLineUp(this.lineUpType);
          this.selectedChannelsIds =[];
          this.modalRef.hide();
          this.spinner.hide();
          this.isShow = !this.isShow;
          this.toastr.success("Successfully Updated!", 'Success!', {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
        }
        else {
          this.spinner.hide();
          this.toastr.error(response.message, 'Error!', {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });

        }

      },
      error => {

        this.toastr.error("Sorry, something went wrong there. Try again", 'Error!', {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
        this.spinner.hide();
      }
    );

  }

  onSubmit() {
    this.submitted = true;
    console.log(">>>>",this.addLineUp.value.sTime);
    //this.spinner.hide();
    if (!this.addLineUp.invalid) {
   
    
    let currentUser: any = this.authenticationService.currentUserValue;
    var sTime;
    if(this.addLineUp.value.type == '1'){
      sTime= moment(this.addLineUp.value.sTime).format('HH:mm:ss');
    }
    else{
      sTime = '';
    
    }
    console.log("stime>>",sTime);
    let data = {
      userID: currentUser.profile.FKuserID,
      title: this.addLineUp.value.title,
      lineupTypeID: this.addLineUp.value.type,
      timeSlotID: this.addLineUp.value.time_slot,
      channelID: this.selectedChannelsIds,
      scheduleDate: moment(this.addLineUp.value.sdate).format('YYYY-MM-DD'),
      scheduleTime: sTime,
      status: "1",
      hashTags: this.HashTags,
      todaysdate: moment().format('YYYY-MM-DD'),
      timeZone: this.addLineUp.value.selectTimezone
    };
    this.userService.addBroadcastLineup(data).subscribe(
      response => {
        if (!response.error) {
          this.selectedChannelsIds =[];
          this.getAllLineUp(this.lineUpType);
          this.modalRef.hide();
          this.spinner.hide();
          this.addLineUp.reset();
          this.isShow = !this.isShow;
          this.toastr.success("Successfully Added!", 'Success!', {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
        }
        else {
          this.spinner.hide();
          this.toastr.error(response.message, 'Error!', {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });

        }
      },
      error => {

        this.toastr.error("Sorry, something went wrong there. Try again", 'Error!', {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
        this.spinner.hide();
      }
    );
    }
   
  }
  addHasTag(e) {
    let val = e;
    if (val) {
      if (this.HashTags.length + 1 <= '10') {

      this.HashTags.push(val);
      this.toggleDisplay();
      this.addLineUp.patchValue({
        "addhasTag": '',
      })
      this.findhasTag = [];
    }
    else{
      this.toastr.error("You can add only 10 items!", 'Alert!', {
        timeOut: 5000,
        progressBar: true,
        closeButton: true
      });
    }
    }


  }
  get f() { return this.addLineUp.controls; }
  saverange(e) {
    if (e) {
      this.userService.getHashtags({ name: e }).subscribe(
        response => {
          if (!response.error) {
            this.findhasTag = response.data;

            this.spinner.hide();

          }

        },
        error => {


          this.spinner.hide();
        }
      );
    }
  }
  saveHashtag() {
    let val = (document.getElementById('inputHastag') as HTMLInputElement).value;
    if (val) {
      if (this.HashTags.length + 1 <= '10') {

        this.HashTags.push(val);
        this.toggleDisplay();
        (document.getElementById('inputHastag') as HTMLInputElement).value = '';
      }
      else {

        this.toastr.error("You can add only 10 items!", 'Error!', {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
      }
    }

  }
  removeHashTags(e) {
    let rArray = this.HashTags;
    rArray.splice(e, 1);
    console.log(">>>-----", rArray);
  }

  getAllLineUp(isdata = '1') {

    let currentUser: any = this.authenticationService.currentUserValue;



    this.userService.getBroadcastLineupByUserByType(
      {
        userID: currentUser.profile.FKuserID,
        lineupTypeID: isdata
      }
    ).subscribe(
      data => {
        if (!data.error) {

          this.LineUpListData = data.data;
          console.log("lineupdata>>>>>>>>>", this.LineUpListData);

        } else {
          //Error

        }
      },
      error => {

      }
    )
  }
  handleLimitSelection() {
    if (this.limitSelection) {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: 2 });
    } else {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: null });
    }
  }

  toogleShowFilter() {
    this.ShowFilter = !this.ShowFilter;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, { allowSearchFilter: this.ShowFilter });
  }
  onItemSelect(item: any) {

    console.log('onItemSelect', item);
    this.selectedChannelsIds.push(item.id);
    console.log("<<<123456>>", this.selectedChannelsIds);
  }
  onItemDeSelect(item: any) {
    console.log('onItemDeSelect', item);
    this.selectedChannelsIds.splice(this.selectedChannelsIds.indexOf(item.id), 1);
  }

  onSelectAll(items: any) {
    console.log('onSelectAll', items);
  }

  ngOnInit() {


    this.lineUpType = '1'
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    this.showCurrentdate = dd + "." + mm + "." + yyyy;
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      limitSelection: 3,
      allowSearchFilter: this.ShowFilter
    };

    this.privateminDate = new Date();
    this.privatemaxDate = new Date();

    this.globalminDate = new Date();
    this.globalmaxDate = new Date();
   
   
    let currentUser: any = this.authenticationService.currentUserValue;
    //Get Channels
    this.channelService.getOnlyChannels(currentUser.profile.FKuserID).subscribe(
      response => {
        if (!response.error) {
          this.channels = response.data
        }
      }
    )

//get timezone
    this.userService.getAllTimezone().subscribe(
      response => {
        if (!response.error) {
          this.timezone = response.data.TimeZone;
        }
      }
    )


    this.userService.getAllAdminData().subscribe(
      response => {
        if (!response.error) {
          this.adminSettings = response.data;
          console.log("admin modele>>>",this.adminSettings);
        
          this.privateminDate.setDate(this.privateminDate.getDate() + parseInt(this.adminSettings.privateLineupMinAdvanceDate));
          this.privatemaxDate.setDate(this.privatemaxDate.getDate() + parseInt(this.adminSettings.privateLineupMaxAdvancedate));


          this.globalminDate.setDate(this.globalminDate.getDate() + parseInt(this.adminSettings.globalLineupMinAdvanceDate));
          this.globalmaxDate.setDate(this.globalmaxDate.getDate() + parseInt(this.adminSettings.globalLineupMaxAdvanceDate));

        
        }
      }
    )



    this.userService.getbroadcastlineupTimeSlots({
      timeZone: 'America/New_York',
     
    }).subscribe(
      response => {
        this.broadcastlineupTimeSlots = response
        console.log(this.broadcastlineupTimeSlots);
      }
    )
    this.requiredTime = []
    this.requiredsTime = [Validators.required]
    this.addLineUp = this.formBuilder.group({

      title: ['', [Validators.required]],
      type: [this.lineUpType, [Validators.required]],
      selectedChannel: [''],
      time_slot: ['', this.requiredTime],
      sdate: ['', [Validators.required]],
      sTime: ['', this.requiredsTime],
      addhasTag: [''],
      editid: [''],
      selectTimezone:['America/New_York']
    });



    this.getAllLineUp();




    this.userService.getBroadcastLineupType().subscribe(
      data => {
        if (!data.error) {

          this.typeData = data.data;

        } else {
          //Error

        }
      },
      error => {

      }
    )

    this.userService.getchannel().subscribe(
      data => {
        if (!data.error) {
          //Success
          console.log("channels>>>", data.data);
          this.getUserChannel = data.data;
          this.spinner.hide();
        } else {
          //Error
          this.spinner.hide();
        }
      },
      error => {

      }
    )



  }

  selectTimeslot(){
   
    this.userService.getbroadcastlineupTimeSlots({
      timeZone: this.addLineUp.value.selectTimezone,
     
    }).subscribe(
      response => {
        this.broadcastlineupTimeSlots = response
        
      }
    )

  }

}
