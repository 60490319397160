import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService, GlobalService, UploadFileService, UserService } from '../../../../_services';
import { NgxSpinnerService } from "ngx-spinner";

import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';
import { RechargeService } from 'src/app/_services/recharge.service';
import { ThemesService } from 'src/app/_services/themes.service';

@Component({
  selector: 'app-user-settings-themes',
  templateUrl: './user-settings-themes.component.html',
  styleUrls: ['./user-settings-themes.component.css']
})
export class UserSettingsThemesComponent implements OnInit {
  isDashboardMode = true
  title = 'Themes'
  color = 'income_bg'
  themes: any;
  selectedTheme = localStorage.getItem('theme')
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private globalService: GlobalService,
    private userService: UserService,
    private themesService: ThemesService,

    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.spinner.show();

    this.themesService.getThemes().subscribe(
      response => {
        this.themes = response.data
        this.spinner.hide();
      },
      error => {

      }
    )
  }
  
  setTheme(themeId){
     this.spinner.show()
     let currentUser: any = this.authenticationService.currentUserValue;

     this.themesService.updateUserProfileTheme({
      userID: currentUser.profile.FKuserID,
      myTheme: themeId
     }).subscribe(
       response => {
        localStorage.setItem('theme', themeId)
        this.selectedTheme = themeId
        this.spinner.hide()
       },
       error => {

       }
     )
  }
}
