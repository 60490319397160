import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/_services/user.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-broadcast-details',
  templateUrl: './broadcast-details.component.html',
  styleUrls: ['./broadcast-details.component.css']
})
export class BroadcastDetailsComponent implements OnInit {
  isDashboardMode = true
  title = 'Broadcast Data Details'
  color = 'broadcast-details-head-bg'
  user:any;
  userId:any;
  broadcustValue:any;
  viewboroadcustData:any;
  viewboroadcustStateData:any;
  totalDuration:any; 
  moment: any = moment;

  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    this.spinner.show()
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.userId = this.user.profile.FKuserID

    this.route.paramMap.subscribe(params => { 
      this.broadcustValue = params.get('id'); 
     
      
      this.userService.getBroadcustDetailsStatesData({broadcastID:this.broadcustValue,userID : this.userId}).subscribe(
        response => {
         
          if (!response.error) {
             this.viewboroadcustData = response.data.broadcastDetails;
            var dt1 = new Date(this.viewboroadcustData.startOn);
            var  dt2 = new Date(this.viewboroadcustData.endON);
            var a = moment(this.viewboroadcustData.endON);//now
            var b = moment(this.viewboroadcustData.startOn);
            
            var now  = moment(this.viewboroadcustData.endON).format("DD/MM/YYYY HH:mm:ss");
            var then = moment(this.viewboroadcustData.startOn).format("DD/MM/YYYY HH:mm:ss");
            
         this.totalDuration =  moment.utc(moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(then,"DD/MM/YYYY HH:mm:ss"))).format("HH:mm:ss");
             
           
          }
          this.spinner.hide()
        },
        error => {
          this.spinner.hide()
        }
      )
      
      
  });


  }

}
