import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService, UserService } from '../../_services';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.css']
})
export class ProfileDetailsComponent implements OnInit {
  userId :any
  user : any
  currentUser : any
  sub;
  isAuth = false

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'))
    if (this.currentUser) {
      this.isAuth = true
    } else {
      this.isAuth = false
    }
    this.sub=this.route.paramMap.subscribe(params => { 
       this.userId = params.get('id');   
       this.userService.getUserDetails({id : this.userId}).subscribe(
         response => {
           if (response.error) {
             //Error
           } else {
             //Success
             this.user = response.data;
             console.log(this.user);
           }
         },
         error => {
           //Error
         }
       )
   });
    // this.userId = this.route.snapshot.queryParams['id']
    // console.log("User Id",this.userId)
  }

}
