import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/_services/user.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-badge-details',
  templateUrl: './badge-details.component.html',
  styleUrls: ['./badge-details.component.css']
})
export class BadgeDetailsComponent implements OnInit {
  isDashboardMode = true
  title = 'Badge'
  color = 'badge-head-bg'

  badge
  user: any;
  userId: any;

  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.badge = JSON.parse(localStorage.getItem('badge'))
  }

  buy(id) {
    this.spinner.show()
    this.user = JSON.parse(localStorage.getItem('currentUser'))
    this.userId = this.user.profile.FKuserID
    
    this.userService.purchaseBadge({
      userID: this.userId,
      badgeID: id
    }).subscribe(
      response => {
        this.spinner.hide()
        this.toastr.success(response.message, 'Purchase!', {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
      },
      error => {

      }
    )
  }

}
