import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { BroadcastService } from 'src/app/_services/broadcast.service';

@Component({
  selector: 'app-broadcast-info-modal',
  templateUrl: './broadcast-info-modal.component.html',
  styleUrls: ['./broadcast-info-modal.component.scss']
})
export class BroadcastInfoModalComponent implements OnInit {
  @Input() userId;
  @Input() broadcastID;
  stats: any = {};

  constructor(
    public activeModal: NgbActiveModal,
    public spinner: NgxSpinnerService,
    public toastr: ToastrService,
    private authenticationService: AuthenticationService,
    private broadcastService: BroadcastService,
  ) { }

  ngOnInit() {
    this.getBroadcastDetails()
  }

  getBroadcastDetails(){
    console.log(this.broadcastID)
    this.broadcastService.getBroadcastDetail({
      userID : this.userId,
      broadcastID : this.broadcastID
    }).subscribe(
      response => {
          this.stats = response.data.broadcastDetails.stat
          console.log(this.stats)
      },
      error =>{

      }
    )
  }

}
