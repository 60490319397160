import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// used to create fake backend
//import { fakeBackendProvider } from './_helpers/fake-backend';

import { AppComponent } from './app.component';
import { appRoutingModule } from './app.routing';

import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { MobileLoginComponent } from './login/mobile-login/mobile-login.component';
import { ChannelComponent } from './channel/channel.component';
import { RegisterComponent } from './register/register.component';
import { OtpComponent } from './register/otp/otp.component';
import { BasicInfoComponent } from './register/basic-info/basic-info.component';
import { ProfileComponent } from './profile/profile.component';
import { DashboardComponent } from './profile/dashboard/dashboard.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgOtpInputModule } from  'ng-otp-input';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';
import { EditComponent } from './profile/edit/edit.component';
import { StatsComponent } from './profile/stats/stats.component';
import { LinksComponent } from './profile/links/links.component';
import { NavComponent } from './_global/nav/nav.component';
import { ChannelDashboardComponent } from './channel/channel-dashboard/channel-dashboard.component';
import { ChannelItemComponent } from './channel/channel-dashboard/channel-item/channel-item.component';
import { ProfileDetailsComponent } from './profile/profile-details/profile-details.component';
import { AboutComponent } from './profile/about/about.component';

import { MainnavComponent } from './partial/mainnav/mainnav.component';
import { EditProfileComponent } from './profile/edit-profile/edit-profile.component';
import { TopNavComponent } from './_global/top-nav/top-nav.component';
import { MainNavComponent } from './_global/main-nav/main-nav.component';
import { DashLinkFinanceComponent } from './profile/dashboard/dash-link-finance/dash-link-finance.component';
import { DashLinkConnectionsComponent } from './profile/dashboard/dash-link-connections/dash-link-connections.component';
import { DashLinkSettingsComponent } from './profile/dashboard/dash-link-settings/dash-link-settings.component';
import { UserRechargeComponent } from './profile/dashboard/dash-link-finance/user-recharge/user-recharge.component';
import { UserIncomeComponent } from './profile/dashboard/dash-link-finance/user-income/user-income.component';
import { UserTransactionHistoryComponent } from './profile/dashboard/dash-link-finance/user-transaction-history/user-transaction-history.component';
import { UserWalletComponent } from './profile/dashboard/dash-link-finance/user-wallet/user-wallet.component';
import { UserFamilyComponent } from './profile/dashboard/dash-link-connections/user-family/user-family.component';
import { UserFansComponent } from './profile/dashboard/dash-link-connections/user-fans/user-fans.component';
import { UserFollowersComponent } from './profile/dashboard/dash-link-connections/user-followers/user-followers.component';
import { UserLikeComponent } from './profile/dashboard/dash-link-connections/user-like/user-like.component';

import { UserFriendsComponent } from './profile/dashboard/dash-link-connections/user-friends/user-friends.component';
import { UserSubscribersComponent } from './profile/dashboard/dash-link-connections/user-subscribers/user-subscribers.component';
import { UserGuardiansComponent } from './profile/dashboard/dash-link-connections/user-guardians/user-guardians.component';
import { UserPollsComponent } from './profile/dashboard/dash-link-connections/user-polls/user-polls.component';
import { UserLineupComponent } from './profile/dashboard/dash-link-connections/user-lineup/user-lineup.component';
import { UserContestsComponent } from './profile/dashboard/dash-link-connections/user-contests/user-contests.component';
import { UserFollowComponent } from './profile/dashboard/dash-link-connections/user-follow/user-follow.component';
import { UserSettingsBadgeComponent } from './profile/dashboard/dash-link-settings/user-settings-badge/user-settings-badge.component';
import { UserSettingsRecordingsComponent } from './profile/dashboard/dash-link-settings/user-settings-recordings/user-settings-recordings.component';
import { UserSettingsBlockedListComponent } from './profile/dashboard/dash-link-settings/user-settings-blocked-list/user-settings-blocked-list.component';
import { UserSettingsBroadcastComponent } from './profile/dashboard/dash-link-settings/user-settings-broadcast/user-settings-broadcast.component';
import { UserSettingsPersonalizationComponent } from './profile/dashboard/dash-link-settings/user-settings-personalization/user-settings-personalization.component';
import { UserSettingsThemesComponent } from './profile/dashboard/dash-link-settings/user-settings-themes/user-settings-themes.component';
import { UserSettingsEStoreComponent } from './profile/dashboard/dash-link-settings/user-settings-e-store/user-settings-e-store.component';
import { UserSettingsReportsComponent } from './profile/dashboard/dash-link-settings/user-settings-reports/user-settings-reports.component';
import { UserSettingsBroadcastAdministratorsComponent } from './profile/dashboard/dash-link-settings/user-settings-broadcast-administrators/user-settings-broadcast-administrators.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ConvertToCoinsComponent } from './profile/dashboard/dash-link-finance/user-income/convert-to-coins/convert-to-coins.component';
import { CashOutComponent } from './profile/dashboard/dash-link-finance/user-income/cash-out/cash-out.component';
import { PollDetailsComponent } from './profile/dashboard/dash-link-connections/user-polls/poll-details/poll-details.component';
import { BadgeDetailsComponent } from './profile/dashboard/dash-link-settings/user-settings-badge/badge-details/badge-details.component';
import { BroadcastDetailsComponent } from './profile/dashboard/dash-link-settings/user-settings-broadcast/broadcast-details/broadcast-details.component';
import { GoLiveComponent } from './go-live/go-live.component';
import { environment } from 'src/environments/environment';
import { NgxAgoraModule, AgoraConfig } from 'ngx-agora';
import {WebcamModule} from 'ngx-webcam';
import { MultiBeamComponent } from './go-live/multi-beam/multi-beam.component';
import { StandardComponent } from './go-live/standard/standard.component';
import { RetrivePasswordComponent } from './forgot-password/retrive-password/retrive-password.component';
import { ShareButtonsModule } from '@ngx-share/buttons';
import { LiveBrodcastComponent } from './go-live/live-brodcast/live-brodcast.component';
import { MultiLiveBrodcastComponent } from './go-live/multi-live-brodcast/multi-live-brodcast.component';
import { ViewLiveBrodcastComponent } from './go-live/view-live-brodcast/view-live-brodcast.component';
import { SocialLoginModule, AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login"
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { GlobalSearchComponent } from './profile/global-search/global-search.component';
import { TagInputModule } from 'ngx-chips';
import { HeadToHeadComponent } from './go-live/head-to-head/head-to-head.component';
import { CountdownTimerModule } from 'ngx-countdown-timer';
import AgoraRTM from 'agora-rtm-sdk'
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { Inviteh2hComponent } from './go-live/modal/inviteh2h/inviteh2h.component'
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { ActiveBroadcasterModalComponent } from './go-live/modal/inviteh2h/active-broadcaster-modal/active-broadcaster-modal.component';
import { FansFriendsFamilyFollowerModalComponent } from './go-live/modal/inviteh2h/fans-friends-family-follower-modal/fans-friends-family-follower-modal.component';
import { H2hrequestComponent } from './_modal/h2hrequest/h2hrequest.component';
import { DetailSearchComponent } from './profile/global-search/detail-search/detail-search.component';


import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { UserlineupComponent } from './profile/about/userlineup/userlineup.component';
import { OTPForgotPasswordComponent } from './forgot-password/otp-forgot-password/otp-forgot-password.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SetLogoModalComponent } from './_modal/set-logo-modal/set-logo-modal.component';
import { InviteByUsernameModalComponent } from './go-live/modal/inviteh2h/invite-by-username-modal/invite-by-username-modal.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormFieldModule, MatButtonModule, MatInputModule, MatRippleModule} from '@angular/material';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { NgEnterDirective } from './_directive/ng-enter.directive';
import { InviteGuestComponent } from './go-live/modal/invite-guest/invite-guest.component';
import { AddUpdatePollsModalComponent } from './profile/dashboard/dash-link-connections/user-polls/add-update-polls-modal/add-update-polls-modal.component';
import { AssignPollsModalComponent } from './go-live/modal/assign-polls-modal/assign-polls-modal.component';
import { VotePollsModalComponent } from './go-live/modal/vote-polls-modal/vote-polls-modal.component';
import { GiftModalComponent } from './go-live/modal/gift-modal/gift-modal.component';
import { ChatComponent } from './chat/chat.component';
import { ChatDetailsComponent } from './chat/chat-details/chat-details.component';
import { UserPointComponent } from './profile/dashboard/dash-link-finance/user-point/user-point.component';
import { ChatUserComponent } from './chat/chat-user/chat-user.component';
import { DiamondsTlogComponent } from './profile/dashboard/dash-link-finance/user-income/diamonds-tlog/diamonds-tlog.component';
import { MultibeamRequestComponent } from './go-live/modal/multibeam-request/multibeam-request.component';


import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireModule } from "@angular/fire";
import { MessagingService } from "./service/messaging.service";
import { AsyncPipe } from "../../node_modules/@angular/common";
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { LeaderboardComponent } from './profile/dashboard/dash-link-connections/leaderboard/leaderboard.component';
import { CoinDropComponent } from './go-live/modal/coin-drop/coin-drop.component';
import { BlockUserModalComponent } from './go-live/modal/block-user-modal/block-user-modal.component';
import { ViewPollDetailsComponent } from './profile/dashboard/dash-link-connections/user-polls/poll-details/view-poll-details/view-poll-details.component';

import { ChatRequestModalComponent } from './chat/modal/chat-request-modal/chat-request-modal.component';
import { BuyStoreCreditComponent } from './profile/dashboard/dash-link-settings/user-settings-recordings/modal/buy-store-credit/buy-store-credit.component';
import { ViewVideoComponent } from './profile/dashboard/dash-link-settings/user-settings-recordings/modal/view-video/view-video.component';
import { StripeComponent } from './_modal/stripe/stripe.component';
import { Module as StripeModule } from "stripe-angular"

import { SocialInfoComponent } from './login/social-info/social-info.component';
import { LiveGuidesComponent } from './channel/live-guides/live-guides.component';
import { UserStoreCreditRechargeComponent } from './profile/dashboard/dash-link-finance/user-store-credit-recharge/user-store-credit-recharge.component';
import { AllChatUserComponent } from './chat/all-chat-user/all-chat-user.component';
import { NotificationComponent } from './_global/notification/notification.component';
import { NotificationModalComponent } from './_global/notification/notification-modal/notification-modal.component';
import { BroadcastInfoModalComponent } from './go-live/modal/broadcast-info-modal/broadcast-info-modal.component';
import { BankdetailsComponent } from './profile/dashboard/dash-link-finance/bankdetails/bankdetails.component';


const fbLoginOptions= {
    scope: 'email',
    return_scopes: true,
    enable_profile_selector: true
  }; // https://developers.facebook.com/docs/reference/javascript/FB.login/v2.11
   
  const googleLoginOptions= {
    scope: 'profile email'
  }; // https://developers.google.com/api-client-library/javascript/reference/referencedocs#gapiauth2clientconfig
   
  let config = new AuthServiceConfig([
    {
      id: GoogleLoginProvider.PROVIDER_ID,
      provider: new GoogleLoginProvider("176216882015-c2kuptsg37di0qn3odghbplv4smtpa8q.apps.googleusercontent.com", googleLoginOptions)
    },
    {
      id: FacebookLoginProvider.PROVIDER_ID,
      provider: new FacebookLoginProvider("2579065955505655", fbLoginOptions)
    }
  ]);

  export function provideConfig() {
    return config;
  }
  
@NgModule({
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        appRoutingModule,
        NgxSpinnerModule,
        CommonModule,
        NgOtpInputModule,
        WebcamModule,
        BrowserAnimationsModule, // required animations module
        ToastrModule.forRoot(),  // ToastrModule added
        NgxAgoraModule.forRoot({ AppID: environment.agora.appId }),
        ShareButtonsModule,
        SocialLoginModule,
        TagInputModule,
        BsDatepickerModule.forRoot(),
        ModalModule.forRoot(),
        CountdownTimerModule.forRoot(),
        TimepickerModule.forRoot(),
        NgbModule,
        InfiniteScrollModule,
        NgMultiSelectDropDownModule.forRoot(),
        MatAutocompleteModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatRippleModule,
        PickerModule,
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        AngularFireMessagingModule,
        AngularFireModule.initializeApp(environment.firebase),
        //StripeModule.forRoot(environment.STRIPEKEY)
        StripeModule.forRoot()
    ],
    declarations: [
        AppComponent,
        HomeComponent,
        LoginComponent,
        MobileLoginComponent,
        ChannelComponent,
        RegisterComponent,
        OtpComponent,
        BasicInfoComponent,
        ProfileComponent,
        DashboardComponent,
        EditComponent,
        StatsComponent,
        LinksComponent,
        NavComponent,
        ChannelDashboardComponent,
        ChannelItemComponent,
        ProfileDetailsComponent,
        MainnavComponent,
        EditProfileComponent,
        AboutComponent,
        TopNavComponent,
        MainNavComponent,
        DashLinkFinanceComponent,
        DashLinkConnectionsComponent,
        DashLinkSettingsComponent,
        UserRechargeComponent,
        UserIncomeComponent,
        UserTransactionHistoryComponent,
        UserWalletComponent,
        UserFamilyComponent,
        UserFansComponent,
        UserFollowersComponent,
        UserLikeComponent,
        UserFriendsComponent,
        UserSubscribersComponent,
        UserGuardiansComponent,
        UserPollsComponent,
        UserLineupComponent,
        UserContestsComponent,
        UserFollowComponent,
        UserSettingsBadgeComponent,
        UserSettingsRecordingsComponent,
        UserSettingsBlockedListComponent,
        UserSettingsBroadcastComponent,
        UserSettingsPersonalizationComponent,
        UserSettingsThemesComponent,
        UserSettingsEStoreComponent,
        UserSettingsReportsComponent,
        UserSettingsBroadcastAdministratorsComponent,
        ForgotPasswordComponent,
        ConvertToCoinsComponent,
        CashOutComponent,
        PollDetailsComponent,
        BadgeDetailsComponent,
        BroadcastDetailsComponent,
        GoLiveComponent,
        MultiBeamComponent,
        StandardComponent,
        RetrivePasswordComponent,
        LiveBrodcastComponent,
        MultiLiveBrodcastComponent,
        ViewLiveBrodcastComponent,
        GlobalSearchComponent,
        HeadToHeadComponent,
        Inviteh2hComponent,
        ActiveBroadcasterModalComponent,
        FansFriendsFamilyFollowerModalComponent,
        H2hrequestComponent,
        DetailSearchComponent,
        UserlineupComponent,
        OTPForgotPasswordComponent,
        SetLogoModalComponent,
        InviteByUsernameModalComponent,
        NgEnterDirective,
        InviteGuestComponent,
        AddUpdatePollsModalComponent,
        AssignPollsModalComponent,
        VotePollsModalComponent,
        GiftModalComponent,
        ChatComponent,
        ChatDetailsComponent,
        UserPointComponent,
        ChatUserComponent,
        DiamondsTlogComponent,

        LeaderboardComponent,
        MultibeamRequestComponent,
        CoinDropComponent,
        BlockUserModalComponent,
        ViewPollDetailsComponent,
        ChatRequestModalComponent,
        BuyStoreCreditComponent,
        ViewVideoComponent,
        StripeComponent,
        SocialInfoComponent,
        LiveGuidesComponent,
        UserStoreCreditRechargeComponent,
        AllChatUserComponent,
        NotificationComponent,
        NotificationModalComponent,
        BroadcastInfoModalComponent,
        BankdetailsComponent,
    ],
    entryComponents: [
      Inviteh2hComponent,
      ActiveBroadcasterModalComponent,
      FansFriendsFamilyFollowerModalComponent,
      H2hrequestComponent,
      SetLogoModalComponent,
      InviteByUsernameModalComponent,
      InviteGuestComponent,
      AddUpdatePollsModalComponent,
      AssignPollsModalComponent,
      VotePollsModalComponent,
      GiftModalComponent,
      MultibeamRequestComponent,
      CoinDropComponent,
      BlockUserModalComponent,
      ChatRequestModalComponent,
      BuyStoreCreditComponent,
      ViewVideoComponent,
      StripeComponent,
      NotificationModalComponent,
      BroadcastInfoModalComponent,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        {
            provide: AuthServiceConfig,
            useFactory: provideConfig
          },
          MessagingService,AsyncPipe
        // provider used to create fake backend fakeBackendProvider
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
//platformBrowserDynamic().bootstrapModule(AppModule);
//node --max_old_space_size=8192 node_modules/@angular/cli/bin/ng build --prod
