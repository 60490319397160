import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PollsService {

  constructor(private http: HttpClient) { }

  addUserPolls(requestData) {
    return this.http.post<any>(`${environment.apiUrl}app/userpolls/addUserPolls`, requestData);
  }

  addPolsWithBroadcast(requestData) {
    return this.http.post<any>(`${environment.apiUrl}app/userpolls/addPolsWithBroadcast`, requestData);
  }

  ansPollsQuestions(requestData) {
    return this.http.post<any>(`${environment.apiUrl}app/userpolls/ansPollsQuestions`, requestData);
  }

  getPollsByBroadcast(requestData) {
    return this.http.post<any>(`${environment.apiUrl}app/userpolls/getPollsByBroadcast`, requestData);
  }

  deletePoll(requestData) {
    return this.http.post<any>(`${environment.apiUrl}app/userpolls/deletePoll`, requestData);
  }

  updateStatus(requestData) {
    return this.http.post<any>(`${environment.apiUrl}app/userpolls/ActivePoll`, requestData);
  }

  sendGift(requestData) {
    return this.http.post<any>(`${environment.apiUrl}app/gift/sendGift`, requestData);
  }

  getAllPollsByUser(requestData) {
    return this.http.get<any>(`${environment.apiUrl}app/userpolls/getAllPollsByUser?userID=`+requestData.userID);
  }

  getAllActivePollsByUser(requestData) {
    return this.http.get<any>(`${environment.apiUrl}app/userpolls/getAllPollsByUser?isActive=1&userID=`+requestData.userID);
  }

  getAllActivePollsByUserToAssign(requestData) {
    return this.http.get<any>(`${environment.apiUrl}app/userpolls/getAllPollsByUser?isActive=1&forBroadcast=1&userID=`+requestData.userID);
  }

  getAllActivePollsByUserForBroadcast(requestData) {
    return this.http.get<any>(`${environment.apiUrl}app/userpolls/getAllPollsByUser?isActive=1&forBroadcast=`+requestData.forBroadcast+`&userID=`+requestData.userID);
  }
  getAllGift(requestData) {
    return this.http.get<any>(`${environment.apiUrl}app/gift/getAllGift`);
  }
}
