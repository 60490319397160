import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { PollsService } from 'src/app/_services/polls.service';

@Component({
  selector: 'app-vote-polls-modal',
  templateUrl: './vote-polls-modal.component.html',
  styleUrls: ['./vote-polls-modal.component.scss']
})
export class VotePollsModalComponent implements OnInit {
  
  @Input() userId;
  @Input() broadcastID;
  @Output() pollAns = new EventEmitter();
  polls = [];

  constructor(
    public activeModal: NgbActiveModal,
    public spinner: NgxSpinnerService,
    public toastr: ToastrService,
    public pollsService: PollsService,
    private authenticationService: AuthenticationService,
  ) { }

  ngOnInit() {
    this.getPolls()
  }

  vote(broadcastID, pollsID, questionID, optionID){
   let currentUser: any = this.authenticationService.currentUserValue;
   this.pollsService.ansPollsQuestions({
    broadcastID : broadcastID,
    userID : currentUser.profile.FKuserID,
    pollsID : pollsID,
    questionID : questionID,
    optionID : optionID,
   }).subscribe(
     response =>{
      this.pollAns.emit(response.data);
      this.getPolls();
     },
     error => {

     }
   )
  }
  getPolls(){
    this.spinner.show();
    let currentUser: any = this.authenticationService.currentUserValue;
    this.polls = [];
    this.pollsService.getPollsByBroadcast({
      userID : currentUser.profile.FKuserID,
      broadcastID : this.broadcastID
    }).subscribe(
      response => {
        this.spinner.hide();
        if(response.data)
          this.polls.push(response.data);
      },
      error => {
 
      }
    )
   }
}
