import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { BroadcastService } from 'src/app/_services/broadcast.service';

@Component({
  selector: 'app-coin-drop',
  templateUrl: './coin-drop.component.html',
  styleUrls: ['./coin-drop.component.scss']
})
export class CoinDropComponent implements OnInit {
  @Input() userId;
  @Input() broadcastID;
  @Output() coinDropped = new EventEmitter();
  coinsPerCatch = 1;
  coins = 100;
  user: any;

  constructor(
    public activeModal: NgbActiveModal,
    public spinner: NgxSpinnerService,
    public toastr: ToastrService,
    private authenticationService: AuthenticationService,
    private broadcastService: BroadcastService,
  ) { }

  ngOnInit() {
    this.user = this.authenticationService.currentUserValue;
  }

  drop() {
    if (this.coins <= 0) {
      return this.toastr.error('Coin you entered is not valid', 'Error!', {
        timeOut: 5000,
        progressBar: true,
        closeButton: true
      });
    }
    this.spinner.show();
    this.broadcastService.dropCoins({
      userID : this.userId,
      broadcastID : this.broadcastID,
      coins : this.coins,
      coinsPerCatch : this.coinsPerCatch,
    }).subscribe(
      response => {
        console.log('Coin Drop', response);
        if (response.error) {
          this.toastr.error(response.message, 'Error!', {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
        } else {
          this.toastr.success(response.message, 'Success!', {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
          this.coinDropped.emit(response.data);
          this.activeModal.close('Close click');
        }
        this.spinner.hide();
      },
      error => {

      }
    );
  }

}
