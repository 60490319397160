import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService, UserService } from '../../_services';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ViewVideoComponent } from '../dashboard/dash-link-settings/user-settings-recordings/modal/view-video/view-video.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  @Input('userId') userId: any;
  user: any
  returnUrl: any
  currentUser: any
  isCurrentUser = false;
  isAuth = false
  visitorId: any;
  videos = []
  isMale = false;
  data = {
    about: '',
    firstName:'',
    lastName:'',
    totalCoinsFormated: '',
    profileID:'',
    userLevel: '',
    totalFansFormated: '',
    totalFollowersFormated: '',
    totalfriendsFormated: '',
    totalLikeFormated: '',
    totalDiamond:'',
    guardian: [
      // {
      //   id :'',
      //   name :'NikkiSports201',
      //   imgUrl : 'assets/images/profile_view/sports_01.png'
      // },
      // {
      //   id :'',
      //   name :'LiveSports2020',
      //   imgUrl : 'assets/images/profile_view/sports_02.png'
      // }
    ],
    badges: [
      // {
      //   name :'Lv.95 Badge',
      //   imgUrl : 'assets/images/profile_view/trophy.png'
      // },
      // {
      //   name :'Lv.100 Badge',
      //   imgUrl : 'assets/images/profile_view/baches.png'
      // }
    ],
    interests: [
      // {
      //   name :'Music',
      //   imgUrl : 'assets/images/profile_view/guiter.png'
      // },
      // {
      //   name :'Games',
      //   imgUrl : 'assets/images/profile_view/game.png'
      // }
    ],
    channels: [
      // {
      //   name :'Music',
      //   id : ''
      // },
      // {
      //   name :'Games',
      //   id : ''
      // }
    ],
    hashtags: [
      // {
      //   name :'Music',
      // },
      // {
      //   name :'Beauty',
      // }
    ],
    hideMyInterest: true,
  }

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    if (this.userId) {

      let currentUser: any = this.authenticationService.currentUserValue;
      console.log("about ", currentUser);
      this.userService.getUserDetails({ id: this.userId, userID: currentUser ? currentUser.profile.FKuserID : 0 }).subscribe(
        response => {
          if (response.error) {
            //Error

          } else {
            this.user = response.data;
            if (this.user.profile.gender === "1") {
              this.isMale = true
            } else {
              this.isMale = false
            }
            this.data.about = this.user.profile.aboutMe;
             
            this.visitorId = this.user.profile.FKuserID;
            this.data.firstName = this.user.profile.firstName
            this.data.lastName = this.user.profile.lastName
            this.data.profileID = this.user.profile.profileID
            this.data.totalCoinsFormated = this.user.profile.Stat.totalCoinsFormated
            this.data.totalDiamond = this.user.profile.Stat.totalDiamondsCollectedFormated
            this.data.userLevel = this.user.profile.Stat.userLevel
            this.data.totalFansFormated = this.user.profile.Stat.totalFansFormated
            this.data.totalFollowersFormated = this.user.profile.Stat.totalFollowersFormated
            this.data.totalfriendsFormated = this.user.profile.Stat.totalfriendsFormated
            this.data.totalLikeFormated = this.user.profile.Stat.totalLikeFormated

            if (this.user.profile.profileSettings) {
              this.data.hideMyInterest = this.user.profile.profileSettings.hideMyInterest !='1' ? false : true;
            }

            this.user.profile.HashTags.forEach(element => {
              this.data.hashtags.push({ name: element.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') })
            });
            
            this.user.profile.Channel.forEach(element => {
              this.data.channels.push(element)
            });

            this.user.profile.Interest.forEach(element => {
              this.data.interests.push(element)
            });

            this.user.profile.Badge.forEach(element => {
              this.data.badges.push(element)
            });
            this.currentUser = JSON.parse(localStorage.getItem('currentUser'))
            if (this.currentUser) {
              if (this.user && this.currentUser.profile.username === this.user.profile.username) {
                this.isCurrentUser = true;
              }
              this.isAuth = true;
            } else {
              this.isAuth = false;
            }

            this.userService.getAllVideosForUser({
              userID: this.user.profile.FKuserID
            }).subscribe(
              response => {
                this.videos = response.data;
                console.log("All Videos", response)
              }
            )
          }
        },
        error => {
          //Error
        }
      );
    }

    // get return url from route parameters or default to '/channels'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/channels';
  }
  view(url){
    const modalRef = this.modalService.open(ViewVideoComponent);
    modalRef.componentInstance.url = url;
  }

}
