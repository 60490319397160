import { Component, OnInit, Renderer2, Inject,TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService, GlobalService, UploadFileService, UserService } from '../../../../../_services';
import { NgxSpinnerService } from "ngx-spinner";

import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';
import { RechargeService } from 'src/app/_services/recharge.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-cash-out',
  templateUrl: './cash-out.component.html',
  styleUrls: ['./cash-out.component.css']
})
export class CashOutComponent implements OnInit {
  isDashboardMode = true
  title = 'Cash Out'
  color = 'cashout_headerbg'
    
  coins = 0;
  diamonds_available = 0;
  total_cash_out = 0;
  cashoutOPtions = [];
  tLog: any;
  totalCashOut: number;
  modalRef: BsModalRef;
  cashOutComponentData: any = [];
  diamondAmount ='';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private modalService: BsModalService,

    private renderer: Renderer2,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private globalService: GlobalService,
    private userService: UserService,
    private rechargeService: RechargeService,

    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.getUserStat();
    this.getCashoutOPtions();
    this.getTransactionLog();
  }

    
  getCashoutOPtions(){
    this.spinner.show();
    this.rechargeService.getCashoutOPtions().subscribe(
      response => {
        this.cashoutOPtions = response.data;
        this.spinner.hide();
      },
      error => {

      }
    );
  }
  // openModal(template: TemplateRef<any>,amount) {
    
  //   this.modalRef = this.modalService.show(template);
  //  this.cashoutConfirm(amount)


  // }
  cashoutConfirm(template: TemplateRef<any>,amount){
    this.spinner.show();
    let currentUser: any = this.authenticationService.currentUserValue;
    const request = {
      amount : amount,
      userID : currentUser.profile.FKuserID
    }
    this.rechargeService.cashoutConfirm(request).subscribe(
      response => {

        if (!response.error) {

          console.log(">>>>",response.data);
          this.cashOutComponentData = response.data;
          this.diamondAmount = amount;
          this.modalRef = this.modalService.show(template);
             // this.toastr.success(response.message, 'Cash Out!', {
             //   timeOut: 5000,
             //   progressBar: true,
             //   closeButton: true
             // });
             this.spinner.hide();
          //Error
        } else {
          this.spinner.hide();
         // this.modalRef.hide();
          this.toastr.error(response.message, 'Error!', {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
        }




      },
      error => {


      }
    );
  }
  cashout(amount){
    this.spinner.show();
    let currentUser: any = this.authenticationService.currentUserValue;
    const request = {
      amount : amount,
      userID : currentUser.profile.FKuserID
    }
    this.rechargeService.cashout(request).subscribe(
      response => {
        this.getUserStat();
        this.getTransactionLog();
        this.modalRef.hide();
        this.toastr.success(response.message, 'Cash Out!', {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
        this.spinner.hide();
      },
      error => {

      }
    );
  }
  
  getUserStat(){
    this.spinner.show();
    let currentUser: any = this.authenticationService.currentUserValue;

    const requestData = {
      userID: currentUser.profile.FKuserID,
    }
    this.rechargeService.getUserStat(requestData).subscribe(
      response => {
        this.coins = response.data.totalCoinsFormated
        this.diamonds_available = response.data.totalDiamondsFormated
        this.total_cash_out = response.data.totalCashout
        this.spinner.hide();
      },
      error => {

      }
    )
  }

  getTransactionLog(){
    this.spinner.show();
    let currentUser: any = this.authenticationService.currentUserValue;

    const requestData = {
      userID: currentUser.profile.FKuserID,
      type: "cashout",
    }
    this.rechargeService.getTransactionLog(requestData).subscribe(
      response => {
        this.tLog = response.data
        this.totalCashOut = 0
        response.data.forEach(element => {
          console.log("log", element.amount);
          this.totalCashOut = this.totalCashOut + parseFloat(element.amount)
        });
        this.spinner.hide();
      },
      error => {

      }
    )
  }


}
