import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BroadcastService } from 'src/app/_services/broadcast.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-active-broadcaster-modal',
  templateUrl: './active-broadcaster-modal.component.html',
  styleUrls: ['./active-broadcaster-modal.component.scss']
})
export class ActiveBroadcasterModalComponent implements OnInit {
  @Input() userId;
  @Input() broadcastID;
  
  userList:any
  constructor(
    public activeModal: NgbActiveModal,
    public broadcastService: BroadcastService,
    public spinner: NgxSpinnerService,
    public toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.spinner.show()
    
    this.broadcastService.getActiveBroadcasterListForHeadToHead({
      userID: this.userId,
    }).subscribe(
      response => {
        if (!response.error) {
          this.userList = response.data.activeBroadcaster
        }
        this.spinner.hide()
      },
      error => {

      }
    )
  }

  invite(toUserID){
    this.spinner.show();
    this.broadcastService.inviteUserForHeadToHead({
      userID : this.userId,
      broadcastID : this.broadcastID,
      toUserID : toUserID
    }).subscribe(
      response => {
        if (response.error) {
          //Error
          this.spinner.hide();
          return  this.toastr.error(response.message, 'Error!', {
            timeOut: 2000,
          });
        } else {
          //Success
          this.spinner.hide();
          return  this.toastr.success("Invitation Sent", 'Success!', {
            timeOut: 2000,
          });
        }
        this.spinner.hide();
      },
      error => {

      }
    )
  }

}
