import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService, GlobalService, UploadFileService } from '../../_services';
import { NgxSpinnerService } from "ngx-spinner";

import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';
import { ValidateEmailNotTaken, ValidateUsernameNotTaken } from 'src/app/validators';
import * as moment from 'moment'; 
import { PasswordValidator } from '../../_validators/password.validator';

@Component({
  selector: 'app-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.css']
})
export class BasicInfoComponent implements OnInit {
  selectedFiles: FileList;

  returnUrl :string = null
  profileImgUrl :string = ''
  error :any
  loading :any
  
  customerId :string = null
  firstName :string
  lastName :string
  submitted  = false
  
  maxDate:any;

  basicInfoForm: FormGroup;
  
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private uploadService: UploadFileService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private globalService: GlobalService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.maxDate = new Date();
   
    this.maxDate.setDate(this.maxDate.getDate() - 6570);
    this.renderer.addClass(this.document.body, 'signup-with-phone');

    let requiredData = JSON.parse(localStorage.getItem('verify-otp'));

    this.basicInfoForm = this.formBuilder.group({
      phoneNo: [requiredData.mobile, [Validators.required]],
      countryCode: [requiredData.country_code, Validators.required],
      username: ['', [Validators.required,Validators.minLength(6)]],
      email: ['', [Validators.required, Validators.email]],
      password: [
        '',
        [
          Validators.required,
          PasswordValidator.strong
         ]
      ],
      cnfPassword: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      //aboutMe: [''],
      dob: ['',Validators.required],
      gender: ['male',Validators.required],
      agree: [false,Validators.pattern('true')],
    }, {validator: this.checkPasswords });

    this.basicInfoForm.controls['email'].setAsyncValidators(ValidateEmailNotTaken.createValidator(this.globalService, this.customerId));
    this.basicInfoForm.controls['username'].setAsyncValidators(ValidateUsernameNotTaken.createValidator(this.globalService, this.customerId));
    // get return url from route parameters or default to '/channels'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/channels';
  }
  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
  let pass = group.get('password').value;
  let cnfPassword = group.get('cnfPassword').value;

    return pass === cnfPassword ? null : { notSame: true }     
  }
  // convenience getter for easy access to form fields
  get f() { return this.basicInfoForm.controls; }
  get fe() { return this.basicInfoForm.errors; }
  get email() { return this.basicInfoForm.get('email') }
  get username() { return this.basicInfoForm.get('username') }

  //Prefered not to use uploadService for security issue
  selectFile(event) {
    this.spinner.show();
    this.selectedFiles = event.target.files;
    const file = this.selectedFiles.item(0);
    this.uploadService.uploadFile(file).subscribe(
      response => {
        console.log("response", response)
        this.profileImgUrl = response.data
        this.spinner.hide();
      },
      error=>{
        console.log("Error", error)
        this.spinner.hide();
      }
    )
  }

  onSubmit() {
    this.spinner.show();
    this.submitted = true
    // stop here if form is invalid
    if (this.basicInfoForm.invalid) {
        console.log('basicInfoFormError', this.basicInfoForm);

        this.spinner.hide();
        return;
    }
    const requestData = {
      phoneNo : this.f.phoneNo.value,
      countryCode : '+'+this.f.countryCode.value,
      username: this.f.username.value,
      email: this.f.email.value,
      password: this.f.password.value,
      cnfPassword: this.f.cnfPassword.value,
      firstName: this.f.firstName.value,
      lastName: this.f.lastName.value,
      //aboutMe: this.f.aboutMe.value,
      dob: moment(this.f.dob.value).format('MM/DD/YYYY'),
      gender: this.f.gender.value ? this.f.gender.value === 'male' ? 1 : 2 : null ,
      deviceType: 'web',
      apnsToken: '222',
      Avtar: this.profileImgUrl,
    }
    this.authenticationService.register(requestData).subscribe(
      response => {
        if (response.error) {
          //Error
          this.toastr.error(response.message, 'Error!', {
            timeOut: 2000,
            progressBar: true,
            closeButton: true
          });
        } else {
          //Success
          this.loading = true;
          const requestData = {
            username : this.f.username.value,
            password : this.f.password.value,
            apnsToken : '222', // Need Fix. Used for Push Notification
            deviceType : 'web'
          }
          // this.toastr.success(response.message, 'Success!', {
          //   timeOut: 2000,
          //   progressBar: true,
          //   closeButton: true
          // });
          this.authenticationService.login(requestData)
              .pipe(first())
              .subscribe(
                  response => {
                      console.log('response', response)
                      this.spinner.hide();
                      if (!response.error) {
                        localStorage.removeItem('verify-otp')
                        this.router.navigate([this.returnUrl]);
                      } else {
                        if (response.message) {
                          this.toastr.error(response.message, 'Error!', {
                            timeOut: 2000,
                            progressBar: true,
                            closeButton: true
                          });
                        }else {
                          this.toastr.error('Sorry, something went wrong there. Try again', 'Error!', {
                            timeOut: 2000,
                            progressBar: true,
                            closeButton: true
                          });
                        }
                      }
                      
                  },
                  error => {
                      this.spinner.hide();
                      this.error = error;
                      this.loading = false;
              });
        }
      },
      error => {
        this.toastr.error("Sorry, something went wrong there. Try again", 'Error!', {
          timeOut: 2000,
          progressBar: true,
          closeButton: true
        });
      }
    )
  }
}
