import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService, UserService } from '../../../_services';
import * as moment from 'moment';

@Component({
  selector: 'app-detail-search',
  styles: [
    `
      .search-results {
        width:700px;
       
      }
    `
  ],
  templateUrl: './detail-search.component.html',
  styleUrls: ['./detail-search.component.scss']
})
export class DetailSearchComponent implements OnInit {
  isDashboardMode = true
  title = 'More Search Result'
  color = 'my-badge-head-bg'
  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService
  ) { }
  searchType: any;
  searchValue: any;
  searchResultData: any;
  boradcustData: any;
  moment: any = moment;
  clicks = 0;


  onScroll() {



    let s: any;
    if (!this.searchResultData) {
      s = 0;
    }
    else {
      this.clicks += 1;
      s = 10 * this.clicks;


    }

    this.spinner.show();
    this.route.paramMap.subscribe(params => {
      this.searchType = params.get('type');
      this.searchValue = params.get('name');

      this.userService.globalSearch({ keywored: this.searchValue, type: this.searchType, start: s }).subscribe(
        response => {
          if (response.error) {
            this.spinner.hide();
            //Error
          } else {
            //Success
            this.searchResultData = response.data;


            if (this.searchResultData && this.searchResultData.BROADCAST && this.searchResultData.BROADCAST.data && this.searchResultData.BROADCAST.data.length > 0) {

              this.boradcustData = this.boradcustData.concat(this.searchResultData.BROADCAST.data);


            }

            else if (this.searchResultData && this.searchResultData.USER && this.searchResultData.USER.data && this.searchResultData.USER.data.length > 0) {

              this.boradcustData = this.boradcustData.concat(this.searchResultData.USER.data);


            }
            else {
              this.boradcustData = this.boradcustData;
            }



            this.spinner.hide();
          }
        },
        error => {
          this.spinner.hide();
          //Error
        }
      )
    });
  }
  ngOnInit() {
    window.scroll(0, 0);
    this.spinner.show();
    this.route.paramMap.subscribe(params => {
      this.searchType = params.get('type');
      this.searchValue = params.get('name');

      this.userService.globalSearch({ keywored: this.searchValue, type: this.searchType }).subscribe(
        response => {
          if (response.error) {
            this.spinner.hide();
            //Error
          } else {
            //Success
            this.searchResultData = response.data;


            if (this.searchResultData && this.searchResultData.BROADCAST && this.searchResultData.BROADCAST.data) {
              this.boradcustData = this.searchResultData.BROADCAST.data;
            }
          
           else if (this.searchResultData && this.searchResultData.USER && this.searchResultData.USER.data) {
              this.boradcustData = this.searchResultData.USER.data;
            }
            else {
              this.boradcustData = [];
            }

            console.log("cccccc>>>",this.boradcustData);


            this.spinner.hide();
          }
        },
        error => {
          this.spinner.hide();
          //Error
        }
      )
    });
  }

}
