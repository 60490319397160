import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService, UserService, UploadFileService } from '../../_services';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ChatService } from '../../_services/chat.service';
import { NgxSpinnerService } from 'ngx-spinner';
import AgoraRTM from 'agora-rtm-sdk';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChatRequestModalComponent } from '../modal/chat-request-modal/chat-request-modal.component';
import * as moment from 'moment';

@Component({
  selector: 'app-chat-details',
  templateUrl: './chat-details.component.html',
  styleUrls: ['./chat-details.component.scss']
})
export class ChatDetailsComponent implements OnInit {
  isDashboardMode = true;
  title = 'Scott Perkings';
  color = 'chat_head-bg';
  sub: any;
  message: string;
  userId: string;
  toUserID: string;
  user: any;
  fromUserID: any;
  chatRoomID: any;
  chatRoomKey: any;
  chatRoomName = 'Loading...';
  ChatChannelToken: any;
  ChatChannel: any;
  msgType = '1';
  chats = [];
  agoraRTM: any = AgoraRTM.createInstance(environment.agora.appId);
  tokenForChat: any;
  ChatUser: any;
  container: HTMLElement;
  chatRoomImage: any;
  FKchatRoomID: any;
  selectedFiles: any;
  mediaImgUrl: any;
  showAlert = false;
  moment: any = moment;
  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private chatService: ChatService,
    private spinner: NgxSpinnerService,
    private uploadService: UploadFileService,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {

    this.spinner.show();
    this.sub = this.route.paramMap.subscribe(params => {
      this.user = JSON.parse(localStorage.getItem('currentUser'));

      this.fromUserID = this.user.profile.FKuserID;

      this.tokenForChat = this.user.profile.tokenForChat;
      this.ChatUser = this.user.profile.ChatUser;
      this.toUserID = params.get('id');

      this.chatService.checkChatRoom({
        toUserID: this.toUserID,
        fromUserID: this.fromUserID
      }).subscribe(
        response => {
          this.chatRoomID = response.data.chatRoomID;
          this.chatRoomKey = response.data.chatRoomKey;

          this.chatService.getAllChatRoomsByID({
            fromUserID: this.fromUserID,
            chatRoomID: this.chatRoomID,
            chatRoomKey: this.chatRoomKey,
          }).subscribe(
            response => {
              this.chatRoomName = response.data[0].chatRoomName;

              this.ChatChannelToken = response.data[0].ChatChannelToken;
              this.ChatChannel = response.data[0].ChatChannel;
              this.chatRoomImage = response.data[0].chatRoomImage;
              this.FKchatRoomID = response.data[0].chatRoomSettings.FKchatRoomID;
              if (response.data[0].isStartChat == '1') {
                this.showAlert = false;
              } else {
                this.showAlert = true;
              }
              console.log(this.ChatChannelToken);
              this.agoraRTM.login({ token: this.tokenForChat, uid: this.ChatUser }).then(() => {
                console.log('AgoraRTM client login success');
                this.subscribeChannelEvents();
              }).catch(err => {
                console.log('AgoraRTM client login failure', err);
              });

              this.getAllChatByChatRoom();
              this.spinner.hide();
            },
            error => {
              this.spinner.hide();
            }
          );

        },
        error => {
          this.spinner.hide();
        }
      );
    });
  }

  rtmLogout(e) {
    this.agoraRTM.logout();
  }
  ngAfterViewInit() {

  }

  private subscribeChannelEvents(): void {
    this.agoraRTM.on('MessageFromPeer', (message, peerId) => {
      const enc = new TextDecoder('utf-8');
      const senderMessage = JSON.parse(enc.decode(message.rawMessage));


      
      if (this.FKchatRoomID === senderMessage.FKchatRoomID) {
        this.toastr.info('1 new message received', 'Info!', {
          timeOut: 2000,
          progressBar: true,
          closeButton: true
        });


        this.container = document.getElementById('msgContainer');
        // this.getAllChatByChatRoom()
        if (this.container) {
          this.chats.push(senderMessage);
          this.container.scrollIntoView({ behavior: 'smooth' });
        }
        console.log('MessageFromPeer', senderMessage, peerId);
      }
    });
  }
  getAllChatByChatRoom() {
    // this.spinner.show();
    this.chatService.getAllChatByChatRoom({
      fromUserID: this.fromUserID,
      chatRoomID: this.chatRoomID,
      chatRoomKey: this.chatRoomKey,
      start: 0,
      limit: 10
    }).subscribe(
      response => {
        // this.spinner.hide();
        if (response.data) {
          this.chats = response.data.reverse();

        }
        if (response.OtersCharRoomSettings) {
          if (response.OtersCharRoomSettings.isEnableChat == '1') {
            this.showAlert = false
          } else {
            this.showAlert = true
          }
          
        }
      },
      error => {

      }
    );
  }

  sendMessage(thumbImage = '') {
    if (thumbImage) {
      this.msgType = '2';
    } else {
      this.msgType = '1';
    }
    this.spinner.show();
    this.chatService.chatRoomSendMsg({
      fromUserID: this.fromUserID,
      chatRoomID: this.chatRoomID,
      chatRoomKey: this.chatRoomKey,
      msgType: this.msgType,
      localMsgID: '',
      playTime: '',
      thumbImage,
      msg: this.message ? this.message : thumbImage,
    }).subscribe(
      response => {
        console.log('Response', response.data.length)
        this.spinner.hide();
        if (response.data.length !== 0) {
          response.data.Avtar = this.user.profile.Avtar;
          const encoder = new TextEncoder();
          const rawMessage = encoder.encode(JSON.stringify(response.data));
          this.agoraRTM.sendMessageToPeer({ rawMessage, messageType: 'RAW', description: '' }, this.chatRoomName).then(() => {
            this.getAllChatByChatRoom();
            this.message = '';
          }).catch(error => {

          });
        } else {
          // Open invite modal
          console.log('Open modal')
          const modalRef = this.modalService.open(ChatRequestModalComponent);
          modalRef.componentInstance.chatRoomKey = this.chatRoomKey;
          modalRef.componentInstance.chatRoomID = this.chatRoomID;
          modalRef.componentInstance.userID = this.fromUserID;
        }
      },
      error => {
        this.spinner.hide();
      }
    );
  }
  selectFile(event) {
    this.spinner.show();
    this.selectedFiles = event.target.files;
    const file = this.selectedFiles.item(0);
    this.uploadService.uploadFile(file).subscribe(
      response => {
        console.log('response', response);
        this.mediaImgUrl = response.data;
        this.sendMessage(this.mediaImgUrl);
        this.spinner.hide();
      },
      error => {
        console.log('Error', error);
        this.spinner.hide();
      }
    );
  }
}
