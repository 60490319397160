import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'
import { ToastrService } from 'ngx-toastr';
@Injectable()
export class MessagingService {
currentMessage = new BehaviorSubject(null);
constructor(private angularFireMessaging: AngularFireMessaging,  private toastr: ToastrService) {
this.angularFireMessaging.messaging.subscribe(
(_messaging) => {
_messaging.onMessage = _messaging.onMessage.bind(_messaging);
_messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
}
)
}
requestPermission() {
this.angularFireMessaging.requestToken.subscribe(
(token) => {
console.log("token>>>>>>>>>>",token);
localStorage.setItem("setFireBaseToken", token);

},
(err) => {
    this.toastr.error('For RyzeLive to work on your device you need to allow notification permission . Without enabling the the notification permission you will not be able to login', 'Alert!', {
        timeOut: 8000,
        progressBar: true,
        closeButton: true
      });
   
console.error('Unable to get permission to notify.', err);
}
);
}
receiveMessage() {
this.angularFireMessaging.messages.subscribe(
(payload) => {
console.log("new message received. ", payload);
this.currentMessage.next(payload);
})
}
}