import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-settings-e-store',
  templateUrl: './user-settings-e-store.component.html',
  styleUrls: ['./user-settings-e-store.component.css']
})
export class UserSettingsEStoreComponent implements OnInit {
  isDashboardMode = true
  title = 'eStore'
  color = 'income_bg'
  constructor() { }

  ngOnInit() {
  }

}
