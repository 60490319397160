import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {

    constructor(
        private http: HttpClient,
        private route: ActivatedRoute,
        private router: Router,
    ) { }

    uploadFile(file){
        const formData = new FormData();
        formData.append('doc', file);
        return this.http.post<any>(`${environment.apiUrl}app/user/singleUploadToS3`, formData);
    }
    saveuserLogos(request){
        return this.http.post<any>(`${environment.apiUrl}app/user/saveuserLogos`, request);
    }
    getuserLogos(request){
        return this.http.get<any>(`${environment.apiUrl}app/user/getUserLogo?userID=`+request.userID);
    }
//   uploadFile(file) {
//     const contentType = file.type;
//     const bucket = new S3(
//           {
//               accessKeyId: `${environment.AWSKEY}`,
//               secretAccessKey: `${environment.AWSSECRECT}`,
//               region: `${environment.AESREGION}`
//           }
//       );
//       const params = {
//           Bucket: `${environment.AWSBUCKET}`,
//           Key: `${environment.FOLDER}/` + file.name,
//           Body: file,
//           ACL: 'public-read',
//           ContentType: contentType,
//           AccessControlAllowOrigin: '*'
//       };
//       bucket.upload(params, function (err, data) {
//           if (err) {
//               console.log('There was an error uploading your file: ', err);
//               return false;
//           }
//           console.log('Successfully uploaded file.', data);
//           return true;
//       });
//   //for upload progress   
//   /*bucket.upload(params).on('httpUploadProgress', function (evt) {
//             console.log(evt.loaded + ' of ' + evt.total + ' Bytes');
//         }).send(function (err, data) {
//             if (err) {
//                 console.log('There was an error uploading your file: ', err);
//                 return false;
//             }
//             console.log('Successfully uploaded file.', data);
//             return true;
//         });*/
//   }
}
