import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActiveBroadcasterModalComponent } from './active-broadcaster-modal/active-broadcaster-modal.component';
import { FansFriendsFamilyFollowerModalComponent } from './fans-friends-family-follower-modal/fans-friends-family-follower-modal.component';
import { InviteByUsernameModalComponent } from './invite-by-username-modal/invite-by-username-modal.component';
import { BroadcastService } from "../../../_services/broadcast.service";
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-inviteh2h',
  templateUrl: './inviteh2h.component.html',
  styleUrls: ['./inviteh2h.component.scss']
})
export class Inviteh2hComponent implements OnInit {
  @Input() userId;
  @Input() broadcastID;
  
  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private broadcastService: BroadcastService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
  }
  openActiveBroadcasterList() {
    const modalRef = this.modalService.open(ActiveBroadcasterModalComponent);
    modalRef.componentInstance.userId = this.userId;
    modalRef.componentInstance.broadcastID = this.broadcastID;
  }
  openFriendsFamilyList() {
    const modalRef = this.modalService.open(FansFriendsFamilyFollowerModalComponent);
    modalRef.componentInstance.userId = this.userId;
    modalRef.componentInstance.broadcastID = this.broadcastID;
  }
  openInviteByUsername() {
    const modalRef = this.modalService.open(InviteByUsernameModalComponent);
    modalRef.componentInstance.userId = this.userId;
    modalRef.componentInstance.broadcastID = this.broadcastID;
  }
  sendRandomH2Hrequest() {
    this.spinner.show()
    this.broadcastService.sendRandomH2Hrequest({
      broadcastID : this.broadcastID,
      userID : this.userId,
    }).subscribe(
      response => {
        if (!response.error) {
          //Success
          this.spinner.hide()
          return  this.toastr.success("H2H Request sent to random user", 'Success!', {
            timeOut: 2000,
            progressBar: true,
            closeButton: true
          });
        }else{
          //Error
          this.spinner.hide()
          return  this.toastr.error(response.message, 'Error!', {
            timeOut: 2000,
            progressBar: true,
            closeButton: true
          });
        }
        
      },
      error => {
        return  this.toastr.error("Something went wrong", 'Error!', {
          timeOut: 2000,
          progressBar: true,
          closeButton: true
        });
      }
    )
  }
}
