import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AuthenticationService, GlobalService, UploadFileService, UserService } from '../../../../_services';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';

import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-user-settings-broadcast-administrators',
  templateUrl: './user-settings-broadcast-administrators.component.html',
  styleUrls: ['./user-settings-broadcast-administrators.component.css']
})
export class UserSettingsBroadcastAdministratorsComponent implements OnInit {
  isDashboardMode = true
  title = 'My Broadcast Administrators'
  color = 'mybroadcast_headerbg'
  modalRef: BsModalRef;
  rGetProfileData: any;
  getAllMyAdministratorData: any;
  selectedUser = '';
  constructor(
    private modalService: BsModalService,
    private globalService: GlobalService,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private authenticationService: AuthenticationService,
  ) {    const Swal = require('sweetalert2')
}

  openModal(template: TemplateRef<any>) {
    this.loadUser();
    this.modalRef = this.modalService.show(template);
  }
  addAdmistrator() {
    this.spinner.show();
    let currentUser: any = this.authenticationService.currentUserValue;
    const requestData = {
      userID: currentUser.profile.FKuserID,
      toUserID: this.selectedUser,
      status: 1
    }
    this.userService.addBroadcastAdministrator(requestData).subscribe(
      response => {


        if (!response.error) {

          this.modalRef.hide();
          this.getAllMyAdministrator();
          this.selectedUser = ''
          this.toastr.success("Successfully Added", 'Success!', {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });


          this.spinner.hide();
        }
      },
      error => {

        this.toastr.error("Sorry, something went wrong there. Try again", 'Error!', {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
        this.spinner.hide();
      }
    );


  }
  getAllMyAdministrator() {
    this.spinner.show();
    let currentUser: any = this.authenticationService.currentUserValue;
    const requestData = {
      userID: currentUser.profile.FKuserID,

    }
    this.userService.getAllMyAdministrator(requestData).subscribe(
      response => {


        if (!response.error) {

          this.getAllMyAdministratorData = response.data;


          // this.toastr.success("Successfully Added", 'Success!', {
          //   timeOut: 5000,
          //   progressBar: true,
          //   closeButton: true
          // });


          this.spinner.hide();
        }
      },
      error => {

        this.toastr.error("Sorry, something went wrong there. Try again", 'Error!', {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
        this.spinner.hide();
      }
    );
  }
  loadUser() {
    this.spinner.show();
    let currentUser: any = this.authenticationService.currentUserValue;

    const requestData = {
      userID: currentUser.profile.FKuserID,

    }

    this.globalService.getUserFriendFamilyFollowerDetail(requestData).subscribe(
      response => {

        this.rGetProfileData = response.data;

        if (!response.error) {
          // this.loading = false;
          // this.toastr.success(response.message, 'Success!', {
          //   timeOut: 5000,
          //   progressBar: true,
          //   closeButton: true
          // });


          this.spinner.hide();
        }
      },
      error => {

        this.toastr.error("Sorry, something went wrong there. Try again", 'Error!', {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
        this.spinner.hide();
      }
    );
  }
  ngOnInit() {
    this.getAllMyAdministrator();
  }

  removeAdminstrator(id) {


    Swal.fire({
      title: 'Are you sure?',
      text: 'You Want to remove this user',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        let currentUser: any = this.authenticationService.currentUserValue;
        const requestData = {
          userID: currentUser.profile.FKuserID,
          toUserID: id,
          status: 0
        }
        this.userService.addBroadcastAdministrator(requestData).subscribe(
          response => {
    
    
            if (!response.error) {
    
              this.modalRef.hide();
              this.getAllMyAdministrator();
              this.toastr.success("Deleted Successfully!", 'Success!', {
                timeOut: 5000,
                progressBar: true,
                closeButton: true
              });
    
    
              this.spinner.hide();
            }
          },
          error => {
    
            this.toastr.error("Sorry, something went wrong there. Try again", 'Error!', {
              timeOut: 5000,
              progressBar: true,
              closeButton: true
            });
            this.spinner.hide();
          }
        );
    
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          '',
          'error'
        )
      }
    })









  }

}
