import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { BroadcastService } from 'src/app/_services/broadcast.service';

@Component({
  selector: 'app-chat-request-modal',
  templateUrl: './chat-request-modal.component.html',
  styleUrls: ['./chat-request-modal.component.scss']
})
export class ChatRequestModalComponent implements OnInit {
  @Input() chatRoomKey;
  @Input() chatRoomID;
  @Input() userID;
  currentUser: any;

  constructor(
    public activeModal: NgbActiveModal,
    public spinner: NgxSpinnerService,
    public toastr: ToastrService,
    private authenticationService: AuthenticationService,
    private broadcastService: BroadcastService,
  ) { }

  ngOnInit() {
    this.currentUser = this.authenticationService.currentUserValue;
    console.log('chatRoomKey', this.chatRoomKey);
    console.log('chatRoomID', this.chatRoomID);
    console.log('userID', this.userID);
  }
  enableChatRoom() {
    this.spinner.show();
    this.broadcastService.enableChatRoom({
      chatRoomKey: this.chatRoomKey,
      chatRoomID: this.chatRoomID,
      userID: this.userID,
    }).subscribe(
      response => {
        this.spinner.hide();
        console.log('enableChatRoom', response);
        this.toastr.info(response.message, 'Info!', {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
      },
      error => {
        this.spinner.hide();
      }
    );
  }
}
