import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-subscribers',
  templateUrl: './user-subscribers.component.html',
  styleUrls: ['./user-subscribers.component.css']
})
export class UserSubscribersComponent implements OnInit {
  isDashboardMode = true
  title = 'My Subscribers'
  color = 'myfan_head-bg'
  constructor() { }

  ngOnInit() {
  }

}
