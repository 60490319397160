import { Component, OnInit, Input } from '@angular/core';
import { UserService } from 'src/app/_services/user.service';
import { AuthenticationService } from '../../_services';

import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { ViewVideoComponent } from '../dashboard/dash-link-settings/user-settings-recordings/modal/view-video/view-video.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.css']
})
export class LinksComponent implements OnInit {

  @Input('user') user: any;
  isAboutCollapsed = true;
  isInterestCollapsed =true;
  isChannelCollapsed =true;
  isVideoCollapsed =true;
  isGuardianCollapsed = true;
  isSubscribersCollapsed = true;
  isShieldsCollapsed = true;
  isStatsCollapsed = true;
  isHashtagsCollapsed = true;
  activeBadges: any;
  userId: any;
  videos = [];
  isMale = false;
  data = {
    firstName: '',
    lastName: '',
    username: '',
    aboutMe: '',
    profileID: '',
    totalCoinsFormated: '',
    userLevel: '',
    totalFansFormated: '',
    totalFollowersFormated: '',
    totalfriendsFormated: '',
    totalLikeFormated: '',
    location: '',
    city: '',
    country: '',
    identificationId: '000000',
    hideMyRealName: true,
    hideMyLocation: true,
    hideMyInterest: true,
    totalDiamond:'',
    

  }
  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    console.log("Link Page User", this.user)
    this.getAllBadgesForUser();
    //this.getUserVideos();
  }
  // getUserVideos(){
  //   this.user = JSON.parse(localStorage.getItem('currentUser'))
  //   this.userId = this.user.profile.FKuserID;
  //   this.userService.getAllVideosForUser({
  //     userID: this.userId
  //   }).subscribe(
  //     response => {
  //       this.videos = response.data;
  //       console.log("All Videos", response)
  //     }
  //   )
  // }

  ngAfterViewInit() {
    if (this.userId) {
      this.load();
    }
  }
  load() {

    let currentUser: any = this.authenticationService.currentUserValue;
    this.userService.getUserDetails({ id: this.userId, userID: currentUser ? currentUser.profile.FKuserID : 0 }).subscribe(
      response => {
        if (response.error) {
          //Error
         
          this.toastr.error(response.message, 'Error!', {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
        } else {


          this.user = response.data;

          this.data.firstName = this.user.profile.firstName
          this.data.lastName = this.user.profile.lastName
          this.data.username = this.user.profile.username
          //Fix : Need to fix static url
          if (this.user.profile.gender === "1") {
            this.isMale = true
          } else {
            this.isMale = false
          }

          this.data.location = this.user.profile.location
          this.data.city = this.user.profile.city
        
          this.data.country = this.user.profile.CountryName
          this.data.aboutMe = this.user.profile.aboutMe
          this.data.profileID = this.user.profile.profileID
       
          this.data.totalCoinsFormated = this.user.profile.Stat.totalCoinsFormated
          this.data.totalDiamond = this.user.profile.Stat.totalDiamondsCollectedFormated
          this.data.userLevel = this.user.profile.Stat.userLevel
          this.data.totalFansFormated = this.user.profile.Stat.totalFansFormated
          this.data.totalFollowersFormated = this.user.profile.Stat.totalFollowersFormated
          this.data.totalfriendsFormated = this.user.profile.Stat.totalfriendsFormated
          this.data.totalLikeFormated = this.user.profile.Stat.totalLikeFormated

          if (this.user.profile.profileSettings) {
            this.data.hideMyRealName = this.user.profile.profileSettings.hideMyRealName != '1' ? false : true;
          }

          if (this.user.profile.profileSettings) {
            this.data.hideMyLocation = this.user.profile.profileSettings.hideMyLocation != '1' ? false : true;
          }

          if (this.user.profile.profileSettings) {
            this.data.hideMyInterest = this.user.profile.profileSettings.hideMyInterest != '1' ? false : true;
          }
      
        }
      },
      error => {
        //Error
      }
    );
  }
  view(url){
    const modalRef = this.modalService.open(ViewVideoComponent);
    modalRef.componentInstance.url = url;
  }
  getAllBadgesForUser(){
    this.spinner.show()
    this.user = JSON.parse(localStorage.getItem('currentUser'))
    this.userId = this.user.profile.FKuserID

    this.userService.getAllBadgesForUser({userID : this.userId}).subscribe(
      response => {
        console.log("getAllBadgesForUser", response);
        if (!response.error) {
          this.activeBadges = response.data.activeBadges;
        }
        this.spinner.hide()
      },
      error => {
        this.spinner.hide()
      }
    )
  }

}
