import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService, GlobalService, UserService } from '../_services';
import { NgxSpinnerService } from "ngx-spinner";

import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  isOptionAvaliable = "Mobile";
  countries = JSON.parse(localStorage.getItem('countries'));
  defaultCode = '1&United States of America'
  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private globalService: GlobalService,
    private userService: UserService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    let ipData = localStorage.getItem("ipData")
    if (!ipData) {
      this.globalService.getIpdata().subscribe(
        reaponse => {
          localStorage.setItem("ipData", JSON.stringify(reaponse))
          this.selectCountry(reaponse)
        }
      )
    } else {
      this.selectCountry(JSON.parse(ipData))
    }

    if (this.isOptionAvaliable == 'Mobile') {
      this.forgotPasswordForm = this.formBuilder.group({
        selectOption: ['Mobile'],
        country_code: [this.defaultCode],

        mobile: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],

      });
    }
    if (this.isOptionAvaliable == 'Email') {
      this.forgotPasswordForm = this.formBuilder.group({
        selectOption: ['Email'],
        email: ['', [Validators.required, Validators.email]],

      });
    }
    if (this.isOptionAvaliable == 'Username') {
      this.forgotPasswordForm = this.formBuilder.group({
        selectOption: ['Username'],
        Username: ['', [Validators.required, Validators.minLength(6)]],

      });
    }
    // get return url from route parameters or default to '/channels'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/channels';
  }
  selectCountry(ipData: any) {
    this.countries.forEach(country => {
      if (country.code === ipData.country) {
        this.defaultCode = country.dialCode + "&" + country.name
      }
    });
  }
  selectOptionChange(a) {
    this.isOptionAvaliable = a;
    this.ngOnInit();
  }
  get f() { return this.forgotPasswordForm.controls; }
  onSubmit() {
    this.submitted = true;
    this.spinner.show();
    // stop here if form is invalid
    if (this.forgotPasswordForm.invalid) {
      console.log('error', this.forgotPasswordForm);
      this.spinner.hide();
      return;
    }
    let res: any;
    let requestData:any;
    if (this.f.country_code && this.isOptionAvaliable == 'Mobile') {
      let str = this.f.country_code.value;
      res = str.split("&");
    }
     requestData = {

      username: (this.isOptionAvaliable == 'Mobile') ? this.f.mobile.value : (this.isOptionAvaliable == 'Username') ? this.f.Username.value : (this.isOptionAvaliable == 'Email') ? this.f.email.value : "",
      countryCode: this.f.country_code && this.isOptionAvaliable == 'Mobile' ? "+" + res[0] : ""
    }
    this.userService.sendOTPforUserForgetPassword(requestData).subscribe(
      responce => {
        if (!responce.error) {

          this.router.navigate(["otp-forgot-password"]);
          console.log("new responce>>>", responce.data.OTP);
          localStorage.setItem("passwordOTP", responce.data.OTP);
          localStorage.setItem("passwordRequest", JSON.stringify(requestData));
          this.toastr.success(responce.message, 'Success!', {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
          this.spinner.hide();
        } else {
          //Error
          this.toastr.error(responce.message, 'Error!', {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
          this.spinner.hide();
        }
      },
      error => {

      }
    )




  }

}
