import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { BroadcastService } from 'src/app/_services/broadcast.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { StripeToken, StripeSource } from "stripe-angular"
import { AuthenticationService } from 'src/app/_services';
import { StripeScriptTag } from "stripe-angular"

@Component({
  selector: 'app-stripe',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.scss']
})
export class StripeComponent implements OnInit {
  @Input() userId;
  @Input() amount;
  @Input() type;
  @Output() cardToken = new EventEmitter();

  nameOnCard: string;
  customerEmail: string;
  public invalidError:any;
  private publishableKey:string = "pk_test_3iSaPoglwlUqTQ1E4qA8RvIM00JfCzq0cz"

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private router: Router,
    private broadcastService: BroadcastService,
    public spinner: NgxSpinnerService,
    public toastr: ToastrService,
    public authenticationService: AuthenticationService,
    public StripeScriptTag:StripeScriptTag
  ) { 
    this.StripeScriptTag.setPublishableKey( this.publishableKey )
  }

  ngOnInit() {
    let currentUser: any = this.authenticationService.currentUserValue;
    console.log("currentUser", currentUser)
    this.customerEmail = currentUser.profile.email
    this.nameOnCard = currentUser.profile.firstName+' '+currentUser.profile.lastName
  }

  extraData = {
    "name": this.nameOnCard,
    "email": this.customerEmail,
    "address_city": '',
    "address_line1": '',
    "address_line2": '',
    "address_state": '',
    "address_zip": ''
  }
 
  onStripeInvalid( error:Error ){
    console.log('Validation Error', error)
  }
 
  setStripeToken( token:StripeToken ){
    console.log('Stripe token', token)
    this.cardToken.emit({
      nameOnCard : this.nameOnCard, 
      customerEmail : this.customerEmail, 
      amount : this.amount, 
      userID : this.userId, 
      token : token.id, 
    });
  }
 
  setStripeSource( source:StripeSource ){
    console.log('Stripe source', source)
  }
 
  onStripeError( error:Error ){
   // console.error('Stripe error', token)
  }

}
