import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService, GlobalService, UploadFileService, UserService } from '../../../../_services';
import { NgxSpinnerService } from "ngx-spinner";

import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';
import { RechargeService } from 'src/app/_services/recharge.service';

@Component({
  selector: 'app-user-wallet',
  templateUrl: './user-wallet.component.html',
  styleUrls: ['./user-wallet.component.css']
})
export class UserWalletComponent implements OnInit {
  isDashboardMode = true
  title = 'My Wallet'
  color = 'wallet-head-bg'

  coins = 0;
  freeCoins = 0;
  diamonds_available = 0;
  total_cash_out = 0;
  totalStoreCredit: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private globalService: GlobalService,
    private userService: UserService,
    private rechargeService: RechargeService,

    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.spinner.show();
    let currentUser: any = this.authenticationService.currentUserValue;

    const requestData = {
      userID: currentUser.profile.FKuserID,
    }
    this.rechargeService.getUserStat(requestData).subscribe(
      response => {
        this.coins = response.data.totalCoinsFormated
        this.freeCoins = response.data.totalCoinsFormatedFree
        this.diamonds_available = response.data.totalDiamondsFormated
        this.total_cash_out = response.data.totalCashout
        this.totalStoreCredit = response.data.totalStoreCredit
        this.spinner.hide();
      },
      error => {

      }
    )
  }

  goToCoinRecharge(){
    this.router.navigate(['recharge'])
  }

  goToStoreCreditRecharge(){
    this.router.navigate(['store-credit-recharge'])
  }

}
