import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService, GlobalService } from '../../_services';
import { NgxSpinnerService } from "ngx-spinner";

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-mobile-login',
  templateUrl: './mobile-login.component.html',
  styleUrls: ['./mobile-login.component.css']
})
export class MobileLoginComponent implements OnInit {

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  isOptionAvaliable = "Mobile"; 
  countries = JSON.parse(localStorage.getItem('countries'));
  defaultCode = '1&United States of America'
  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private globalService: GlobalService,
    private spinner: NgxSpinnerService
  ) {
      // redirect to home if already logged in
      if (this.authenticationService.currentUserValue) { 
        this.globalService.redirectToHome();
      }
   }

  ngOnInit() {

    localStorage.removeItem("passwordOTP");
    localStorage.removeItem("passwordRequest");

   let ipData = localStorage.getItem("ipData")
   if (!ipData) {
    this.globalService.getIpdata().subscribe(
      reaponse =>{
        localStorage.setItem("ipData", JSON.stringify(reaponse))
        this.selectCountry(reaponse)
      }
    )
   }else {
     this.selectCountry(JSON.parse(ipData))
   }

   if(this.isOptionAvaliable == 'Mobile'){
    this.loginForm = this.formBuilder.group({
      selectOption: ['Mobile'],
      country_code: [this.defaultCode],     
     
      mobile: ['', [Validators.required, Validators.minLength(10),Validators.maxLength(10)]],
      password: ['', Validators.required]
    });
   }
   if(this.isOptionAvaliable == 'Email'){
    this.loginForm = this.formBuilder.group({    
      selectOption: ['Email'],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
   }
   if(this.isOptionAvaliable == 'Username'){
    this.loginForm = this.formBuilder.group({ 
      selectOption: ['Username'],   
      Username: ['', [Validators.required,Validators.minLength(6)]],
      password: ['', Validators.required]
    });
   }
    // get return url from route parameters or default to '/channels'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/channels';
  }
  
  selectCountry(ipData:any) {
    this.countries.forEach(country => {
      if (country.code === ipData.country) {
        this.defaultCode = country.dialCode+"&"+country.name
      }
    });
  }
  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  //Login form onSubmit
  onSubmit() {
      this.submitted = true;
      this.spinner.show();
      // stop here if form is invalid
      if (this.loginForm.invalid) {
          console.log('error', this.loginForm);
          this.spinner.hide();
          return;
      }
      let res:any;
      if(this.f.country_code && this.isOptionAvaliable == 'Mobile'){
      let str = this.f.country_code.value;
       res = str.split("&");
      } 
      this.loading = true;
      const requestData = {
        
        username : (this.isOptionAvaliable == 'Mobile') ?this.f.mobile.value:(this.isOptionAvaliable == 'Username')?this.f.Username.value:(this.isOptionAvaliable == 'Email')?this.f.email.value:"",
        countryCode: this.f.country_code && this.isOptionAvaliable == 'Mobile' ? "+" + res[0]:"",
        password : this.f.password.value,
        apnsToken : localStorage.getItem('setFireBaseToken') != null ? localStorage.getItem('setFireBaseToken'): '2222', // Need Fix. Used for Push Notification
        deviceType : 'web'
      }
      this.authenticationService.login(requestData)
          .pipe(first())
          .subscribe(
              response => {
                  console.log('response', response)
                  this.spinner.hide();
                  if (!response.error) {
                    // this.toastr.success('Login You are successfully logged in', 'Success!', {
                    //   timeOut: 5000,
                    //   progressBar: true,
                    //   closeButton: true
                    // });
                    this.router.navigate([this.returnUrl]);
                  } else {
                    if (response.message) {
                      this.toastr.error(response.message, 'Error!', {
                        timeOut: 5000,
                        progressBar: true,
                        closeButton: true
                      });
                    }else {
                      this.toastr.error('Sorry, something went wrong there. Try again', 'Error!', {
                        timeOut: 5000,
                        progressBar: true,
                        closeButton: true
                      });
                    }
                  }
                  
              },
              error => {
                  this.spinner.hide();
                  this.error = error;
                  this.loading = false;
          });
  }

  selectOptionChange(a){
    this.isOptionAvaliable = a;
    this.ngOnInit();
  }
}
