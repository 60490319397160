import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { BroadcastService } from 'src/app/_services/broadcast.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-h2hrequest',
  templateUrl: './h2hrequest.component.html',
  styleUrls: ['./h2hrequest.component.scss']
})
export class H2hrequestComponent implements OnInit {
  @Input() guestRequest;
  @Input() headToHeadRequest;
  @Input() multiCastRequest;
  @Input() currentUserId;
  url: string;
  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private router: Router,
    private broadcastService: BroadcastService,
    public spinner: NgxSpinnerService,
    public toastr: ToastrService,
  ) { }

  ngOnInit() {
    //this.activeModal.close();
  }

  accept_invite(headToHeadRequestId, userId, physicalChannel, broadcastId){
    this.broadcastService.acceptHeadtoHeadReq({
      userID : this.currentUserId,
      headToHeadReqID : headToHeadRequestId,
      status : 1
    }).subscribe(
      response => {
        if (!response.error) {
          this.activeModal.close();
          // tslint:disable-next-line: max-line-length
          this.url = 'go-live/head-to-head/standard'+'/'+this.currentUserId+'/'+physicalChannel+'/'+broadcastId+'/'+response.data.isAudioOnly;
          return this.router.navigate([this.url]);
        } else {
          return  this.toastr.error(response.message, 'Error!', {
            timeOut: 2000,
          });
        }
      },
      error => {

      }
    )
  }

  accept_multi_invite(multicastReqID, userId, physicalChannel, broadcastId, isAudioOnly, maxAllowedSlots){
    this.broadcastService.acceptMulticastReq({
      userID : this.currentUserId,
      multicastReqID : multicastReqID,
      isAudioOnly : isAudioOnly
    }).subscribe(
      response => {
        if (!response.error) {
          this.activeModal.close();
          this.url = 'go-live/multi-broadcast/multibeam'+'/'+this.currentUserId+'/'+physicalChannel+'/'+broadcastId+'/'+isAudioOnly+'/'+maxAllowedSlots
          return this.router.navigate([this.url]);
        } else {
          return  this.toastr.error(response.message, 'Error!', {
            timeOut: 2000,
          });
        }
      },
      error => {

      }
    )
  }
  reject_multi_invite(multicastReqID, userId, physicalChannel, broadcastId){
    this.broadcastService.rejectMulticastReq({
      userID : this.currentUserId,
      multicastReqID : multicastReqID,
      status : 0
    }).subscribe(
      response => {
        if (!response.error) {
          this.activeModal.close();
          return  this.toastr.success('Multicast Request rejected', 'Success!', {
            timeOut: 2000,
          });
        } else {
          return  this.toastr.error(response.message, 'Error!', {
            timeOut: 2000,
          });
        }
      },
      error => {

      }
    )
  }
  reject_invite(headToHeadRequestId, userId, physicalChannel, broadcastId){
    this.broadcastService.acceptHeadtoHeadReq({
      userID : this.currentUserId,
      headToHeadReqID : headToHeadRequestId,
      status : 0
    }).subscribe(
      response => {
        if (!response.error) {
          this.activeModal.close();
          return  this.toastr.success('H2H Request rejected', 'Success!', {
            timeOut: 2000,
          });
        } else {
          return  this.toastr.error(response.message, 'Error!', {
            timeOut: 2000,
          });
        }
      },
      error => {

      }
    )
  }
  accept_guest_invite(guestAppearanceReqID, userId, physicalChannel, broadcastId){
    this.broadcastService.accepGuestReq({
      userID : this.currentUserId,
      guestAppearanceReqID : guestAppearanceReqID,
      status : 1
    }).subscribe(
      response => {
        if (!response.error) {
          this.activeModal.close();
          this.url = 'go-live/broadcast/standard'+'/'+this.currentUserId+'/'+physicalChannel+'/'+broadcastId+'/'+response.data.isAudioOnly
          return this.router.navigate([this.url]);
        } else {
          return  this.toastr.error(response.message, 'Error!', {
            timeOut: 2000,
          });
        }
      },
      error => {

      }
    )
  }

  reject_guest_invite(guestAppearanceReqID, userId, physicalChannel, broadcastId){
    this.broadcastService.rejectGuestReq({
      userID : this.currentUserId,
      guestAppearanceReqID : guestAppearanceReqID,
      status : 0
    }).subscribe(
      response => {
        if (!response.error) {
          this.activeModal.close();
          return  this.toastr.success('Guest Request rejected', 'Success!', {
            timeOut: 2000,
          });
        } else {
          return  this.toastr.error(response.message, 'Error!', {
            timeOut: 2000,
          });
        }
      },
      error => {

      }
    )
  }

}
