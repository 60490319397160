import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
  ) { }
  
  getUserFriendFamilyFollowerDetail(requestData) {
    return this.http.post<any>(`${environment.apiUrl}app/chat/getAllChatRoomsByUser`, requestData);
  }
  
  getUserIFanDetail(requestData) {
    return this.http.post<any>(`${environment.apiUrl}app/chat/getUserIFanDetailChat`, requestData);
  }
  
  getUserFanDetail(requestData) {
    return this.http.post<any>(`${environment.apiUrl}app/chat/getUserFanDetailChat`, requestData);
  }
  
  getUserFriendDetail(requestData) {
    return this.http.post<any>(`${environment.apiUrl}app/chat/getUserFriendDetailChat`, requestData);
  }

  checkChatRoom(requestData) {
    return this.http.post<any>(`${environment.apiUrl}app/chat/checkChatRoom`, requestData);
  }

  getAllChatRoomsByID(requestData) {
    return this.http.post<any>(`${environment.apiUrl}app/chat/getAllChatRoomsByID`, requestData);
  }

  chatRoomSendMsg(requestData) {
    return this.http.post<any>(`${environment.apiUrl}app/chat/chatRoomSendMsg`, requestData);
  }

  getAllChatByChatRoom(requestData) {
    return this.http.post<any>(`${environment.apiUrl}app/chat/getAllChatByChatRoom`, requestData);
  }

  enableChatRoom(requestData) {
    return this.http.post<any>(`${environment.apiUrl}app/chat/enableChatRoom`, requestData);
  }

  getUserFamilyDetail(requestData) {
    return this.http.post<any>(`${environment.apiUrl}app/chat/getUserFanDetailChat`, requestData);
  }
}
