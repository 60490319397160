import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService, GlobalService } from '../_services';
import { NgxSpinnerService } from "ngx-spinner";

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})

export class RegisterComponent implements OnInit {
  sendOtpForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  countries = JSON.parse(localStorage.getItem('countries'));
  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private globalService: GlobalService,
    private spinner: NgxSpinnerService
  ) { 
      // redirect to home if already logged in
      if (this.authenticationService.currentUserValue) { 
        this.globalService.redirectToHome();
      }
  }

  ngOnInit() {    
    this.sendOtpForm = this.formBuilder.group({
      mobile_number: ['', [Validators.required,Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)]],
      country_code: ['', Validators.required]
    });
    // get return url from route parameters or default to '/verify-otp'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/verify-otp';
  }

  // convenience getter for easy access to form fields
  get f() { return this.sendOtpForm.controls; }

    //sendOtpForm form onSubmit
    onSubmit() {
      this.submitted = true;
      this.spinner.show();
      // stop here if form is invalid
      if (this.sendOtpForm.invalid) {
          console.log('error', this.sendOtpForm);
          this.spinner.hide();
          return;
      }
      const requestData = {
        phoneNo : this.f.mobile_number.value,
        countryCode : '+'+this.f.country_code.value,
      }
      //Check Mobile Number
      this.loading = true;

      this.globalService.checkUserPhoneNo(requestData).subscribe(
        response => {
          console.log('response', response)
          if (!response.error) {
            this.loading = false;
            this.toastr.success(response.message, 'Success!', {
              timeOut: 2000,
              progressBar: true,
              closeButton: true
            });
            this.router.navigate([this.returnUrl]);
            this.spinner.hide();
            //Need Fix, After sms integration will change this
            this.toastr.success("Your OTP Send to registred mobile no", 'OTP', {
              timeOut: 5000,
              progressBar: true,
              closeButton: true
            });
            localStorage.setItem('verify-otp',JSON.stringify( 
            {
              country_code:this.f.country_code.value,
              mobile:this.f.mobile_number.value,
              otp:response.data.OTP,
              verify:false,
            }))
          } else {

            localStorage.removeItem('verify-otp');

            this.loading = false;
            this.spinner.hide();
            this.toastr.error(response.message, 'Error!', {
              timeOut: 5000,
              progressBar: true,
              closeButton: true
            });
          }
        },
        error => {
          localStorage.removeItem('verify-otp');
          this.toastr.error("Sorry, something went wrong there. Try again", 'Error!', {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
          this.spinner.hide();
        }
      );
  }
}
