import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-view-poll-details',
  templateUrl: './view-poll-details.component.html',
  styleUrls: ['./view-poll-details.component.scss']
})
export class ViewPollDetailsComponent implements OnInit {
  moment: any = moment;

  isDashboardMode = true
  title = 'Poll Details'
  color = 'my-pool-head-bg'
  pollDetails:any

  constructor() { }

  ngOnInit() {
    this.pollDetails = JSON.parse(localStorage.getItem('poll'))
    console.log(this.pollDetails);
  }

}
