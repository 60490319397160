export class User {
    constructor(public id: number, public username: string) {}
}

export interface IUserResponse {
    total: number;
    data: {
        USER : {
            data : User[];
            total: number;
        }
    };
}