import { Component, OnInit } from '@angular/core';
import { SetLogoModalComponent } from "../../../../_modal/set-logo-modal/set-logo-modal.component";
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-user-settings-personalization',
  templateUrl: './user-settings-personalization.component.html',
  styleUrls: ['./user-settings-personalization.component.css']
})

export class UserSettingsPersonalizationComponent implements OnInit {
  isDashboardMode = true
  title = 'My Personalization'
  color = 'pesonalize_headerbg'
  constructor(
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
  }
  setLogoModalOpen() {
    const modalRef = this.modalService.open(SetLogoModalComponent);
    // modalRef.componentInstance.userId = this.uid;
    // modalRef.componentInstance.broadcastID = this.broadcastId;
  }
}
