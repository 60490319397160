import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/_services/authentication.service';
declare var Hls:any;
@Component({
  selector: 'app-view-video',
  templateUrl: './view-video.component.html',
  styleUrls: ['./view-video.component.scss']
})
export class ViewVideoComponent implements OnInit {
  @Input() url;
  constructor(
    public activeModal: NgbActiveModal,
    public spinner: NgxSpinnerService,
    public toastr: ToastrService,
    private authenticationService: AuthenticationService,
  ) { }

  ngOnInit() {
    let video:any = document.getElementById('video');
    if (Hls.isSupported()) {
    let hls = new Hls();
    hls.loadSource(this.url);
    hls.attachMedia(video);
    hls.on(Hls.Events.MANIFEST_PARSED, function() {
      video.play();
    });
  } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
    video.src = this.url;
    video.addEventListener('loadedmetadata', function() {
      video.play();
    });
  }
  }

}
