import { Component, OnInit, Renderer2, Inject, Input } from '@angular/core';
import { ChannelService } from '../../_services';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { ChatService } from '../../_services/chat.service';
import * as moment from 'moment';

@Component({
  selector: 'app-chat-user',
  templateUrl: './chat-user.component.html',
  styleUrls: ['./chat-user.component.scss']
})
export class ChatUserComponent implements OnInit {
  @Input() user: any;

  moment: any = moment;
  userData: any;
  userId: any;

  constructor(
    private renderer: Renderer2,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private chatService: ChatService
  ) { }

  ngOnInit() {
    //console.log("user", this.user);
  }

  enableChat(chatRoom){
    console.log('enableChat', chatRoom)
    // this.userData = JSON.parse(localStorage.getItem('currentUser'))
    // this.userId = this.user.profile.FKuserID
    this.spinner.show()
    this.chatService.enableChatRoom(
      {
        chatRoomID : chatRoom.id,
        chatRoomKey : chatRoom.chatRoomKey,
        userID : chatRoom.createdBY
      }
    ).subscribe(response => {
      if (!response.error) {
        this.router.navigate(['/chat-details/'+chatRoom.createWith]);
      }
      this.spinner.hide()
    })
  }

}
